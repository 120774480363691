import React, { useState, useEffect, Component } from 'react'
import { authFetch } from '../../auth';


export default function CarriersItemEquipment(props) {
    const [state, setState] = useState({})
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }


    useEffect(() => {
        if (props && props.equipment) {
            const removeLeftBracket = props.equipment.replace('{', '')
            const removeRightBracket = removeLeftBracket.replace('}', '')
            const removeDoubleQuote = removeRightBracket.replace(/['"]+/g, "")
            const removeSpaces = removeDoubleQuote.replace(/ /g, '')
            const splitEquipment = removeSpaces.split(',')


            //Below is used, Top is not used.
            const switchQuotes = props.equipment.replace(/'/g, "\"")
            const lowerCase = switchQuotes.toLowerCase()
            const convert = JSON.parse(lowerCase)

            setState(convert)

        }
    }, [props.equipment])

    
    const trailerChange = (e) => {
        const key = e.target.value.toLowerCase();
        console.log(key)
        const value = !state[key];
        const newSelected = Object.assign(state, { [key]: value });
        setState({ ...state, [key]: value });
        //this.props.parentCarrierTrailers({ selected: newSelected })
    }

    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/carriers/${props.carrier_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                equipment: state
                
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit);
        props.updateEquipment(state)
    }

    return (
        <div class="card">

            <div class="card-header" style={{ border: "none" }}>
                <h3 class="card-title">Equipment</h3>
                <div class="edit-button">
                    <a href="#edit" id="edit" onClick={changeEdit}> [edit]</a>

                </div>
            </div>

            <div class="card-body">
                <div class="form-selectgroup">
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="hotshot" value="Hotshot" class="form-selectgroup-input" checked={state.hotshot} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Hotshot</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="flatbed" value="Flatbed" class="form-selectgroup-input" checked={state.flatbed} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Flatbed</span>
                    </label>

                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="stepdeck" value="Stepdeck" class="form-selectgroup-input" checked={state.stepdeck} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Stepdeck</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="doubledrop" value="DoubleDrop" class="form-selectgroup-input" checked={state.doubledrop} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Double Drop</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="doubles" value="Doubles" class="form-selectgroup-input" checked={state.doubles} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Doubles</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="rgn" value="RGN" class="form-selectgroup-input" checked={state.rgn} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">RGN</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="lowboy" value="Lowboy" class="form-selectgroup-input" checked={state.lowboy} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Lowboy</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="dryvan" value="Dryvan" class="form-selectgroup-input" checked={state.dryvan} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Dry Van</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="reefer" value="Reefer" class="form-selectgroup-input" checked={state.reefer} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Reefer</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="sprinter" value="Sprinter" class="form-selectgroup-input" checked={state.sprinter} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Sprinter Van</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="box" value="Box" class="form-selectgroup-input" checked={state.box} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label">Box Van</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="checkbox" name="other" value="Other" class="form-selectgroup-input" checked={state.other} onChange={trailerChange} disabled={edit} />
                        <span class="form-selectgroup-label"> Other</span>
                    </label>


                </div>
                <form onSubmit={handleSubmit}>
                {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>
        </div>

    )
}