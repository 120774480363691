import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { authFetch } from '../../../auth';

const PendingDelete = (props) => {

    const initialState = () => {
        return (props)
    }
    const history = useHistory();

    const [state, setState] = useState(initialState);
    const [showModal, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setState(props);
    }
    
    const handleRemoveLoads = () => {
        props.parentDeleteSingle(props.row)
        setShow(false)
        
    }
    



    return (
        <>
                <Link>
                    <Button className="btn btn-icon btn-danger" onClick={handleShow}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="4" y1="7" x2="20" y2="7" /><line x1="10" y1="11" x2="10" y2="17" /><line x1="14" y1="11" x2="14" y2="17" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>

                    </Button>
                </Link>

                <Modal
                    show={showModal}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body class="modal-body">
                            <div>Are you sure you want to delete this load?</div>
                            <br />
                            <div>{props.row.value}</div>
                           


                        </Modal.Body>
                        <Modal.Footer>
                         <Button variant="primary" onClick={handleClose}>
                                Don't Delete
                            </Button>
                            <Button variant="danger" onClick={handleRemoveLoads} >
                                Delete
                            </Button>
                            
                            
                        </Modal.Footer>
                    </form>
                </Modal>


        </>
    )
}

export default PendingDelete