import React, { Component, useState, useEffect } from 'react'
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker"
import moment from 'moment';
import { authFetch, logout } from '../../auth'
import { useHistory, withRouter } from "react-router-dom";
import { setDate } from 'date-fns';


export default function EquipmentTruckItemRecords(props) {

    const [state, setState] = useState('')
    const [newRecord, setNewRecord] = useState('')
    const [date, setDate] = useState(new Date())
    const handleChange = e => {
        setNewRecord({
            ...newRecord,
            [e.target.name]: e.target.value
        })

    }
    useEffect(() => {
        setState(props)
    })

    const handleSubmit = e => {
        e.preventDefault();
        const uniqueID = uuidv4()
        authFetch(`https://fltbd.herokuapp.com/api/truck_notes/${props.equipment_id}`, {
            method: 'PUT',
            body: JSON.stringify({
                note_id: uniqueID,
                work_performed: newRecord.work_performed,
                performed_by: newRecord.performed_by,
                service_date: moment(date).format('MM-DD-YYYY'),
                mileage: newRecord.mileage,

            }),
            headers: { 'Content-Type': 'application/json' },
        })

        props.parentNewRecord({
            note_id: uniqueID,
            work_performed: newRecord.work_performed,
            performed_by: newRecord.performed_by,
            service_date: moment(date).format('MM-DD-YYYY'),
            mileage: newRecord.mileage,

        })
        setNewRecord({
            work_performed: '',
            performed_by: '',
            mileage: '',
        })
        setDate(new Date())

    }


    return (
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Add New Service Record</h3>
            </div>
            <div class="card-body">
                <form onSubmit={handleSubmit}>
                    <div class="form-row">

                        <div class="form-label col-md-12 mb-3">
                            <label class="form-label" for="inputMakeModel">Service Performed</label>

                            <textarea type="text" class="form-control" placeholder="Description of Service Performed On Equipment" name="work_performed" value={newRecord.work_performed} onChange={handleChange} />
                        </div>
                    </div>
                    <div class="form-row">
                    <div class="col-md-2">
                            <label class="form-label" >Mileage</label>
                            <input type="text" class="form-control" name="mileage" value={newRecord.mileage} onChange={handleChange} />
                        </div>
                        <div class="col-md-2">
                            <label class="form-label" >Service Date</label>
                            <DatePicker
                                selected={date}
                                name="service_date"
                                className="form-control"
                                dateFormat="MM/dd/yyyy"
                                onChange={(date) => setDate(date)}
                            />
                        </div>
                        
                        <div class="col-md-4">
                            <label class="form-label" >Performed By</label>
                            <input type="text" class="form-control" name="performed_by" value={newRecord.performed_by} onChange={handleChange} />
                        </div>
                    </div>
                    <button class="btn btn-success float-right">Create Record</button>
                </form>
            </div>
        </div >
    )
}