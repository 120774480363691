import React, { useEffect, useState } from "react";
import {
    Route, Switch, Redirect, Router, useHistory
} from 'react-router-dom';
import DashboardHeader from "./DashboardHeader";
import DashboardFinances from "./DashboardFinances";
import DashboardRecent from "./DashboardRecent"
import { authFetch } from "../../auth";
import moment from 'moment';
import DashboardInvoices from "./DashboardInvoices";
import OnboardModal from "../Modals/OnBoardModal";
import SubStatusModal from "../Modals/SubStatusModal";

export default function DashboardContainer(props) {
    const history = useHistory();

    const [loads, setLoads] = useState()
    const [recentLoads, setRecentLoads] = useState()
    const [recentCarriers, setRecentCarriers] = useState()
    const [invoices, setInvoices] = useState(0)
 
    const [activeLoads, setActiveLoads] = useState([])
    const [completedLoads, setCompletedLoads] = useState([])
    const [sevenDays, setSevenDays] = useState([])
    const [thirtyDays, setThirtyDays] = useState([])
    const [ytd, setYTD] = useState([])
    


    const currentDate = moment().format('MM/DD/YYYY')
    const sevenDaysAgo = moment(currentDate).subtract(7, 'days').format('MM/DD/YYYY')
    const thirtyDaysAgo = moment(currentDate).subtract(30, 'days').format('MM/DD/YYYY')
    const currentYear = moment().startOf('year').format('MM/DD/YYYY');


    const mapRevenueTime = (loads) => {
        loads.map(load => {
            if (load.actions !== undefined && load.actions[0] !== undefined) {
                const loadDate = moment(load.actions[0].date).format('MM/DD/YYYY')

                if (Date.parse(loadDate) > Date.parse(sevenDaysAgo)) {
                    setSevenDays(sevenDays => [...sevenDays, load])
                }
                if (Date.parse(loadDate) > Date.parse(thirtyDaysAgo)) {
                    setThirtyDays(thirtyDays => [...thirtyDays, load])
                }
                if (Date.parse(loadDate) > Date.parse(currentYear)) {
                    setYTD(ytd => [...ytd, load])

                }
            }
            else {
                return
            }
        })
        



    }
    useEffect(() => {
        if (props && props.invoices) {
            setInvoices(props.invoices)
        }
    }, [props.invoices])

    useEffect(() => {
        if (props && props.loads) {
            setLoads(props.loads)
            
            const response = props.loads
            setLoads(response)
            const sortLoads = response.sort((a, b) => a.id < b.id ? 1 : -1)
            const recent = sortLoads.slice(0, 5)
            setRecentLoads(recent)
            mapRevenueTime(response)
            response.map(activeLoad => {
                if (activeLoad.status !== "Completed" && activeLoad.status !== "Invoiced") {
                    setActiveLoads(activeLoads => [...activeLoads, activeLoad])
                }
                if (activeLoad.status === "Completed") {
                    setCompletedLoads(completedLoads => [...completedLoads, activeLoad])
                }

            })
        }
    }, [props.loads])
    useEffect(() => {
        document.title = props.title || "";
      }, [props.title]);

    useEffect(() => {
        if (props && props.carriers) {
            setRecentCarriers(props.carriers)
            
        }
    }, [props.carriers])

    
    return (
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            {!props.activeSub ? <SubStatusModal {...props} /> : null }
            {props.onBoard ? <OnboardModal {...props} /> : null }
            <DashboardHeader />
            <DashboardFinances
                loads={loads}
                activeLoads={activeLoads}
                completedLoads={completedLoads}
                sevenDays={sevenDays}
                thirtyDays={thirtyDays}
                ytd={ytd}

            />
            <DashboardRecent
                loads={recentLoads}
                recentCarriers={recentCarriers}
            />
       
            <DashboardInvoices data={invoices}  />
            
        </div>
    )

}

