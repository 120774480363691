/* eslint-disable */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
  useSortBy
} from "react-table";
import { matchSorter } from "match-sorter";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
  useHistory
} from "react-router-dom"
import Select from 'react-select'
import { useAuth, authFetch, logout } from "../../auth";
import { Dropdown, Form } from "react-bootstrap";
import { set } from "date-fns";

import { v4 as uuidv4 } from "uuid";


const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 0px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  input {
      width: 50%;
      
  }
  btn {
    padding: .5rem .5rem;
  }

`
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])


    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}



function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ statusOptions, parentDeleteList, parentPatchList, columns, data, parentHideCompleted }) {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );


  const [patchStatus, setPatchStatus] = useState('')
  const deleteSelected = () => {

    const selectedList = selectedFlatRows.map(d => d.original.load_id)
    authFetch(`https://fltbd.herokuapp.com/api/loads/multi-delete`, {
      method: 'POST',
      body: JSON.stringify({
        load_list: selectedList
      }),
      headers: { 'Content-Type': 'application/json' },
    })
    parentDeleteList(selectedList)

  }

  const patchSelected = (e) => {
    const selectedList = selectedFlatRows.map(d => d.original.invoice_id)
    authFetch(`https://fltbd.herokuapp.com/api/invoice/multi-patch`, {
      method: 'PATCH',
      body: JSON.stringify({
        invoice_list: selectedList,
        status: e.value
      }),
      headers: { 'Content-Type': 'application/json' },
    })
    console.log(selectedList)

    parentPatchList(selectedList, e)

  }
  const history = useHistory();
  const doubleClickRow = (e) => {
    const values = e.values.invoice_id
    history.push({
      pathname: `/accounting/invoice/${values}`,
      //remove window.location.href if you want to load from state
    })
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        hiddenColumns: [''],
        selectedRowIds: [],
        pageIndex: 0,
        sortBy: [
          { id: 'loadnumber', desc: true }
        ]
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }

  );


  // We don't want to render all of the rows for this example, so cap
  // it for this use case

  return (
    <>
      <div className="card">
      <div class="card-header">
          <h3 class="card-title">Invoices </h3>
           <div class="edit-button">
                    <Link to="/accounting/create-invoice"> [Create New Invoice]</Link>

                </div>

        </div>
      <div className="table-responsive">
        <table className="table-responsive card-table table-vcenter" {...getTableProps()}>
          <thead>


            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left"
                }}
              >

                <span>Search: </span>
                <input
                  type="text"
                  value={globalFilter || ""}
                  placeholder="Search By Invoice, Date, Customer, etc"
                  onChange={e => setGlobalFilter(e.target.value)}
                  style={{
                    fontSize: "1.1rem",
                    border: "0",
                    width: "100%"
                  }}
                />
              </th>
            </tr>
            {headerGroups.map((headerGroup) => (
              <tr style={{ backgroundColor: "#f6e2b3"}} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>

            {page.map((row, i) => {
              prepareRow(row);

              return (
                <>
                  {row.isSelected == true ?
                    <tr style={{ cursor: "pointer", textShadow: "0px 0px 1px black" }}  {...row.getRowProps()} onDoubleClick={() => doubleClickRow(row)} onClick={() => row.toggleRowSelected()}>

                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        );
                      })}
                    </tr>
                    :
                    <tr style={{ cursor: "pointer" }}  {...row.getRowProps()} onDoubleClick={() => doubleClickRow(row)} onClick={() => row.toggleRowSelected()}>

                      {row.cells.map((cell) => {
                        return (
                          <td  {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        );
                      })}
                    </tr>
                  }
                </>
              );

            })}
            <div style={{ paddingTop: "Can be changed to a value, will allow last row status to show" }}>
              {''}
            </div>
          </tbody>
        </table>
        </div>
        <div class="row m-3">
          <div class="col">
            <div className="pagination">
              <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'< Previous'}
              </button>
              <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={() => nextPage()} disabled={!canNextPage}>
                {'Next >'}
              </button>
            </div>
          </div>
          <div class="col d-none d-sm-block">
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
          </div>
          <div class="col d-none d-sm-block">
            <select
              class="form-select"

              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>


        </div>
        <br />
        <div class="row m-3">
          
          
          <div class="col">
          
          </div>
          <div class="col">
          <Select placeholder="Status of Selected" options={statusOptions} onChange={patchSelected} />

          </div>
        </div>

      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";


const Actions = ({ actions }) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <>
      {actions.map((action, idx) => {
        return (
          <span key={idx} className="badge">
            {action}
          </span>
        );
      })}
    </>
  );
};

function AccountingInvoicesTable(props) {
  const [data, setData] = useState([]);
  const [selectedLoad, setSelectedLoad] = useState()

  const handleDeleteList = (removeLoads) => {
    const toDelete = new Set(removeLoads);
    const arrayOfObjects = [...data];
    const newArray = arrayOfObjects.filter(obj => !toDelete.has(obj.load_id));
    setData(newArray)
  }

  const handlePatchList = (patchLoads, e) => {
    const toPatch = new Set(patchLoads);
    const dataCopy = [...data]
    const mappedData = dataCopy.map(g => (toPatch.has(g.invoice_id) ? Object.assign({}, g, { status: e.value }) : g))
    setData(mappedData)
  }

  const handleDeleteSingle = (row) => {
    const dataCopy = [...data];
    // It should not matter what you name tableProps. It made the most sense to me.
    dataCopy.splice(row.row.index, 1);
    setData(dataCopy);
    authFetch(`https://fltbd.herokuapp.com/api/loads/${row.value}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    })
  }


  const changeStatus = (row, e) => {
    const dataCopy = [...data]
    const mappedData = dataCopy.map(g => (g.load_id === row.row.values.load_id ? Object.assign({}, g, { status: e.value }) : g))
    //setData(mappedData)
    authFetch(`https://fltbd.herokuapp.com/api/loads/${row.row.values.load_id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        status: e.value
      }),
      headers: { 'Content-Type': 'application/json' },
    })
   // props.updateLoads(mappedData, props.tab)
    props.testUpdate(row, e)
  }


  const statusOptions = [
    { value: 'Invoiced', label: 'Invoiced' },
    { value: 'Paid', label: 'Paid' },
    { value: 'Past Due', label: 'Past Due' },
  ]


  const columns = React.useMemo(
    () => [

      {
        Header: "Invoice #",
        id: "invoicenumber",
        accessor: "invoice_number"
      },
      {
        Header: "Ref #",
        id: "referencenumber",
        accessor: "ref_number"
      },
      {
        Header: "Date",
        id: "date",
        accessor: "date"
      },

     
      {
        Header: "Customer",
        id: 'Customer',
        accessor: "customer_name"
      },
      
      {
        Header: "Total",
        disableSortBy: true,
        accessor: "total",
        Cell: (row) => (

          <div>
            {row.cell.value === null ? null : '$' + row.cell.value}
          </div>
        )
      },
      
      {
        Header: "Status",
        id: "status",
        accessor: "status",
        Cell: (row) => (

          <>
            {row.cell.value === "Invoiced" ?
            <span class="badge bg-indigo">{row.cell.value}</span>   
            :
            row.cell.value === "Paid" ?
            <span class="badge bg-green">{row.cell.value}</span>
            :
            row.cell.value === "Past Due" ?
            <span class="badge bg-red">{row.cell.value}</span>
            :
            null
        }
          </>
        )
      },
     

      {
        Header: "",
        id: "invoice_id",
        disableSortBy: true,
        accessor: "invoice_id",

        Cell: (row) => (
          <div >
           
           
            <Link to={`/accounting/invoice/${row.cell.value}`}>
            <button class="btn btn-icon btn-primary"><svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>                        </button>
          
            </Link>
            <a href={`https://fltbd-bucket.s3.us-east-2.amazonaws.com/invoice_${row.cell.value}.pdf`} target="_blank">
            <button class="btn btn-icon btn-info" >
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" /><line x1="8" y1="8" x2="12" y2="8" /><line x1="8" y1="12" x2="12" y2="12" /><line x1="8" y1="16" x2="12" y2="16" /></svg>
                    </button>
            </a>
           

           
          </div>
        )
      },


    ],
    [data]
  );

  const history = useHistory();

  useEffect(() => {
    if (props && props.data) {
      setData(props.data)
    }
  }, [props.data])

  useEffect(() => {
    document.title = props.title || "";
}, [props.title]);


  return (

    <Styles>
      <Table
        columns={columns}
        data={data}
        parentPatchList={handlePatchList}
        statusOptions={statusOptions}
      />
    </Styles>
  );
}

export default AccountingInvoicesTable;
