import React, { useState, useEffect, Component, useRef } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Select from 'react-select'
import axios from 'axios'
import { authFetch } from '../../auth';
import LoadsAccessorialFees from './Modals/LoadsAccessorialFees';

const LoadsFinances = (props) => {
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);
    const [timer, setTimer] = useState(null)
    useEffect(() => {

    }, [])
    const [focus, setFocus] = useState(false)
    function grossPay(e) {
        clearTimeout(timer)
        //Testing the handleGrossFocus instead of this...
        //const newTimer = setTimeout(() => {
        //    authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
        //        method: 'PATCH',
        //        body: JSON.stringify({
        //            [e.target.name]: e.target.value
        //        }),
        //        headers: { 'Content-Type': 'application/json' },
        //    })
       // }, 500)
        //setTimer(newTimer)
        const value = parseInt(e.target.value)
        const NaNChecker = (isNaN(value) ? 0 : value)
        props.parentLoadDetails({ gross_pay: NaNChecker })

    }
    function carrierPay(e) {
        clearTimeout(timer)
        const value = parseInt(e.target.value)
        const NaNChecker = (isNaN(value) ? 0 : value)
        props.parentLoadDetails({ carrier_pay: e })
    }
    function lineHaul(e) {
        clearTimeout(timer)
        const value = parseInt(e.target.value)
        const NaNChecker = (isNaN(value) ? 0 : value)
        props.parentLoadDetails({ line_haul: NaNChecker})
    }   
    function handleFocus(e) {
        setFocus(false)
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                [e.target.name]: e.target.value
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        //props.parentLoadDetails({gross_pay: e.target.value})

    }
    function handleLineHaulFocus(e) {
        setFocus(false)
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                line_haul: e.target.value,
                carrier_pay: ((totalFees || 0) + (parseFloat(e.target.value) || 0))
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentLoadDetails({carrier_pay: ((totalFees || 0) + (parseFloat(e.target.value) || 0))})

    }
    const totalFees = props.accessorial_fees ? props.accessorial_fees.reduce((acc, fee) => {
        const amount = fee && parseFloat(fee.amount); // add a null check here
        const quantity = fee && parseInt(fee.quantity); // add a null check here
        if (isNaN(amount) || isNaN(quantity)) {
          return acc;
        }
        return acc + amount * quantity;
      }, 0):0;
   
    return (


        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Finances</h3>

                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">Customer Pay</span>
                    </div>
                    <input
                        onBlur={(e) => handleFocus(e)}
                        onFocus={() => setFocus(true)}
                        type="num"
                        value={props.gross_pay}
                        name="gross_pay"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={(e) => grossPay(e)} />
                </div>

                <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-sm">Line Haul</span>
                        </div>
                        <input 
                        onBlur={(e) => handleLineHaulFocus(e)}
                        onFocus={() => setFocus(true)}
                        type="text" 
                        pattern="[0-9]*" 
                        value={props.line_haul} 
                        name="line_haul" 
                        class="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm" 
                        onChange={(e) => lineHaul(e)} />
                    </div>
              
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-sm">Carrier Pay</span>
                        </div>
                        <input 
                        type="text" 
                        pattern="[0-9]*" 
                        value={((totalFees || 0) + (parseFloat(props.line_haul) || 0)).toFixed(2)}
                        class="form-control" 
                        disabled
                        />
                    </div>
                    <LoadsAccessorialFees {...props} />
                    
            </div>
        </div>




    )


}

export default LoadsFinances