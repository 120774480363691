import React, { useEffect, useState } from "react";
import styled from "styled-components"
import Footer from "../Footer";
import HomeMasthead from "./HomeMasthead";
import HomeJumbotron from "./HomeJumbotron";
import fltbd_rc_mobile_phone_2 from "../../assets/img/fltbd_rc_mobile_phone_2.png"
import freightbrokerai_mobile from "../../assets/img/freightbrokerai_mobile.png"
import multi_docs from "../../assets/img/multi_docs.svg"
import del_truck from "../../assets/img/del_truck.svg"
import web_cursor from "../../assets/img/web_cursor.svg"
import web_chart from "../../assets/img/web_chart.svg"
import web_lever from "../../assets/img/web_lever.svg"
import web_stopclock from "../../assets/img/web_stopclock.svg"
import web_flash from "../../assets/img/web_flash.svg"
import web_brain from "../../assets/img/web_brain.svg"
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Jump from 'react-reveal/Jump';
import Rotate from 'react-reveal/Rotate';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
} from "react-router-dom";


export default function HomeContainer() {
  return (
    <>
      <div class="container-fluid" style={{ margin: "0", padding: "0" }}>


        <lottie-player style={{ borderBottom: "8px solid white" }} src="https://lottie.host/4ed73666-0d15-4b73-adc3-9e4ce5db1ed0/xpsunLnK4E.json" background="transparent" speed="1" loop autoplay></lottie-player>


      </div>
      
      <div class="container-fluid" style={{ margin: "0", padding: "0" }}>



      </div>
      <section class="jumbotron text-center roadbg" style={{ paddingBottom: "2rem" }}>
        <div class="container">


          <Pulse>
            <h1 class="jumbotron-header">A TMS That Delivers.</h1>
          </Pulse>
          <p>

            <Link to="/signup"><button class="btn btn-success btn-lg m-2">Get Started For Free</button></Link>
          </p>
        </div>
      </section>
      <section class="jumbotron" style={{ paddingBottom: "2rem" }}>


        <span class="early-access-tag">At FreightBroker.ai</span>

        <h2 class="font-color-white">We believe in empowering our customers with cutting-edge technology and user-friendly features that simplify their daily operations.
          Our platform is designed to cater to new, lean, and small brokerages, as well as anyone who wants to stay ahead of the curve.
          Our commitment is to provide our customers with the most affordable and reliable solution in the market.
          We are dedicated to listening to our customers' feedback and continuously improving our platform to meet their evolving needs.</h2>


      </section>
      <section class="p-3 cFeatures">
        <div class="container">
          <div class="row mt-3">
            <div class="col-md-4 text-center">
              <Rotate>
                <img src={multi_docs} height="128px" />
              </Rotate>
              <h2 class="mt-3">Documents</h2>
              <p>Swiftly Create Contracts, Confirmations, Bill of Ladings, etc.</p>

            </div>
            <div class="col-md-4 text-center">
              <Rotate>
                <img src={web_lever} height="128px" />
              </Rotate>
              <h2 class="mt-3">Management</h2>
              <p>Effortlessly handle your clients, carriers, and additional data.</p>
            </div>
            <div class="col-md-4 text-center">
              <Rotate>
                <img src={web_chart} height="128px" />
              </Rotate>
              <h2 class="mt-3">Reports</h2>
              <p>Easily Generate Customized.</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-4 text-center">
              <Rotate>
                <img src={web_flash} height="128px" />
              </Rotate>
              <h2 class="mt-3">Fast</h2>

            </div>
            <div class="col-md-4 text-center">
              <Rotate>
                <img src={web_stopclock} height="128px" />
              </Rotate>
              <h2 class="mt-3">Efficient</h2>
            </div>
            <div class="col-md-4 text-center">
              <Rotate>
                <img src={web_brain} height="128px" />
              </Rotate>
              <h2 class="mt-3">Intuitive</h2>
            </div>
          </div>

        </div>
      </section>
      <div style={{ backgroundColor: "none" }}>
        <div class="build-loads-container">
          <div class="row">
            <div class="col-md-7">
              <Fade left>
                <h2 className="font-color-white">Simply Simple.</h2>
                <p className="lead font-color-white">Eliminating unnecessary features, or "bloat," is crucial in achieving efficiency and simplicity.</p>
              </Fade>
              <Fade left>
                <h2 className="font-color-white">Build Loads. Build Success.</h2>
                <p className="lead font-color-white">Easily create rate confirmations and streamline your operations with minimal clicks.</p>
              </Fade>
              <Fade left>
                <h2 className="font-color-white">Your Contract. Your Conditions.</h2>
                <p className="lead font-color-white">Customize your experience with us by using your own contract and setting your own terms and conditions.</p>
              </Fade>
              <Fade left>
                <h2 className="font-color-white ">Send Electonically. </h2>
                <p className="lead font-color-white">Send your documents via email directly from the app, example email below:</p>
              </Fade>
              <Fade left>
                <div className="card" style={{ marginTop: "0rem" }}>
                  <div className="card-body" style={{ padding: "1.5rem" }}>
                    You have received a rate confirmation from: <br /> <b>Jordan Brokers Inc.</b>
                    <div className="d-flex justify-content-center" style={{ padding: "1.5rem", textAlign: "center" }}>
                      <a href="/rateconfirmation/demo" target="_blank">
                        <button className="btn btn-primary">View Rate Confirmation</button>
                      </a>
                    </div>
                    <div className="d-none d-sm-block" style={{ borderTop: "1px solid #EAEAEC", marginTop: "15px" }}>

                      <div style={{ paddingTop: "25px" }}>
                        <p className="text-muted">If you’re having trouble with the button above, copy and paste the URL below into your web browser.</p>
                        <p className="text-muted d"> https://www.freightbroker.ai/rateconfirmation/demo</p>
                      </div>

                    </div>
                  </div>
                </div>
              </Fade>

            </div>
            <Fade right>
              <div className="col-md-5 d-flex justify-content-center d-none d-sm-block">
                <img src={freightbrokerai_mobile} />
              </div>
            </Fade>
          </div>
        </div>


      </div>



    </>


  )

}