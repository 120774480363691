import React, { useEffect, useState } from "react";
import {
    Route, Switch, Redirect, Router, useHistory
} from 'react-router-dom';

import EquipmentField from "./EquipmentField";
import EquipmentHeader from "./EquipmentHeader";
import EquipmentTrailerTable from "./EquipmentTrailerTable";
import EquipmentTruckTable from "./EquipmentTruckTable";

import { useAuth, authFetch, logout } from "../../auth";


export default function EquipmentContainer(props) {
    const [truckData, setTruckData] = useState([]);
    const [trailerData, setTrailerData] = useState([]);

  
    const handleNewTruck = (newTruck) => {
        const dataCopy = [...truckData];
        setTruckData([...dataCopy, newTruck])
        props.updateTruckList([...dataCopy, newTruck])
    }
    const handleRemoveTruck = (removeTruck) => {
        const dataCopy = [...truckData];
        dataCopy.splice(removeTruck.row.index, 1)
        setTruckData(dataCopy)
        authFetch(`https://fltbd.herokuapp.com/api/equipment/truck/${removeTruck.value}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
        console.log(dataCopy)
    }
    
    const handleNewTrailer = (newTrailer) => {
        const dataCopy = [...trailerData];
        setTrailerData([...dataCopy, newTrailer])
        props.updateTrailerList([...dataCopy, newTrailer])
    }
    const handleRemoveTrailer = (removeTrailer) => {
        const dataCopy = [...trailerData];
        dataCopy.splice(removeTrailer.row.index, 1)
        setTrailerData(dataCopy)
        authFetch(`https://fltbd.herokuapp.com/api/equipment/trailer/${removeTrailer.value}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
        console.log(dataCopy)
        props.updateTrailerList(dataCopy)

    }
   
    useEffect(() => {
      if (props && props.trucks && props.trailers) {
          setTruckData(props.trucks)
          setTrailerData(props.trailers)
      }
  }, [props.trucks], [props.trailers])
    
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);
    return (
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <EquipmentHeader />
            <EquipmentField parentNewTruck={handleNewTruck}  parentNewTrailer={handleNewTrailer}/>
            <EquipmentTruckTable truckData={truckData} parentRemoveTruck={handleRemoveTruck}/>
            <EquipmentTrailerTable trailerData={trailerData} parentRemoveTrailer={handleRemoveTrailer}/>
            
        </div>
    )
}