import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect,
    useParams
} from "react-router-dom";

export default function ResetPassword() {
    const params = useParams();

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errorPassword, setErrorPassword] = useState('')
    const [errorConfirmPassword, setErrorConfirmPassword] = useState('')
    const [notFound, setNotFound] = useState(false)
    const [redirect, setRedirect] = useState()
    const [reset, setReset] = useState()

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value)
    }

    const onSubmitClick = (e) => {
        e.preventDefault()

        if (checkPassword(password)) {
            if (password !== confirmPassword) {
                setErrorConfirmPassword('Error dont match')
            }
            else {
                let opts = {
                    "hashed_password": password,
                }
                console.log("test")
                fetch(`https://fltbd.herokuapp.com/api/reset-password/${params.forgot_id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(opts),
                    headers: { 'Content-Type': 'application/json' },
                }).then(r => r.json())
                    .then(data => {
                        console.log(data)
                        if (data.success === true) {
                            setReset(true)
                            setTimeout(() => {
                                setRedirect(true)
                            }, 1000)
                        }

                    })
            }
        }
        else {
            setErrorPassword('The password format is not correct..')
        }


    }
    function checkPassword(str) {
        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,32}$/;
        return re.test(str);
    }
    function viewPassword(e) {
        var x = document.getElementById("passwordField");

        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    function viewConfirmPassword(e) {
        var x = document.getElementById("confirmPasswordField");

        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    useEffect(() => {
        fetch(`https://fltbd.herokuapp.com/api/reset-password/${params.forgot_id}`, {
            method: 'GET',
        })

            .then(response => response.json())
            .then(d => {
                if (d.status_code === 689) {

                    setNotFound(true)
                }
                if(d.status_code === 593) {

                    setNotFound(true)
                }
            })


    }, [])

    return (
        <>
            {redirect ? 
                <Redirect to='../login'/>
                :

                <div class="container-tight py-4">
                    <div class="text-center mb-4">
                    </div>
                    <form class="card card-md" action="." method="get">
                        <div class="card-body">
                            <h2 class="card-title text-center mb-4">Reset Password</h2>

                            {notFound ?

                                <div class="mt-2">
                                    <p>Request Not Found or Expired</p>
                                    <p>If you have forgot your password please use the link below:</p>
                                    <a href="/forgot-password">https://fltbd.com/forgot-password</a>
                                </div>
                                :
                                reset ?
                                <p class="text-muted mb-4">Great! Password Reset. You can go to the <Link to='../login'>Login page</Link>  now or wait for us to redirect you in 5 seconds</p>
                                :
                                <>
                                    <div class="mb-2">
                                        <label class="form-label">Password </label>
                                        <small class="form-hint mt-2">
                                            Your password must be 6-32 characters long, and contain at least one special character, one UPPERCASE letter and one lowercase letter.
                                        </small>
                                        <div class="input-group input-group-flat mt-2">
                                            <input type="password" id="passwordField" class="form-control" placeholder="Password" onChange={handlePasswordChange} value={password} autocomplete="off" />
                                            <span class="input-group-text">
                                                <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewPassword}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                                                </a>
                                            </span>
                                        </div>
                                        <small class="text-danger">{errorPassword}</small>
                                    </div>
                                    <div class="mb-2">
                                        <label class="form-label">Confirm Password </label>
                                        <div class="input-group input-group-flat">
                                            <input type="password" id="confirmPasswordField" class="form-control" placeholder="Confirm Password" onChange={handleConfirmPasswordChange} autocomplete="off" />
                                            <span class="input-group-text">
                                                <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewConfirmPassword}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                                                </a>
                                            </span>
                                        </div>
                                        <small class="text-danger">{errorConfirmPassword}</small>
                                    </div>
                                    <div class="form-footer">
                                        <button type="submit" class="btn btn-primary w-100" onClick={onSubmitClick}>Reset Password</button>
                                    </div>
                                </>
                            }

                        </div>

                    </form>
                </div>
            }
        </>
    )

}