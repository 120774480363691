import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination
} from "react-table";
import { matchSorter } from "match-sorter";
import axios from "axios";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
    useHistory
} from "react-router-dom"
import { useAuth, authFetch, logout } from "../../auth";
import RequestRemove from "./Modals/RequestRemove";

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;
    background-color: white;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 0px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  input {
      width: 50%;
      
  }
  color-white {
      background-color: white;
  }

`

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {
    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}



function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function DoubleBrokerTableBuild({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );
    const history = useHistory();
    const doubleClickRow = (e) => {
        const values = e.values.location_id
        history.push({
            pathname: window.location.href = `/location/${values}`,
            //remove window.location.href if you want to load from state
        })
    }
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        usePagination,
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    //const firstPageRows = rows.slice(0, rows.length);

    return (
        <>
            <div className="card">
                <div class="table-responsive">
                    <table className="table-responsive card-table table-vcenter" {...getTableProps()}>

                        <thead>

                            <tr>

                                <th
                                    colSpan={visibleColumns.length}
                                    style={{
                                        textAlign: "left",
                                        backgroundColor: "white",

                                    }}
                                >
                                    <span>Search: </span>
                                    <br />
                                    <input
                                        type="text"
                                        value={globalFilter || ""}
                                        placeholder="Search By Company Name, MC, or DOT"
                                        onChange={e => setGlobalFilter(e.target.value)}
                                        style={{
                                            fontSize: "1.1rem",
                                            border: "0",
                                            width: "100%",
                                        }}
                                    />
                                </th>
                            </tr>
                            {headerGroups.map((headerGroup) => (
                                <tr style={{ backgroundColor: "#f6e2b3" }} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                            {/* Render the columns filter UI */}

                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div class="row m-3">
                            <RequestRemove />


                </div>

            </div>
        </>
    );
}
// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";


function DoubleBrokerTable(props) {
    const [logged] = useAuth()
    const columns = useMemo(
        () => [

            {
                Header: "Company Name",
                accessor: "db_name"
            },
            {
                Header: "MC Number",
                accessor: "mc_number"
            },
            {
                Header: "DOT Number",
                accessor: "dot_number"
            },
            {
                Header: "Phone Number",
                accessor: "phone_number"
            },



        ],
        []
    );

    //const data = React.useMemo(() => makeData(500), []);
    const history = useHistory();
    const [data, setData] = useState([]);
    useEffect(() => {
        authFetch("https://fltbd.herokuapp.com/api/double-broker-list").then(response => {
            if (response.status === 500) {
                //history.go(0)
                logout()
                console.log(response.status)
                //return null
            }
            return response.json()
        })
            .then(response => {
                if (response) {
                    setData(response)

                }
            })
    }, [])



    return (
        <Styles>
            <DoubleBrokerTableBuild columns={columns} data={data} />
        </Styles>
    );
}

export default DoubleBrokerTable;


