import React, { useEffect, useState } from "react";
import { useAuth, login } from "../../auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,  
  Redirect
} from "react-router-dom";


export default function Login(props) {
  const history = useHistory();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [hidePW, setHidePW] = useState(false)
  
  const [logged] = useAuth();
  const onSubmitClick = (e) => {
    e.preventDefault()
    let opts = {
      "email": email.toLowerCase(),
      "password": password
    }
    fetch('https://fltbd.herokuapp.com/login', {
      method: 'post',
      body: JSON.stringify(opts),
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
      
    }).then(r => r.json())    
      .then(token => {
        if (token && token.access_token) {
          login(token)
          
          history.replace("/dashboard")
          history.go(0)
        }
        else {
          if(token) {
            console.log(token)
            if(token.status_code == 401) {
              setError("The email and/or password are incorrect")
            }         
            if(token.status_code == 489) {
              setError("The email and/or password are incorrect")
            }
            if(token.status_code == 491) {
              setError("User found, but email has not been confirmed.")
            }
          }
        }
      })
  }
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }
  function viewPassword() {
    var x = document.getElementById("currentPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  return (
    <div class="jumbo-login-not-implemented">

      <div class="container-tight py-4">

        <div class="text-center mb-4">

          <h1>Sign In</h1>
        </div>
        <form class="card card-md better-box-shadow" action="#" method="get" autocomplete="off">

          <div class="card-body">

            <h2 class="card-title text-center mb-4">
              {error}
            </h2>
            <div class="mb-3">
              <label class="form-label">Email Address</label>
              <input type="text" name="email" class="form-control" placeholder="Enter Email" onChange={handleEmailChange} value={email} />
            </div>
            <div class="mb-2">
              <label class="form-label">
                Password
                <span class="form-label-description">
                  <a href="/forgot-password">Forgot Password</a>
                </span>
              </label>
              
              <div class="input-group input-group-flat">
                <input type="password" name="password" class="form-control" id="currentPassword" placeholder="Password" onChange={handlePasswordChange} value={password} autocomplete="off" />
                {hidePW ? null : 
                <span class="input-group-text">
                  <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewPassword}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                  </a>
                </span>
                    }
              </div>
        
            </div>

            <div class="form-footer">
              <button type="submit" class="btn btn-primary w-100" onClick={onSubmitClick}>Sign in</button>
            </div>
            <hr />
            <p>Don't have an account? <Link to="/signup"><a href="#"><b>Sign Up</b></a></Link></p> 
          </div>


        </form>
        
      </div>
    </div>
  )
}