import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';
import { Alert } from "react-bootstrap";


export default function CustomersItemBillingDetails(props) {
    const [state, setState] = useState('')
    const [showingSuccessAlert, setShowingSuccessAlert] = useState(false);
    const [showingFailAlert, setShowingFailAlert] = useState(false);
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }


    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        props.parentCustomerDetails({ [e.target.name]: e.target.value })
        //props.updateCustomer({[e.target.name]: e.target.value, customer_id: props.customer_id})
        //props.updateSingle({[e.target.name]: e.target.value, customer_id: props.customer_id, type: "customers", id: props.id})

    }


    useEffect(() => {
        setState(props)
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await authFetch(`https://fltbd.herokuapp.com/api/customers/${props.customer_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    billing_contact_name: state.billing_contact_name,
                    billing_email: state.billing_email,
                    billing_phone_number: state.billing_phone_number,
                    billing_street_address: state.billing_street_address,
                    billing_state: state.billing_state,
                    billing_city: state.billing_city,
                    billing_zipcode: state.billing_zipcode,
                }),
                headers: { 'Content-Type': 'application/json' },
            });
            
            if (response.status === 304) {
                throw new Error('No fields were updated');
            } 
            if (!response.ok) {
                onShowFailAlert()

                console.error('PATCH request failed');
                setEdit(!edit);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if(data.success) {
                onShowSuccessAlert()
                setEdit(!edit);
                props.updateCustomer(state);
            } else {
                throw new Error(`Update failed! reason: ${data.reason}`);
            }
            setEdit(!edit);
        } catch (error) {
            console.error('An error occurred during the fetch:', error);
        }
    }
    const onShowSuccessAlert = () => {
        setShowingSuccessAlert(true,
            setTimeout(() => {
                setShowingSuccessAlert(false)
            }, 2000)
        );
    }
    const onShowFailAlert = () => {
        setShowingFailAlert(true,
            setTimeout(() => {
                setShowingFailAlert(false)
            }, 2000)
        );
    }
    return (
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Billing Details</h3>
                <div class="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>
                </div>
            </div>
            <div class="card-body">
            <Alert variant="success" show={showingSuccessAlert} >
                    Remit Information Updated.
                </Alert>
                <Alert variant="warning" show={showingFailAlert} >
                    Nothing Updated
                </Alert>
                
                <form onSubmit={handleSubmit}>
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Contact Name</label>
                            <input type="text" class="form-control" value={state.billing_contact_name} name="billing_contact_name" onChange={handleChange} required readOnly={edit} />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" value={state.billing_email} name="billing_email" onChange={handleChange} readOnly={edit} />

                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Phone Number</label>
                            <div class="input-group">

                                <input type="text"
                                    class="form-control"
                                    value={state.billing_phone_number}
                                    name="billing_phone_number"
                                    onChange={handleChange}
                                    required readOnly={edit} />
                            </div>
                        </div>
                    </div>



                    <div class="form-row">
                        <div class="col-md-12 mb-3">
                            <label class="form-label">Remit Street Address</label>
                            <input type="text" class="form-control" value={state.billing_street_address} name="billing_street_address" readOnly={edit} onChange={handleChange} />
                        </div>

                    </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Remit City</label>
                            <input type="text" class="form-control" value={state.billing_city} name="billing_city" readOnly={edit} onChange={handleChange} />
                        </div>
                        <div className="form-group col-md-4 mb-3">
                            <label className="form-label" for="inputState">Remit State</label>
                            <select className="form-control" value={state.billing_state} name="billing_state" readOnly={edit} onChange={handleChange} >
                                <option value="">Choose...</option>

                                <optgroup label="U.S. States/Territories">
                                    <option value="AK">AK - Alaska</option>
                                    <option value="AL">AL - Alabama</option>
                                    <option value="AR">AR - Arkansas</option>
                                    <option value="AZ">AZ - Arizona</option>
                                    <option value="CA">CA - California</option>
                                    <option value="CO">CO - Colorado</option>
                                    <option value="CT">CT - Connecticut</option>
                                    <option value="DC">DC - District of Columbia</option>
                                    <option value="DE">DE - Delaware</option>
                                    <option value="FL">FL - Florida</option>
                                    <option value="GA">GA - Georgia</option>
                                    <option value="HI">HI - Hawaii</option>
                                    <option value="IA">IA - Iowa</option>
                                    <option value="ID">ID - Idaho</option>
                                    <option value="IL">IL - Illinois</option>
                                    <option value="IN">IN - Indiana</option>
                                    <option value="KS">KS - Kansas</option>
                                    <option value="KY">KY - Kentucky</option>
                                    <option value="LA">LA - Louisiana</option>
                                    <option value="MA">MA - Massachusetts</option>
                                    <option value="MD">MD - Maryland</option>
                                    <option value="ME">ME - Maine</option>
                                    <option value="MI">MI - Michigan</option>
                                    <option value="MN">MN - Minnesota</option>
                                    <option value="MO">MO - Missouri</option>
                                    <option value="MS">MS - Mississippi</option>
                                    <option value="MT">MT - Montana</option>
                                    <option value="NC">NC - North Carolina</option>
                                    <option value="ND">ND - North Dakota</option>
                                    <option value="NE">NE - Nebraska</option>
                                    <option value="NH">NH - New Hampshire</option>
                                    <option value="NJ">NJ - New Jersey</option>
                                    <option value="NM">NM - New Mexico</option>
                                    <option value="NV">NV - Nevada</option>
                                    <option value="NY">NY - New York</option>
                                    <option value="OH">OH - Ohio</option>
                                    <option value="OK">OK - Oklahoma</option>
                                    <option value="OR">OR - Oregon</option>
                                    <option value="PA">PA - Pennsylvania</option>
                                    <option value="PR">PR - Puerto Rico</option>
                                    <option value="RI">RI - Rhode Island</option>
                                    <option value="SC">SC - South Carolina</option>
                                    <option value="SD">SD - South Dakota</option>
                                    <option value="TN">TN - Tennessee</option>
                                    <option value="TX">TX - Texas</option>
                                    <option value="UT">UT - Utah</option>
                                    <option value="VA">VA - Virginia</option>
                                    <option value="VT">VT - Vermont</option>
                                    <option value="WA">WA - Washington</option>
                                    <option value="WI">WI - Wisconsin</option>
                                    <option value="WV">WV - West Virginia</option>
                                    <option value="WY">WY - Wyoming</option>
                                </optgroup>
                                <optgroup label="Canadian Provinces">
                                    <option value="AB">AB - Alberta</option>
                                    <option value="BC">BC - British Columbia</option>
                                    <option value="MB">MB - Manitoba</option>
                                    <option value="NB">NB - New Brunswick</option>
                                    <option value="NF">NF - Newfoundland</option>
                                    <option value="NT">NT - Northwest Territories</option>
                                    <option value="NS">NS - Nova Scotia</option>
                                    <option value="NU">NU - Nunavut</option>
                                    <option value="ON">ON - Ontario</option>
                                    <option value="PE">PE - Prince Edward Island</option>
                                    <option value="QC">QC - Quebec</option>
                                    <option value="SK">SK - Saskatchewan</option>
                                    <option value="YT">YT - Yukon Territory</option>

                                </optgroup>
                            </select>
                        </div>


                        <div class="col-md-4 mb-3">
                            <label class="form-label">Remit Zipcode</label>
                            <input type="text" class="form-control" value={state.billing_zipcode} name="billing_zipcode" readOnly={edit} onChange={handleChange} />
                        </div>
                    </div>



                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>

        </div>
    )
}