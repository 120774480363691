import React, { useEffect, useState } from "react";
import {
    Route, Switch, Redirect, Router, useHistory, Link
} from 'react-router-dom';



export default function DriversHeader() {

    return (
        <div class="d-sm-flex align-items-center justify-content-between">

                <h1>Drivers</h1>
            
           
        </div>

    )
}