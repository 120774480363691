import React, { Component, useState, useEffect } from 'react';
import { authFetch } from '../../auth';
import { v4 as uuidv4 } from "uuid";
import { useParams, withRouter, useHistory } from "react-router"


export default function CarriersAdd(props) {
    const history = useHistory()
    const [submitting, setSubmitting] = useState(false)
    const uniqueID = uuidv4()
    const [state, setState] = useState({ factor: "No", carrier_id: uniqueID })
    const [carriers, setCarriers] = useState([])

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value

        })
    }
    const handleNewCarrier = (NewCarrier) => {
        const dataCopy = [...carriers]
        setCarriers([...dataCopy, NewCarrier])
        props.updateCarriersList([...dataCopy, NewCarrier])
    }

    const handleSubmit = e => {
        e.preventDefault();


        authFetch(`https://fltbd.herokuapp.com/api/carriers`, {
            method: 'PUT',
            body: JSON.stringify({
                carrier_id: state.carrier_id,
                carrier_name: state.carrier_name,
                carrier_dba: state.carrier_dba,
                physical_address: state.physical_address,
                phone_number: state.phone_number,
                carrier_mc: state.carrier_mc,
                carrier_dot: state.carrier_dot,
                billing_address: state.carrier_billing_address,
                federal_id: state.federal_id,
                factor: state.factor,
                factoring_company: state.factoring_company
            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(r => r.json())
        .then(g => {
            if(g.success) {
                handleNewCarrier({...state, id: g.id})
            }
        })    
        setSubmitting(true)

        
        setTimeout(() => {
            history.push({
                pathname: `/carriers`,
            })
        }, 1500);

    }
    const testSubmit = e => {
        e.preventDefault();



        setSubmitting(true)
        setTimeout(() => {
            setSubmitting(false)

        }, 1500);



    }
    useEffect(() => {
        if (props && props.carriers) {
            setCarriers(props.carriers)
        }
    }, [props.carriers])
    useEffect(() => {
        document.title = props.title || "";
      }, [props.title]);

    return (

        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div class="d-sm-flex">
                <h1>Add Carrier</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">

                            <div class="card-header">
                                <h3 class="card-title">Carrier Details</h3>


                            </div>

                            <div class="card-body">
                                <label class="form-label" >Carrier Name</label>
                                <input type="text" class="form-control" value={state.carrier_name} name="carrier_name" onChange={handleChange} required />
                                <label class="form-label" >Carrier DBA</label>
                                <input type="text" class="form-control" value={state.carrier_dba} name="carrier_dba" onChange={handleChange} />
                                <label class="form-label" >Carrier Physical Address </label>
                                <textarea class="form-control" value={state.physical_address} name="physical_address" onChange={handleChange} required />
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label class="form-label">Phone</label>
                                        <input type="text" class="form-control" value={state.phone_number} name="phone_number" onChange={handleChange} required />
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label class="form-label">MC Number</label>
                                        <input type="text" class="form-control" value={state.carrier_mc} name="carrier_mc" onChange={handleChange} />
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label class="form-label">DOT Number</label>
                                        <input type="text" class="form-control" value={state.carrier_dot} name="carrier_dot" onChange={handleChange} required />
                                    </div>
                                </div>




                            </div>

                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Billing Details</h3>

                            </div>

                            <div class="card-body">

                                <label class="form-label" >Carrier Billing Address </label>
                                <textarea class="form-control" value={state.carrier_billing_address} name="carrier_billing_address" onChange={handleChange} required />
                                <label class="form-label" >Federal ID</label>
                                <input type="text" class="form-control" value={state.federal_id} name="federal_id" onChange={handleChange} required />
                                <label class="form-label" >Does the carrier factor their receivables? </label>
                                <div class="form-check">
                                    <input class="form-check-input" value="Yes" name="factor" checked={state.factor === "Yes"} type="radio" onChange={handleChange} />
                                    <label class="form-check-label">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" value="No" name="factor" checked={state.factor === "No"} type="radio" onChange={handleChange} />
                                    <label class="form-check-label">
                                        No
                                    </label>
                                </div>
                                {state.factor == "Yes" ? <div>
                                    <label class="form-label" >Factoring Company </label>
                                    <input type="text" class="form-control" value={state.factoring_company} name="factoring_company" onChange={handleChange} />
                                </div> : null}


                            </div>

                        </div>

                        <br />
                        {submitting ?
                            <button class="btn btn-success float-right" disabled>Submitting...</button>
                            :

                            <button type="submit" className="btn btn-success float-right">Submit</button>
                        }

                    </div>


                </div>

            </form>
        </div>

    )
}

