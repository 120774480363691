import React, { useState, useEffect, Component } from 'react'
import { authFetch } from '../../auth';
import Select, { createFilter, components } from 'react-select'
import { useHistory, withRouter, useParams } from "react-router-dom";


export default function LocationsInfo(props) {
    const params = useParams();

    const [showEdit, setShowEdit] = useState(false)
    const [edit, setEdit] = useState(false)
    const [dockHeight, setDockHeight] = useState()
    const [showDockInfo, setShowDockInfo] = useState()
    const [showHoursInfo, setHoursInfo] = useState()
    const [loading, setLoading] = useState(true)
    const [showInfo, setShowInfo] = useState()
    const [hours, setHours] = useState({
        "Monday": {
            "open": "08:00",
            "close": "17:00"
        },
        "Tuesday": {
            "open": "08:00",
            "close": "17:00"
        },
        "Wednesday": {
            "open": "08:00",
            "close": "17:00"
        },
        "Thursday": {
            "open": "08:00",
            "close": "17:00"
        },
        "Friday": {
            "open": "08:00",
            "close": "17:00"
        },
        "Saturday": {
            "open": "09:00",
            "close": "15:00"
        },
        "Sunday": {
            "open": "CLOSED",
            "close": "CLOSED"
        }

    })

    const changeEdit = () => {
        setEdit(!edit);
    }
    const handleSubmit = e => {
        e.preventDefault();

        if (!showEdit) {
            // This is when you are setting the hours for the first time. Resusing the showEdit state.
            if (showHoursInfo && showDockInfo) {
                console.log("Hours & Dock")
                authFetch(`https://fltbd.herokuapp.com/api/locations-info/${params.location_id}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        hours: hours,
                        dock_height: dockHeight
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                setEdit(false);
                setShowEdit(true)
                props.updateLocationInfo({params, dockHeight, hours})
            }
            if (showHoursInfo && !showDockInfo) {
                console.log("Hours")
                authFetch(`https://fltbd.herokuapp.com/api/locations-info/${params.location_id}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        hours: hours,
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                setEdit(false);
                setShowEdit(true)
                props.updateLocationInfo({params, hours})
            }
            if (!showHoursInfo && showDockInfo) {
                console.log("Dock")
                authFetch(`https://fltbd.herokuapp.com/api/locations-info/${params.location_id}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        dock_height: dockHeight
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                setEdit(false);
                setShowEdit(true)
                props.updateLocationInfo({params, dockHeight})
            }
            
            
           
        }
        else {
            console.log("--- PATCHING ---")
            if (showHoursInfo && showDockInfo) {
                console.log("Hours & Dock")
                authFetch(`https://fltbd.herokuapp.com/api/locations-info/${params.location_id}`, {
                    method: 'PATCH',
                    body: JSON.stringify({
                        hours: hours,
                        dock_height: dockHeight
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                setEdit(false);
                setShowEdit(true)
                //props.updateLocationInfo({params, dockHeight, hours})
            }
            if (showHoursInfo && !showDockInfo) {
                console.log("Hours")
                authFetch(`https://fltbd.herokuapp.com/api/locations-info/${params.location_id}`, {
                    method: 'PATCH',
                    body: JSON.stringify({
                        hours: hours,
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                setEdit(false);
                setShowEdit(true)
                //props.updateLocationInfo({params, dockHeight, hours})
            }
            if (!showHoursInfo && showDockInfo) {
                console.log("Dock")
                authFetch(`https://fltbd.herokuapp.com/api/locations-info/${params.location_id}`, {
                    method: 'PATCH',
                    body: JSON.stringify({
                        dock_height: dockHeight
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                setEdit(false);
                setShowEdit(true)
                //props.updateLocationInfo({params, dockHeight, hours})
            }
            props.updateLocationInfo({params, dockHeight, hours})
        }

    }

    const timeList = [
        { value: 'CLOSED', label: 'CLOSED' },
        { value: '00:00', label: '00:00' },
        { value: '00:30', label: '00:30' },
        { value: '01:00', label: '01:00' },
        { value: '01:30', label: '01:30' },
        { value: '02:00', label: '02:00' },
        { value: '02:30', label: '02:30' },
        { value: '03:00', label: '03:00' },
        { value: '03:30', label: '03:30' },
        { value: '04:00', label: '04:00' },
        { value: '04:30', label: '04:30' },
        { value: '05:00', label: '05:00' },
        { value: '05:30', label: '05:30' },
        { value: '06:00', label: '06:00' },
        { value: '06:30', label: '06:30' },
        { value: '07:00', label: '07:00' },
        { value: '07:30', label: '07:30' },
        { value: '08:00', label: '08:00' },
        { value: '08:30', label: '08:30' },
        { value: '09:00', label: '09:00' },
        { value: '09:30', label: '09:30' },
        { value: '10:00', label: '10:00' },
        { value: '10:30', label: '10:30' },
        { value: '11:00', label: '11:00' },
        { value: '11:30', label: '11:30' },
        { value: '12:00', label: '12:00' },
        { value: '12:30', label: '12:30' },
        { value: '13:00', label: '13:00' },
        { value: '13:30', label: '13:30' },
        { value: '14:00', label: '14:00' },
        { value: '14:30', label: '14:30' },
        { value: '15:00', label: '15:00' },
        { value: '15:30', label: '15:30' },
        { value: '16:00', label: '16:00' },
        { value: '16:30', label: '16:30' },
        { value: '17:00', label: '17:00' },
        { value: '17:30', label: '17:30' },
        { value: '18:00', label: '18:00' },
        { value: '18:30', label: '18:30' },
        { value: '19:00', label: '19:00' },
        { value: '19:30', label: '19:30' },
        { value: '20:00', label: '20:00' },
        { value: '20:30', label: '20:30' },
        { value: '21:00', label: '21:00' },
        { value: '21:30', label: '21:30' },
        { value: '22:00', label: '22:00' },
        { value: '22:30', label: '22:30' },
        { value: '23:00', label: '23:00' },
        { value: '23:30', label: '23:30' },
        { value: '24:00', label: '24:00' },
    ]

    const TimeSelect = (props) => {

        return (
            <>
                <select
                    value={hours[props.day][props.type]}
                    onChange={(e) => { handleChange(e, props.day, props.type) }}
                    disabled={!edit}
                >
                    {timeList.map((option) => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                </select>
            </>
        )
    }
    function handleChange(e, day, type) {

        setHours(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [type]: e.target.value
            }
        }))
    }
    const initialHours = () => {
        setHoursInfo(true)
        setEdit(true)
    }
    const ShowHours = () => {
        if (showHoursInfo === false) {
            return (
                <>
                    <button className="btn btn-primary" onClick={initialHours}>Set Location Hours</button>
                </>
            )
        }
        return (
            <>
                <table>

                    <tr><th>Monday</th><td><TimeSelect day="Monday" type='open' /> - <TimeSelect day="Monday" type='close' /></td></tr>
                    <tr><th>Tuesday</th><td><TimeSelect day="Tuesday" type='open' /> - <TimeSelect day="Tuesday" type='close' /></td></tr>
                    <tr><th>Wednesday</th><td><TimeSelect day="Wednesday" type='open' /> - <TimeSelect day="Wednesday" type='close' /></td></tr>
                    <tr><th>Thursday</th><td><TimeSelect day="Thursday" type='open' /> - <TimeSelect day="Thursday" type='close' /></td></tr>
                    <tr><th>Friday</th><td><TimeSelect day="Friday" type='open' /> - <TimeSelect day="Friday" type='close' /></td></tr>
                    <tr><th>Saturday</th><td><TimeSelect day="Saturday" type='open' /> - <TimeSelect day="Saturday" type='close' /></td></tr>
                    <tr><th>Sunday</th><td><TimeSelect day="Sunday" type='open' /> - <TimeSelect day="Sunday" type='close' /></td></tr>
                </table>
            </>
        )

    }
    const initialDock = () => {
        setShowDockInfo(true)
        setEdit(true)
    }
    const ShowDockHeight = () => {

        if (showDockInfo === false) {
            return (
                <>
                    <button className="btn btn-primary" onClick={initialDock}>Set Dock Height</button>
                </>
            )
        }
        return (
            <>
                <label class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" onChange={dockHeightChange} checked={dockHeight} disabled={!edit} />
                    <span class="form-label">Not Required or Required</span>

                </label>
            </>
        )
    }
    const dockHeightChange = (e) => {

        setDockHeight(!dockHeight)
    }

    useEffect(() => {
        if (props && props.location_info) {

            if (props.location_info[0] && props.location_info[0].hours !== null) {
                if(props.location_info[0].hours === undefined) {
                    setHoursInfo(false)
                    setLoading(false)
                }
                else {
                setHoursInfo(true)
                setHours(props.location_info[0].hours)
                setShowEdit(true)
                setLoading(false)
                }
            }

            else {
                setHoursInfo(false)
                setLoading(false)
            }
        }

    }, [props.location_info])

    useEffect(() => {
        if (props && props.location_info) {
            if (!props.location_info.length) {
                setShowDockInfo(false)
                setLoading(false)
                

            }
        }
        if (props && props.location_info && props.location_info[0]) {

            if (props.location_info[0] && props.location_info[0].dock_height != null) {

                setDockHeight(props.location_info[0].dock_height)
                setShowEdit(true)
                setShowDockInfo(true)
                setLoading(false)
            }
            
            else {
               
      
                setShowDockInfo(false)
                setLoading(false)
                
            }

        }
        else {
            return
        }
    }, [props.location_info])


    return (
        <div className="card">
            <div className="card-header" style={{ border: "none" }}>
                <h3 className="card-title">Location Info</h3>
                {showEdit ?
                    <div className="edit-button">
                        <a href="#" onClick={changeEdit}> [edit]</a>
                    </div>
                    :
                    null}
            </div>
            {loading ? 
            <div className="card-body">
                <p>Loading...</p>
            </div>
            :
            <>
            <div className="card-body">

                <h3>Hours</h3>
                <ShowHours />


                <h3 className="mt-3">Dock Height</h3>

                <ShowDockHeight />

                <form onSubmit={handleSubmit}>
                    {edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>
            </>
        }
        </div>
    )
}
