import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';

export default function DriversDetails(props) {
    const [state, setState] = useState('')
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }
    useEffect(() => {
        setState(props)
    })
    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        props.parentDriverDetails({[e.target.name]: e.target.value})

    }
    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/drivers/${props.driver_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                first_name: state.first_name,
                last_name: state.last_name,
                dob: state.dob,
                license_number: state.license_number,
                license_state: state.license_state,
                license_exp: state.license_exp, 

            }),
            headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit);
    }

    return (
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Driver Details</h3>
                <div class="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>
                </div>
            </div>
            <div class="card-body">
                <form onSubmit={handleSubmit}>
                    <div class="form-row">
                        <div class="col-md-3 mb-3">
                            <label for="inputFirstName" class="form-label">First Name</label>
                            <input type="text" class="form-control" placeholder="First Name" name="first_name" value={props.first_name} onChange={handleChange} readOnly={edit} />
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="inputMiddleInitial" class="form-label">Middle Initial</label>
                            <input type="text" class="form-control" placeholder="" name="middle_initial" onChange={handleChange} readOnly={edit} />
                        </div>
                        <div class="col-md-5 mb-3">
                            <label for="inputLastName" class="form-label">Last Name</label>
                            <input type="text" class="form-control" placeholder="Last Name" name="last_name" value={state.last_name} onChange={handleChange} readOnly={edit} />
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="imputDOB" class="form-label">Date of Birth</label>
                            <input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dob" value={state.dob} onChange={handleChange} readOnly={edit} />
                        </div>
                    </div>
                    <div class="form-row">

                        <div class="col-md-6 mb-3">
                            <label for="inputLicenseNumber" class="form-label">License Number</label>
                            <input type="text" class="form-control" placeholder="License Number" name="license_number" value={state.license_number}  onChange={handleChange} readOnly={edit} />
                        </div>
                        <div class="col-md-2 mb-3">
                            <label for="inputLicenseNumber" class="form-label">License State</label>
                            <input type="text" class="form-control" placeholder="License State" name="license_state" value={state.license_state} onChange={handleChange} readOnly={edit} />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="inputLicenseNumber" class="form-label">License Exp.</label>
                            <input type="text" class="form-control" placeholder="mm/dd/yyyy" name="license_exp" value={state.license_exp} onChange={handleChange} readOnly={edit} />
                        </div>
                    </div>
                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>

        </div>
    )
}
