import React, { useEffect, useState } from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";

export default function LegalContainer(props) {


    useEffect(() => {
        document.title = props.title || "";
      }, [props.title]);

    return (
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div class="d-sm-flex">
                <div>
                    <h1>{props.page}</h1>
                </div>

            </div>
            <div class="card">
                <div class="card-body">
                    {props.page === "Privacy Policy"
                    ?
                    <PrivacyPolicy />
                    :
                    <TermsConditions />
                    }
                </div>
            </div>
        </div>
    )

}
