import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const LoadingModal = () => {

    return(
  
        <Modal.Body>
            <div class="d-flex justify-content-center">
                <h3>Loading</h3>
            </div>
            <br />
            <div class="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </Modal.Body>


    )
}

export default LoadingModal