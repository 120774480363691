import React, { Component, useState, useEffect } from 'react'
import { v4 as uuidv4 } from "uuid";
import { useHistory, withRouter } from "react-router-dom";
import { authFetch, logout } from '../../auth';
import { Spinner, Button } from "react-bootstrap";
import moment from 'moment';



export default function CustomerNotes(props) {
    const initialState = () => {
        return (props)
    }
    const [loading, setLoading] = useState(false)
    const [remove, setRemove] = useState(false)

    const [state, setState] = useState(initialState)
    const [initials, setInitials] = useState()

    const handleAddNote = (e) => {
        e.preventDefault()
        setLoading(true);
        const note_id = uuidv4()
        const { customer_id } = state.customer_id
        const newNote = {
            content: e.currentTarget.content.value,
            customer_url: state.customer_id,
            customer_id: state.id,
            note_id: note_id,
            creation_date: moment().format("MM/DD/YY")
        }
        setState({
            customer_notes: [...state.customer_notes, newNote]
        });
        props.parentNotesCallBack(state.customer_notes.concat([{
            content: e.currentTarget.content.value,
            author: state.author,
            customer_url: state.customer_id,
            customer_id: state.id,
            note_id: note_id
        }]))
        authFetch(`https://fltbd.herokuapp.com/api/customer_notes/${props.customer_id}`, {
            method: 'PUT',
            body: JSON.stringify({
                content: e.currentTarget.content.value,
                author: state.author,
                customer_url: state.customer_id,
                customer_id: state.id,
                note_id: note_id,

            }),
            headers: {
                'Content-Type': 'application/json',

            },

        })
        setNoteContent('')
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }
    const handleRemoveNote = (idx) => (e) => {

        const removeNote = state.customer_notes.map((note, sidx) => {

            if (idx !== sidx)
                return note;
            fetch(`https://fltbd.herokuapp.com/api/customer_notes/${props.customer_id}/${note.note_id}`, {
                method: 'DELETE',

            })

        });
        props.parentNotesCallBack(state.customer_notes.filter((s, sidx) => idx !== sidx));


    }
    const [noteContent, setNoteContent] = useState('')
    const noteContentChange = (e) => {
        setNoteContent(e.target.value)
    }

    const createInitials = (e) => {
        const firstLetters = e
            .split(' ')
            .map(word => word[0])
            .join('');

        return firstLetters;
    }


    useEffect(() => {
        if (props && props.customer_notes) {
            setState(props)
        }
    }, [props.customer_notes])



    return (
        <>
            <div class="card" >
                <div class="card-body">
                    <h5>Notes</h5>
                    <form onSubmit={handleAddNote}>
                        <textarea name="content" className="form-control" type="text" value={noteContent} onChange={noteContentChange} />
                        {loading ?

                            <div class="float-right">
                                <Spinner animation="border" variant="primary" size="sm" />

                            </div> :
                            <button class="btn btn-dark float-right mt-3" type="submit">+ Add Note</button>
                        }
                    </form>

                </div>
            </div>
            <div class="card" style={{ height: "calc(24rem + 10px)", marginTop: "1rem" }}>
                <div class="card-body card-body-scrollable card-body-scrollable-shadow">

                    <div class="divide-y">
                        {state.customer_notes?.map((note, idx) => (
                            <div key={note.id}>


                                <div class="row ">

                                    <div class="col">
                                        <div class="">
                                            {note.content}
                                        </div>
                                        <div class="text-muted">{moment(note.creation_date).format("MM/DD/YY")} by {note.author}</div>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <button

                                            type="button"
                                            onClick={handleRemoveNote(idx)}
                                            className="small"
                                            class="btn btn-danger float-right">
                                            -
                                        </button>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )


}