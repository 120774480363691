import React, { Component, useState, useEffect } from 'react'
import { v4 as uuidv4 } from "uuid";
import { useHistory, withRouter } from "react-router-dom";
import { authFetch, logout } from '../../auth';
import { Spinner, Button } from "react-bootstrap";
import moment from 'moment';



export default function CarriersItemNotes(props) {
    const initialState = () => {
        return (props)
    }
    const [loading, setLoading] = useState(false)
    const [remove, setRemove] = useState(false)
    const [noteContent, setNoteContent] = useState('')
    const noteContentChange = (e) => {
        setNoteContent(e.target.value)
    }

    const [state, setState] = useState(initialState)


    const handleAddNote = (e) => {
        e.preventDefault()
        setLoading(true);
        const note_id = uuidv4()

        const newNote = {
            content: e.currentTarget.content.value,
            author: props.author,
            carrier_id: props.carrier_id,
            note_id: note_id,
            creation_date: moment().format("MM/DD/YY")
        }

        setState(prevState => {
            const newState = {
                ...prevState,
                notes: [...prevState.notes, newNote]
            };
            props.parentNotesCallBack(newState.notes);
            console.log(props.notes);

            // move your fetch call here, so that it's invoked only after setState has finished
            authFetch(`https://fltbd.herokuapp.com/api/carriers/${props.carrier_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    notes: [newState.notes]
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            return newState; // return new state
        });

        setNoteContent('')
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }
    const handleRemoveNote = (idx) => (e) => {
        setState(prevState => {
            const newState = {
                ...prevState,
                notes: prevState.notes.filter((s, sidx) => idx !== sidx)
            }

            // Send updated notes to the parent
            props.parentNotesCallBack(newState.notes);

            // Use updated state in the fetch call
            authFetch(`https://fltbd.herokuapp.com/api/carriers/${props.carrier_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    notes: [newState.notes]
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            return newState;
        });
    }

    useEffect(() => {
        if (props && props.notes) {
            console.log(props.notes)
            setState({ notes: props.notes })
        }
        else {
            setState({ notes: [] })
        }
    }, [props.notes])





    return (
        <>

            <div class="card" >
                <div class="card-header">
                    <h3 class="card-title">Notes</h3>
                </div>

                <div class="card-body">

                    <form onSubmit={handleAddNote}>
                        <textarea name="content" className="form-control" type="text" value={noteContent} onChange={noteContentChange} />
                        {loading ?

                            <div class="float-right">
                                <Spinner animation="border" variant="primary" size="sm" />

                            </div> :
                            <button class="btn btn-dark float-right mt-3" type="submit">+ Add Note</button>
                        }
                    </form>

                </div>

                <div class="card-body card-body-scrollable card-body-scrollable-shadow">

                    <div class="divide-y">
                        {state.notes?.map((note, idx) => (
                            <div key={note.id}>


                                <div class="row ">

                                    <div class="col">
                                        <div class="">
                                            {note.content}
                                        </div>
                                        <div class="text-muted">{moment(note.creation_date).format("MM/DD/YY")} by {note.author}</div>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <button

                                            type="button"
                                            onClick={handleRemoveNote(idx)}
                                            className="small"
                                            class="btn btn-danger float-right">
                                            -
                                        </button>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )


}