import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../auth";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useRouteMatch,
    useParams
} from "react-router-dom";
import Select from 'react-select'
import DatePicker from "react-datepicker"
import moment from 'moment';
import CustomerInfoEdit from "./EditInvoice/CustomerInfoEdit";
import ItemsInfoEdit from "./EditInvoice/ItemsInfoEdit";




export default function AccountingInvoice(props) {
    const params = useParams();

    const [data, setData] = useState([])
    const [customerID, setCustomerID] = useState()
    const [edit, setEdit] = useState(false)

    const getCustomerID = (Customer) => {
        setCustomerID(Customer.id)
    }

    const changeEdit = () => {
        setEdit(!edit);
    }

    useEffect(() => {
        if (props && props.invoices) {
            const invoiceInfo = props.invoices.filter(invoice => invoice.invoice_id == params.invoice_id)
            setData(invoiceInfo[0])
            
        }

    }, [props.invoices])
    const handleCustomerChange = (Details) => {
        setData(prevState => {
            return { ...prevState,  ...Details  };
        });
        if (Details.terms !== null || Details.terms.length() > 0) {
            getDueDate(Details.terms, data.date)
        }
    }

    const updateCustomer = (Details) => {
        
        setData(prevState => {
            return { ...prevState,  ...Details  };
        });
        if (Details.terms !== null || Details.terms.length() > 0) {
            getDueDate(Details.terms, data.date)
        }

    }
    const handleDetailChange = (e) => {
        if(e.label) {
            setData({...data, status: e.value})
        }
        else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }

    const handleDateChange = (date, name) => {
        const terms = data.terms

        if (name === "date") {
            setData({ ...data, [name]: date })
            getDueDate(terms, date)


        }
    }
    function getDueDate(e, date) {
        if (e == "Net 60") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(60, 'days').toDate() };
            })
        }
        if (e == "Net 45") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(45, 'days').toDate() };
            })
        }
        if (e == "Net 30") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(30, 'days').toDate() };
            })
        }
        if (e == "Net 15") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(15, 'days').toDate() };
            })
        }
        if (e == "Net 10") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(10, 'days').toDate() };
            })
        }
        if (e == "Due On Receipt") {
            setData(prevState => {
                return { ...prevState, due_date: moment(date).add(0, 'days').toDate() };
            })
        }

    }
    return (
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Invoice #{data.invoice_number}</h3>
                <div class="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>

                </div>
            </div>
            <div className="card-body">
                
                        <CustomerInfoEdit {...data} {...props}
                            edit={edit}
                            getCustomerID={getCustomerID}
                            handleDetailChange={handleDetailChange}
                            handleDateChange={handleDateChange}
                            handleCustomerChange={handleCustomerChange}
                            updateCustomer={updateCustomer}
                        />
                        <ItemsInfoEdit {...data} {...props}
                            customer_id={customerID}
                            edit={edit}
                        />


                  
                
            </div>
        </div>
    )
}