import React, { useEffect, useState } from 'react';
import DashboardRecentCarriers from './DashboardRecentCarriers';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory
} from "react-router-dom";



export default function DashboardRecent(props) {
  const [loaded, setLoaded] = useState(false)
  const history = useHistory()
  const doubleClickRow = (e) => {
    const values = e.load_id
    history.push({
      pathname: `/loads/${values}`,

    })
  }

  useEffect(() => {
    if (props && props.loads) {
      setTimeout(() => {
        setLoaded(true)
      }, 1000)
    }
  }, [props.loads])
  return (
    <div>
      <div class="row row-deck">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Recent Loads</h3>
            </div>
            <div class="table-responsive">
              {loaded ?
                <table className="table table-vcenter card-table" >

                  <thead>
                    <tr style={{ borderTop: "1px solid black" }}>
                      <th style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Load Number</th>
                      <th style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Carrier</th>
                      <th style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}>Status</th>
                      <th style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }}></th>

                    </tr>
                  </thead>

                  {props.loads && props.loads.map((load, idx) => (

                    <tr style={{ cursor: "pointer" }} key={load.id} onDoubleClick={() => doubleClickRow(load)} >

                      <td class="align-middle">
                        {load.load_number}
                      </td>
                      <td class="align-middle" >{load.carrier_name}</td>
                      <td class="align-middle">{load.status}</td>
                      <td>
                      {load.accepted === null ?
                        null :
                        <Link to={`/documents/rateconfirmation/${load.load_id}`} >
                          <button class="btn btn-icon btn-primary"><svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>                        </button>
                        </Link>
                        }
                        <Link to={`loads/${load.load_id}`} >
                          <button class="btn btn-icon btn-green">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" /><path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" /><line x1="16" y1="5" x2="19" y2="8" /></svg>
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}

                </table>

                :
                <table class="table table-vcenter card-table">
                  <thead>
                    <tr style={{ borderTop: "1px solid black" }}>
                      <th style={{ borderBottom: "1px solid black", color: "black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0" }} class="placeholder-glow"><span class="placeholder col-12"></span></th>


                    </tr>
                  </thead>



                  <tr class="placeholder-glow">
                    <td>
                      <span class="placeholder col-12"></span>

                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="placeholder col-12"></span>
                    </td>
                  </tr>
                  <tr class="placeholder-glow">
                    <td>
                      <span class="placeholder col-12"></span>
                    </td>
                  </tr>
                  <tr class="placeholder-glow">
                    <td>
                      <span class="placeholder col-12"></span>
                    </td>
                  </tr>
                  <tr class="placeholder-glow">
                    <td>
                      <span class="placeholder col-12"></span>
                    </td>
                  </tr>

                </table>
              }
            </div>
            <div class="card-footer text-center tx-13">
              <Link to="/loads" class="link-03">View All Loads <i class="icon ion-md-arrow-down mg-l-5"></i></Link>
            </div>
          </div>

        </div>

        <div class="col-md-4">
          <div class="card">

            <DashboardRecentCarriers recentCarriers={props.recentCarriers} />
            <div class="card-footer text-center tx-13">
              <Link to="/carriers" class="link-03">View All Carriers <i class="icon ion-md-arrow-down mg-l-5"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}