import React, { Component, useState, setState } from 'react';
import { useAuth, login } from "../../auth";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect
} from "react-router-dom";

function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,32}$/;
    return re.test(str);
}

export default class SignUpUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    handleEmail = e => {
        this.props.parentEmail(e.target.value)
    }
    handlePassword = e => {
        this.props.parentPassword(e.target.value)
    }
    handleConfirmPassword = e => {
        this.setState({ confirmPassword: e.target.value })
        this.setState({ errorConfirmPassword: '' })
    }
    handleFirstName = e => {
        this.props.parentFirstName(e.target.value)
    }
    handleLastName = e => {
        this.props.parentLastName(e.target.value)
    }
    handleSubmit = e => {

        e.preventDefault();
        if (checkPassword(this.props.state.password)) {

            if (this.props.state.password !== this.state.confirmPassword) {
                this.setState({ errorConfirmPassword: 'The passwords do not match.' })
            }
            else {
                let opts = {
                    "email": this.props.state.email.toLowerCase(),
                    "hashed_password": this.props.state.password,
                    "first_name": this.props.state.firstName,
                    "last_name": this.props.state.lastName,
                    "phone_number": this.props.state.phoneNumber,
                    "company_name": this.props.state.companyName,
                    "company_mc": this.props.state.companyMC,
                    "company_dot": this.props.state.companyDOT,
                    "company_address": this.props.state.companyAddress,
                    "type": this.props.state.type,


                }
               
                fetch('https://fltbd.herokuapp.com/registration/signup', {
                    method: 'PUT',
                    body: JSON.stringify(opts),
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(r => r.json())
                    .then(data => {
                        console.log(data)
                        
                        if (data.error_code === '5021') {
                            this.setState({ errorEmail: data.error })
                            console.log(data.error)
                            console.log("not")
                        }
                        if (data.success === true) {
                            this.setState({ success: true })
                            setTimeout(() => {
                                this.setState({success: false})
                                this.setState({ redirect: true })
                            }, 2500)
                            
                        }
                    })
            }
        }
        else {
            this.setState({ errorPassword: 'The password format is not correct..' })
        }
    };



    render() {


        function viewPassword(e) {
            var x = document.getElementById("passwordField");

            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
        function viewConfirmPassword(e) {
            var x = document.getElementById("confirmPasswordField");

            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
        return (
            <>
                {this.state.success === true ?
                    "Once you confirm your email you will be able to login, we will redirect you to the login page"
                    :
                    this.state.redirect === true ?
                    <Redirect to='../login' />
                    :
                    <div>
                        <h2>Admin User</h2>
                        <div class="mb-3">
                            <label class="form-label">Email Address</label>
                            <input type="text" name="email" class="form-control" placeholder="Enter email" value={this.props.state.email} onChange={this.handleEmail} />
                            <small class="text-danger">{this.state.errorEmail}</small>

                        </div>
                        <div class="mb-2">
                            <label class="form-label">Password </label>
                            <small class="form-hint">
                                Your password must be 6-32 characters long, and contain at least one special character, one UPPERCASE letter and one lowercase letter.
                            </small>
                            <div class="input-group input-group-flat">
                                <input type="password" id="passwordField" class="form-control" placeholder="Password" onChange={this.handlePassword} autocomplete="off" />
                                <span class="input-group-text">
                                    <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewPassword}>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                                    </a>
                                </span>
                            </div>
                            <small class="text-danger">{this.state.errorPassword}</small>
                        </div>
                        <div class="mb-2">
                            <label class="form-label">Confirm Password </label>
                            <div class="input-group input-group-flat">
                                <input type="password" id="confirmPasswordField" class="form-control" placeholder="Confirm Password" onChange={this.handleConfirmPassword} autocomplete="off" />
                                <span class="input-group-text">
                                    <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewConfirmPassword}>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                                    </a>
                                </span>
                            </div>
                            <small class="text-danger">{this.state.errorConfirmPassword}</small>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">First Name</label>
                            <input type="text" name="first_name" class="form-control" placeholder="Enter first name" value={this.props.state.firstName} onChange={this.handleFirstName} />
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Last Name</label>
                            <input type="text" name="last_name" class="form-control" placeholder="Enter last name" value={this.props.state.lastName} onChange={this.handleLastName} />
                        </div>
                        <div class="d-flex justify-content-center">

                            {3 == 3 ?
                                <button class="btn btn-lg btn-success" onClick={this.handleSubmit}>Submit</button>
                                :
                                "Sorry, we aren't acceptiong signups"
                            }
                        </div>    
                    </div>
                }
             </>
        )
    }
}