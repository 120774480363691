import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';

export default function EquipmentTruckItemDetails(props) {
    const [state, setState] = useState('')
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }

    useEffect(() => {
        setState(props)
    })

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        props.parentEquipmentDetails({[e.target.name]: e.target.value})

    }
   

    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/equipment/truck/${props.equipment_id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            year: state.year,
            make_model: state.make_model,
            state: state.state,
            plate: state.plate,
            vin: state.vin,

          }),
          headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit);
    }

    return(
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Equipment Details</h3>
                <div class="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>

                </div>
            </div>
            <div class="card-body">
            <form onSubmit={handleSubmit}>

                <div class="form-row">
                    <div class="col-md-2 mb-3">
                        <label class="form-label">Year</label>
                        <input type="text" class="form-control"  value={state.year} name="year" onChange={handleChange} required readOnly={edit}  />
                    </div>
                    <div class="col-md-10 mb-3">
                        <label class="form-label">Make & Model</label>
                        <input type="text" class="form-control"  value={state.make_model} name="make_model" onChange={handleChange}  readOnly={edit}  />
                    </div>                  
                </div>
                <div class="form-row">
                    <div class="col-md-1 mb-3">
                        <label class="form-label">State</label>
                        <input type="text" class="form-control"  value={state.state} name="state" onChange={handleChange} required readOnly={edit}  />
                    </div>
                    <div class="col-md-2 mb-3">
                        <label class="form-label">Plate</label>
                        <input type="text" class="form-control"  value={state.plate} name="plate" onChange={handleChange} readOnly={edit}  />
                    </div>
                    <div class="col-md-9 mb-3">
                        <label class="form-label">VIN</label>
                        <input type="text" class="form-control"  value={state.vin} name="vin" onChange={handleChange}  readOnly={edit}  />
                    </div>
                    
                </div>
                {!edit ?
                    <div>
                        <br />
                        <button type="submit" className="btn btn-success float-right">Make Changes</button>
                    </div>
                    : null}
            </form>
            </div>
            
        </div>
    )
}