import React, { useMemo, useState, useEffect, Component } from "react";
import styled from "styled-components";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import SendPacket from "./Modals/SendPacket";

export class DashboardHeader extends Component {
    state = {
        isOpen: false
    };

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false })

    render() {
        return (
            <div>

                <div class="d-sm-flex align-items-center justify-content-between">
                        
                    <h1>Dashboard</h1>
                   
                </div>


            </div>
        )
    }
}

export default DashboardHeader


