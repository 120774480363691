import React, { Component, useEffect, useState } from 'react'
import { useHistory, withRouter, useParams } from "react-router-dom";
import { authFetch, logout } from '../../auth'
import { v4 as uuidv4 } from "uuid";
import DriversDetails from './DriversDetails';
import DriversAlerts from './DriversAlerts';
import NotFound from '../Home/NotFound';

export default function DriversItem(props) {

    const [data, setData] = useState()
    const [notFound, setNotFound] = useState(false)
    const params = useParams();

    const handleDriverDetails = (Details) => {
        const detailKey = Object.keys(Details)[0]
        const detailValue = Object.values(Details)[0]
        setData({...data, [detailKey]: detailValue})
    }
    const handleAlertDetails = (Details) => {

        setData({...data, [Details]: !Details})
    }
    const handleLicenseAlert = (Alert) => {
        setData({...data, license_alert: Alert})
    }
    const handleTwicAlert = (Alert) => {
        setData({...data, twic_card_alert: Alert})
    }
    const handleMedicalAlert = (Alert) => {
        setData({...data, medical_alert: Alert})
    }
   
    useEffect(() => {
        
        if(props  &&  props.drivers) {
            console.log(params.driver_id)
            const driverInfo = props.drivers.filter(driver => driver.driver_id ==  params.driver_id)
            setData(driverInfo[0])
            if(driverInfo[0] === undefined) {
                setNotFound(true)
            }
            
        }
    }, [props.drivers])
    if(notFound){
        return(
            <NotFound />
        )
    }
    return (
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div class="d-sm-flex align-items-center justify-content-between">
                <h1>Manage Driver</h1>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <DriversDetails 
                        parentDriverDetails={handleDriverDetails}
                        {...data} 
                    />
                </div>
                <div class="col-md-4">
                    <DriversAlerts
                        parentAlertDetails={handleAlertDetails} 
                        parentLicenseAlert={handleLicenseAlert}
                        parentTwicAlert={handleTwicAlert}
                        parentMedicalAlert={handleMedicalAlert}
                        {...data}
                    />
                </div>
            </div>
        </div>

    )

}