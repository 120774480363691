import React, { useEffect, useState, Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { authFetch } from "../../auth";
import { Alert } from "react-bootstrap";


export default function LocationsField(props) {
    const [state, setState] = useState('')
    const [newLocation, setNewLocation] = useState('')
    const [collapse, setCollapse] = useState(true)
    const [showingAlert, setShowingAlert] = useState(false);


    const handleChange = e => {
        setNewLocation({
            ...newLocation,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = e => {
        e.preventDefault();
        const uniqueID = uuidv4()
        authFetch(`https://fltbd.herokuapp.com/api/locations`, {
            method: 'PUT',
            body: JSON.stringify({
                location_id: uniqueID,
                location_name: newLocation.location_name,
                branch_number: newLocation.branch_number,
                contact_name: newLocation.contact_name,
                phone_number: newLocation.phone_number,
                street_address: newLocation.street_address,
                city: newLocation.city,
                state: newLocation.state,
                zipcode: newLocation.zipcode,

            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(r => r.json())
        .then(data => {
            if (data.success) {
                onShowAlert()
                props.parentNewLocation({
                    location_id: uniqueID,
                    location_name: newLocation.location_name,
                    branch_number: newLocation.branch_number,
                    contact_name: newLocation.contact_name,
                    phone_number: newLocation.phone_number,
                    street_address: newLocation.street_address,
                    city: newLocation.city,
                    state: newLocation.state,
                    zipcode: newLocation.zipcode,
                })
                setNewLocation({
                    location_name: '',
                    branch_number: '',
                    contact_name: '',
                    phone_number: '',
                    street_address: '',
                    city: '',
                    state: '',
                    zipcode: ''
                })
            }
        })     
    }
    const onShowAlert = () => {
        setShowingAlert(true,
            setTimeout(() => {
                setShowingAlert(false)
            }, 2000)
        );
    }

    return (
        <div class="card" >
            <div class="card-header" data-toggle="collapse" 
        data-target="#addLocation" onClick={() => setCollapse(!collapse)} style={{cursor: "pointer"}}>
                <h3 class="card-title" >Add New Location</h3>
                <div class="edit-button">
                    {collapse ?
                    <button class="btn btn-success" type="button" data-toggle="collapse" data-target="#addLocation" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapse(false)}>
                        +
                    </button>
                    :
                    <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#addLocation" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapse(true)}>
                        -
                    </button>
                    }
                </div>
            </div>

            <div class="card-body collapse" id="addLocation">
            <Alert variant="success" show={showingAlert} >
                    Location Successfully Added.
                </Alert>
                <form onSubmit={handleSubmit}>
                    <div class="form-group">
                        <label for="inputLocationName">Location Name</label>
                        <input type="text" class="form-control" placeholder="Enter Company Name" value={newLocation.location_name} name="location_name" onChange={handleChange} required />
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputContactName">Contact Name</label>
                            <input type="text" class="form-control" id="inputContactName" placeholder="Contact Name" value={newLocation.contact_name} name="contact_name" onChange={handleChange} />
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPhoneNumber">Phone Number</label>
                            <input type="tel" class="form-control" id="inputPhoneNumber" placeholder="555-555-5555" value={newLocation.phone_number} name="phone_number" onChange={handleChange} />

                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputAddress">Address</label>
                        <input type="text" class="form-control" id="inputAddress" placeholder="Enter Street Address, example: 1234 Main St" value={newLocation.street_address} name="street_address" onChange={handleChange} />
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputCity">City</label>
                            <input type="text" class="form-control" id="inputCity" placeholder="Enter City" value={newLocation.city} name="city" onChange={handleChange} />
                        </div>
                        
                        <div className="form-group col-md-4">
                            <label className="form-label" for="inputState">State</label>
                            <select className="form-control" value={newLocation.state} name="state" onChange={handleChange}>
                                <option value="">Choose...</option>
                            
                                    <optgroup label="U.S. States/Territories">
                                    <option value="AK">AK - Alaska</option>
                                    <option value="AL">AL - Alabama</option>
                                    <option value="AR">AR - Arkansas</option>
                                    <option value="AZ">AZ - Arizona</option>
                                    <option value="CA">CA - California</option>
                                    <option value="CO">CO - Colorado</option>
                                    <option value="CT">CT - Connecticut</option>
                                    <option value="DC">DC - District of Columbia</option>
                                    <option value="DE">DE - Delaware</option>
                                    <option value="FL">FL - Florida</option>
                                    <option value="GA">GA - Georgia</option>
                                    <option value="HI">HI - Hawaii</option>
                                    <option value="IA">IA - Iowa</option>
                                    <option value="ID">ID - Idaho</option>
                                    <option value="IL">IL - Illinois</option>
                                    <option value="IN">IN - Indiana</option>
                                    <option value="KS">KS - Kansas</option>
                                    <option value="KY">KY - Kentucky</option>
                                    <option value="LA">LA - Louisiana</option>
                                    <option value="MA">MA - Massachusetts</option>
                                    <option value="MD">MD - Maryland</option>
                                    <option value="ME">ME - Maine</option>
                                    <option value="MI">MI - Michigan</option>
                                    <option value="MN">MN - Minnesota</option>
                                    <option value="MO">MO - Missouri</option>
                                    <option value="MS">MS - Mississippi</option>
                                    <option value="MT">MT - Montana</option>
                                    <option value="NC">NC - North Carolina</option>
                                    <option value="ND">ND - North Dakota</option>
                                    <option value="NE">NE - Nebraska</option>
                                    <option value="NH">NH - New Hampshire</option>
                                    <option value="NJ">NJ - New Jersey</option>
                                    <option value="NM">NM - New Mexico</option>
                                    <option value="NV">NV - Nevada</option>
                                    <option value="NY">NY - New York</option>
                                    <option value="OH">OH - Ohio</option>
                                    <option value="OK">OK - Oklahoma</option>
                                    <option value="OR">OR - Oregon</option>
                                    <option value="PA">PA - Pennsylvania</option>
                                    <option value="PR">PR - Puerto Rico</option>
                                    <option value="RI">RI - Rhode Island</option>
                                    <option value="SC">SC - South Carolina</option>
                                    <option value="SD">SD - South Dakota</option>
                                    <option value="TN">TN - Tennessee</option>
                                    <option value="TX">TX - Texas</option>
                                    <option value="UT">UT - Utah</option>
                                    <option value="VA">VA - Virginia</option>
                                    <option value="VT">VT - Vermont</option>
                                    <option value="WA">WA - Washington</option>
                                    <option value="WI">WI - Wisconsin</option>
                                    <option value="WV">WV - West Virginia</option>
                                    <option value="WY">WY - Wyoming</option>
                                </optgroup>
                                <optgroup label="Canadian Provinces">
                                    <option value="AB">AB - Alberta</option>
                                    <option value="BC">BC - British Columbia</option>
                                    <option value="MB">MB - Manitoba</option>
                                    <option value="NB">NB - New Brunswick</option>
                                    <option value="NF">NF - Newfoundland</option>
                                    <option value="NT">NT - Northwest Territories</option>
                                    <option value="NS">NS - Nova Scotia</option>
                                    <option value="NU">NU - Nunavut</option>
                                    <option value="ON">ON - Ontario</option>
                                    <option value="PE">PE - Prince Edward Island</option>
                                    <option value="QC">QC - Quebec</option>
                                    <option value="SK">SK - Saskatchewan</option>
                                    <option value="YT">YT - Yukon Territory</option>
                                
                                </optgroup>
                            </select>
                        </div>
                        <div class="form-group col-md-2">
                            <label for="inputZip">ZIP Code</label>
                            <input type="text" class="form-control" id="inputZip" placeholder="Enter ZIP Code" value={newLocation.zipcode} name="zipcode" onChange={handleChange} />
                        </div>
                    </div>
                    <button class="btn btn-success float-right">Create Location</button>
                </form>
            </div>

        </div >
    )

}

