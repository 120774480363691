import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from 'axios'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { v4 as uuidv4 } from "uuid";
import { authFetch } from '../../auth';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


const LoadsShippers = props => {
    const history = useHistory();

    const [state, setState] = useState({ shippers: [] });
    const [locations, setLocations] = useState();
    const [focus, setFocus] = useState(false)

    function moveUp(index, e) {
        if (index === 0) {
            return;
        }


        props.parentActionOrder2(index, e);
    }

    function moveDown(index, e) {
        const actionsInfo = props.actions.filter(action => action.action == "s")
        if (index === actionsInfo.length - 1) {
            return;
        }



        props.parentActionOrder2(index, e);
    }



    const getOrder = () => {
        if (props && props.actions) {
            const actionsInfo = props.actions.filter(action => action.action == "s")
            if (actionsInfo === null || actionsInfo.length === 0) {
                return 1
            }
            else {
                let sortActions = actionsInfo.sort((a, b) => a.order - b.order)
                const getLast = sortActions.slice(-1)
                const orderNumber = parseInt(getLast[0].order) + 1
                return orderNumber
            }
        }
        else {
            return
        }
    }

    const handleOrder = (idx) => (e) => {
        const orderChange = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx)
                return shipper;

            props.parentActionOrder(shipper, e)
            return { ...shipper, order: parseInt(shipper.order) + 1 }
        })


    }

    const handleAddShipper = () => {
        const getUniqueID = uuidv4()
        const newDate = moment(new Date()).toDate()
        const newShipper =
        {
            location_name: '',
            action: 's',
            load_match: props.loadID,
            action_id: getUniqueID,
            date: newDate,
            order: getOrder()
        }
        setState({
            shippers: [...state.shippers, newShipper]
        })
        authFetch(`https://fltbd.herokuapp.com/actions`, {
            method: 'PUT',
            body: JSON.stringify({
                location_name: '',
                action: 's',
                load_match: props.loadID,
                action_id: getUniqueID,
                date: moment(newDate).format("L"),
                order: getOrder()
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        //Testing adding to actions in LoadsItem - Works Below...
        props.parentAddAction(newShipper)
        //Fazing out parentShippers most likely...
        //props.parentShippers(state.shippers.concat([{newShipper}]))


    }

    const handleRemoveShipper = (idx) => () => {
        state.shippers.map((shipper, sidx) => {
            if (idx !== sidx)
                return shipper;
            authFetch(`https://fltbd.herokuapp.com/actions/${shipper.action_id}`, {
                method: 'DELETE',

            })
            props.parentRemoveAction(shipper)
        })
        const removeShipper = state.shippers.filter((s, sidx) => idx !== sidx)
        setState({
            shippers: removeShipper
        })
        //fazing out..
        //props.parentShippers(state.shippers.filter((s, sidx) => idx !== sidx));
    }

    const handleShipperChange = (idx) => (e) => {
        const shipperChange = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx)
                return shipper;
            authFetch(`https://fltbd.herokuapp.com/actions/${shipper.action_id}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    location_name: e.location_name,
                    load_match: props.load_id,
                    street_address: e.street_address,
                    city: e.city,
                    state: e.state,
                    contact_name: e.contact_name,
                    zipcode: e.zipcode,
                    pickup_number: e.pickup_number,
                    cargo_description: e.cargo_description,
                    phone_number: e.phone_number,
                    action: 's',
                    action_id: shipper.action_id,
                    order: idx + 1
                })

            })
            props.parentActionChange(e, shipper.action_id)

            return {
                ...shipper,
                location_name: e.location_name,
                street_address: e.street_address,
                city: e.city,
                state: e.state,
                zipcode: e.zipcode,
                contact_name: e.contact_name,
                phone_number: e.phone_number,
                pickup_number: e.pickup_number,
                cargo_description: e.cargo_description,
                extra_notes: e.extra_notes,
            };


        })
        //May not need this, because useEffect is getting ran again
        //setState({shippers: shipperChange})


        //props.parentActionChange(shipperChange)
        //props.parentShippers(shipperChange)

    }

    useEffect(() => {
        if (props && props.actions) {
            const actionsInfo = props.actions.filter(action => action.action == "s")
            const options = actionsInfo.map(d => ({
                "location_name": d.location_name,
                "load_match": props.load_id,
                "street_address": d.street_address,
                "city": d.city,
                "state": d.state,
                "contact_name": d.contact_name,
                "zipcode": d.zipcode,
                "phone_number": d.phone_number,
                "action_id": d.action_id,
                "pickup_number": d.pickup_number,
                "cargo_description": d.cargo_description,
                "extra_notes": d.extra_notes,
                "order": d.order,
                "date": d.date

            }))
            //This sorts by date - which works, but not ideal to actions that perform on the same day, order will work better.
            //let sortActions = options.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse()));

            let sortActions = options.sort((a, b) => a.order - b.order)
            setState({ ...state, shippers: sortActions })

            //Fazing out..
            //props.parentShippers(sortActions)
        }
    }, [props.actions])

    useEffect(() => {
        if (props && props.locations) {
            const options = props.locations.map(d => ({
                "location_name": d.location_name,
                "value": d.location_id,
                "label": d.location_name,
                "street_address": d.street_address,
                "city": d.city,
                "state": d.state,
                "zipcode": d.zipcode,
                "contact_name": d.contact_name,
                "phone_number": d.phone_number,
            }))
            setLocations(options)

        }
    }, [props], [props.locations])
    //https://eight-bites.blog/en/2021/06/wait-user-typing/
    const [timer, setTimer] = useState(null)
    const handleChange = (idx) => (e) => {

        clearTimeout(timer)

        const updateShipper = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx)
                return shipper;

            props.parentActions(e, shipper.action_id)
            return { ...shipper, [e.target.name]: e.target.value }

        })

        setState({ ...state, shippers: updateShipper })
        //props.parentShippers({ ...state, shippers: updateShipper })

    }
    const handleDateChange = (idx) => (date) => {
        const newDate = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx)
                return shipper;
            authFetch(`https://fltbd.herokuapp.com/actions/${shipper.action_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    date: moment(date).format("L")
                }),
                headers: { 'Content-Type': 'application/json' },
            })
            const e = {
                target: {
                    name: "date",
                    value: moment(date).format("L")
                }
            }
            props.parentActions(e, shipper.action_id)
            return { ...shipper, date: date };
        });
        setState({ ...state, shippers: newDate })


        //Below probably will be outdated and fazed
        //props.parentShippers({...state, shippers: newDate})

    }
    const handleFocus = (idx) => (e) => {
        setFocus(false)
        const updateShipper = state.shippers.map((shipper, sidx) => {
            if (idx !== sidx)
                return shipper;

            authFetch(`https://fltbd.herokuapp.com/actions/${shipper.action_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    [e.target.name]: e.target.value
                }),
                headers: { 'Content-Type': 'application/json' },
            })


            return { ...shipper, [e.target.name]: e.target.value }

        })

    }

    return (
        <>
            <div>


                <div class="card">
                    <div class="card-body">
                        <form>
                            <button type="button" onClick={handleAddShipper} className="small" class="btn btn-lime float-right">Add Pick Up</button>
                        </form>
                        <h3 class="card-title">Shippers </h3>
                    </div>
                </div>
                {state && state.shippers.map((shipper, idx) => (
                    <div class="card">
                        <div class="card-body">
                            <div key={shipper.action_id} className="shipper alert alert-lime mb-4">
                                {shipper.location_name === ""
                                    ?
                                    <label><b>{`Pick Up #${idx + 1}`}</b> - Select Location Below</label>
                                    :
                                    <label><b>{`Pick Up #${idx + 1}`}</b></label>
                                }
                                <Select
                                    options={locations}
                                    placeholder={shipper.location_name}
                                    onChange={handleShipperChange(idx)}

                                />


                                <br />

                                {3 === 2 ?
                                    <div>
                                        <button name="moveUp" value={shipper.order} onClick={(e) => moveUp(idx, e)}>Move Up</button>
                                        <button name="moveDown" value={shipper.order} onClick={(e) => moveDown(idx, e)}>Move Down</button>
                                    </div>
                                    : null}
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-sm">Street Address</span>
                                    </div>
                                    <input
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        type="text"
                                        value={shipper.street_address}
                                        name="street_address" class="form-control"
                                        onChange={handleChange(idx)} />
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">City</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={shipper.city}
                                                name="city"
                                                class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">State</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={shipper.state}
                                                name="state" class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">Zipcode</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                name="zipcode"
                                                value={shipper.zipcode}
                                                class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-default">Contact</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={shipper.contact_name}
                                                class="form-control"
                                                name="contact_name"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">Phone Number</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                name="phone_number"
                                                value={shipper.phone_number}
                                                class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group input-group-default">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Pick Up Number</span>
                                    </div>
                                    <input
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        type="text"
                                        value={shipper.pickup_number}
                                        name="pickup_number"
                                        class="form-control"
                                        onChange={handleChange(idx)} />
                                </div>
                                <br />
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Cargo Description </span>
                                    </div>
                                    <textarea
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        class="form-control"
                                        value={shipper.cargo_description}
                                        name="cargo_description"
                                        onChange={handleChange(idx)} />
                                </div>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Extra Notes </span>
                                    </div>
                                    <textarea
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        class="form-control"
                                        value={shipper.extra_notes}
                                        name="extra_notes"
                                        onChange={handleChange(idx)} />
                                </div>

                                <div className="row">
                                    <div className="col-6">

                                        <DatePicker
                                            selected={moment(shipper.date).toDate()}
                                            name="date"
                                            className="form-control"
                                            onChange={handleDateChange(idx)} />
                                    </div>
                                    <div className="col-4">
                                    </div>
                                    <div className="col-2">
                                        <input type="text" className="form-control" name="order" value={shipper.order} onChange={handleChange(idx)} />
                                    </div>
                                    {3 === 2 ?
                                        <div className="col-4">
                                            <button
                                                type="button"
                                                name="upArrow"
                                                value={shipper.order}
                                                onClick={handleOrder(idx)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="16" y1="9" x2="12" y2="5" /><line x1="8" y1="9" x2="12" y2="5" /></svg>
                                            </button>
                                            <button
                                                type="button"
                                                name="downArrow"
                                                value={shipper.order}
                                                onClick={handleOrder(idx)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1="12" y1="5" x2="12" y2="19" /><line x1="16" y1="15" x2="12" y2="19" /><line x1="8" y1="15" x2="12" y2="19" /></svg>
                                            </button>
                                        </div>
                                        :
                                        null}
                                </div>

                                <br />


                                <button
                                    type="button"
                                    onClick={handleRemoveShipper(idx)}
                                    className="small"
                                    class="btn btn-ghost-danger float-right">
                                    Remove
                                </button>

                            </div>
                        </div>

                    </div>
                ))}



            </div>

        </>
    )


}

export default LoadsShippers