import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    Redirect,
    useParams
  } from "react-router-dom";

export default function ConfirmEmail(props) {
    const params = useParams();
    const [verifying, setVerifying] = useState(true)
    const [confirmed, setConfirmed] = useState()
    const [redirect, setRedirect] = useState()

    useEffect(() => {
        fetch(`https://fltbd.herokuapp.com/api/confirm-email/${params.confirmation_id}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', }
            })
            
            .then(response => response.json())
            .then(d => {
                if(d.success === true ) {
                    setConfirmed(true)
                    setVerifying(false)
                    setTimeout(() => {
                        setRedirect(true)
                    }, 5000)
                }
                if(d.success === false) {
                    setConfirmed(false)
                    setVerifying(false)
                    
                }
            }
                
                )
            
    }, [])

    return (
        <>
        {redirect ? 
            <Redirect to="../login" />   
            :
        <div class="container-tight py-4">
            <div class="text-center mb-4">
            </div>
            <form class="card card-md" action="." method="get">
                <div class="card-body">
                    <h2 class="card-title text-center mb-4">Confirm Email</h2>
                    {verifying ?              
                        <div>
                        <p class="text-muted mb-4">Veryfing your email now...</p> 
                    </div>
                    : null
                    }
                    {confirmed ? 
                    <>
                    <p class="text-muted mb-4">Great! Email confirmed. You can go to the <Link to='../login'>Login page</Link>  now or wait for us to redirect you in 5 seconds</p>
                     
                    </>
                    
                    :
                    <p class="text-muted mb-4">Oops! Looks like something went wrong. Please go back to your email and try the link again.
                        if you receive the same error please reach out @fltbd
                    </p>  
                    }
                    
                    

                </div>
                
            </form>
        </div>
    }
    </>
    )

}