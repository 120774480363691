import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export class Leftside extends Component {
    render() {
        return (
            <div>
                <div class="contact-wrapper">
                    <div class="contact-navleft">
                        <nav class="nav flex-column">
                            <a href="#tabContact" class="nav-link active" data-toggle="tab">
                                <span data-toggle="tooltip" title="All Contacts" data-placement="right"><i data-feather="users"></i></span>
                            </a>
                            <a href="#tabPhoneCall" class="nav-link" data-toggle="tab">
                                <span data-toggle="tooltip" title="Recently Contacted" data-placement="right"><i data-feather="phone-call"></i></span>
                            </a>
                            <a href="#tabFavorites" class="nav-link" data-toggle="tab">
                                <span data-toggle="tooltip" title="Favorites" data-placement="right"><i data-feather="star"></i></span>
                            </a>
                            <a href="#tabTags" class="nav-link" data-toggle="tab">
                                <span data-toggle="tooltip" title="Contact Labels" data-placement="right"><i data-feather="tag"></i></span>
                            </a>
                            <a href="#tabExport" class="nav-link" data-toggle="tab">
                                <span data-toggle="tooltip" title="Export Contacts" data-placement="right"><i data-feather="upload"></i></span>
                            </a>
                            <a href="" class="nav-link">
                                <span data-toggle="tooltip" title="Contact Settings" data-placement="right"><i data-feather="settings"></i></span>
                            </a>
                        </nav>
                    </div>
                </div>
                <div class="contact-sidebar">
        <div class="contact-sidebar-header">
          <i data-feather="search"></i>
          <div class="search-form">
            <input type="search" class="form-control" placeholder="Search contacts" />
          </div>
          <a href="#modalNewContact" class="btn btn-xs btn-icon btn-primary" data-toggle="modal">
            <span data-toggle="tooltip" title="Add New Contact"><i data-feather="user-plus"></i></span>
          </a>
        </div>
        <div class="contact-sidebar-body">
          <div class="tab-content">
            <div id="tabContact" class="tab-pane fade active show">
              <div class="pd-y-20 pd-x-10 contact-list">
                <label id="contactA" class="contact-list-divider">A</label>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-gray-700">A</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Abigail Johnson</h6>
                    <span class="tx-12">+1-234-567-890</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img14.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Archie Cantones</h6>
                    <span class="tx-12">archie@cantones.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-primary">a</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Allan Rey Palban</h6>
                    <span class="tx-12">allanr@palban.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-offline"><span class="avatar-initial rounded-circle bg-info">a</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Aileen Mante</h6>
                    <span class="tx-12">+1-234-567-890</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <label id="contactB" class="contact-list-divider">B</label>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img11.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Brenda Aceron</h6>
                    <span class="tx-12">brenda@aceron.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-indigo">B</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Brandibelle Yap</h6>
                    <span class="tx-12">byap@urmail.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-gray-800">B</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Brejette Bunggay</h6>
                    <span class="tx-12">+1-234-567-890</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>

                <label id="contactC" class="contact-list-divider">C</label>

                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img13.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Camille Audrey</h6>
                    <span class="tx-12">+1-234-567-890</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img12.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Christine Lerio</h6>
                    <span class="tx-12">+1-234-567-890</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>

                <label id="contactD" class="contact-list-divider">D</label>

                <div class="media">
                  <div class="avatar avatar-sm avatar-offline"><span class="avatar-initial rounded-circle bg-primary">D</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Daniel Calinawan</h6>
                    <span class="tx-12">daniel@youremail.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>

                <label id="contactE" class="contact-list-divider">E</label>

                <div class="media">
                  <div class="avatar avatar-sm avatar-offline"><span class="avatar-initial rounded-circle bg-success">E</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Elvis Vircede</h6>
                    <span class="tx-12">+63929-123-4567</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-gray-700">E</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Elena Salubre</h6>
                    <span class="tx-12">e.salubre@myemail.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
              </div>
            </div>
            <div id="tabPhoneCall" class="tab-pane fade">
              <div class="pd-y-20 pd-x-10 contact-list">
                <label class="contact-list-divider">Recently Contacted</label>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img13.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Camille Audrey</h6>
                    <span class="tx-12">+1-234-567-890</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-offline"><span class="avatar-initial rounded-circle bg-success">E</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Elvis Vircede</h6>
                    <span class="tx-12">+63929-123-4567</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
              </div>
            </div>
            <div id="tabFavorites" class="tab-pane fade">
              <div class="pd-y-20 pd-x-10 contact-list">
                <label class="contact-list-divider">My Favorites</label>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img14.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Archie Cantones</h6>
                    <span class="tx-12">archie@cantones.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img11.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Brenda Aceron</h6>
                    <span class="tx-12">brenda@aceron.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-indigo">B</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Brandibelle Yap</h6>
                    <span class="tx-12">byap@urmail.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img13.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Camille Audrey</h6>
                    <span class="tx-12">+1-234-567-890</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-offline"><span class="avatar-initial rounded-circle bg-success">E</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Elvis Vircede</h6>
                    <span class="tx-12">+63929-123-4567</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
              </div>
            </div>
            <div id="tabTags" class="tab-pane fade">
              <div class="pd-y-20 pd-x-10 contact-list">
                <label class="contact-list-divider">My Family</label>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-gray-700">A</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Abigail Johnson</h6>
                    <span class="tx-12">+1-234-567-890</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><img src="../../assets/img/img14.jpg" class="rounded-circle" alt="" /></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Archie Cantones</h6>
                    <span class="tx-12">archie@cantones.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <label class="contact-list-divider">My Friends</label>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-primary">a</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Allan Rey Palban</h6>
                    <span class="tx-12">allanr@palban.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-offline"><span class="avatar-initial rounded-circle bg-primary">D</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Daniel Calinawan</h6>
                    <span class="tx-12">daniel@youremail.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <div class="media">
                  <div class="avatar avatar-sm avatar-offline"><span class="avatar-initial rounded-circle bg-success">E</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Elvis Vircede</h6>
                    <span class="tx-12">+63929-123-4567</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
                <label class="contact-list-divider">My Officemates</label>
                <div class="media">
                  <div class="avatar avatar-sm avatar-online"><span class="avatar-initial rounded-circle bg-gray-700">E</span></div>
                  <div class="media-body mg-l-10">
                    <h6 class="tx-13 mg-b-3">Elena Salubre</h6>
                    <span class="tx-12">e.salubre@myemail.com</span>
                  </div>
                  <nav>
                    <a href=""><i data-feather="star"></i></a>
                    <a href=""><i data-feather="edit-2"></i></a>
                  </nav>
                </div>
              </div>
            </div>
            <div id="tabExport" class="tab-pane fade">
              <div class="pd-y-25 pd-x-20">
                <h6 class="tx-12 tx-semibold tx-spacing-1 tx-uppercase">Export Contacts</h6>
                <p class="tx-13 tx-color-03 mg-b-20">You can export your contacts and import them into other email apps.</p>
                <div class="form-group">
                  <label class="tx-13">Which contacts do you want to export?</label>
                  <select class="custom-select tx-13">
                    <option value="1" selected>All Contacts</option>
                    <option value="2">My Favorites</option>
                    <option value="3">My Family</option>
                    <option value="4">My Friends</option>
                    <option value="4">My Officemates</option>
                  </select>
                </div>
                <button class="btn btn-sm btn-primary">Export</button>
              </div>
            </div>
          </div>
        </div>
      </div>
            </div>
        )
    }
}

export default Leftside