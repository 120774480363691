import React, { useEffect, useState } from "react";
import { useAuth, authFetch } from "../../auth";
import { Alert } from "react-bootstrap";

function checkPassword(str) {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,32}$/;
  return re.test(str);
}


export default function SettingsPassword(props) {
  const [state, setState] = useState([])
  const [currentPassword, setCurrentPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [currentPasswordError, setCurrentPasswordError] = useState()
  const [newPasswordError, setNewPasswordError] = useState()
  const [confirmPasswordError, setConfirmPasswordError] = useState()
  const [passwordError, setPasswordError] = useState()

  const [showingAlert, setShowingAlert] = useState(false);


  const currentPasswordChange = (e) => {
    setCurrentPassword(e.target.value)
  }

  const newPasswordChange = (e) => {
    setNewPassword(e.target.value)
  }

  const confirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }



  const handleSubmit = (e) => {
    setCurrentPasswordError('')
    setConfirmPasswordError('')
    setNewPasswordError('')
    setConfirmPasswordError('')
    e.preventDefault()
    if (newPassword == undefined) {
      setNewPasswordError('Error password is left blank...')
    }
    if (typeof newPassword !== undefined && typeof confirmPassword !== undefined) {

      if (newPassword !== confirmPassword) {
        setConfirmPasswordError('Error dont match')
      }
      if (newPassword == confirmPassword) {
        if (checkPassword(newPassword)) {
          authFetch(`https://fltbd.herokuapp.com/api/user/change-password`, {
            method: 'PATCH',
            body: JSON.stringify({
              current_password: currentPassword,
              new_password: newPassword
            }),
            headers: { 'Content-Type': 'application/json' },
          }).then(r => r.json())
            .then(data => {
              if (data.success !== "True") {
                //setCurrentPasswordError("Current Password is Not Correct.")
              }
              if (data.message === "Internal Server Error" && data.success !== "True") {
                setCurrentPasswordError("Password is Not Correct.")
              }
              if (data.success === true) {
                onShowAlert()              }

            })

        }
        else {
          setPasswordError('This password does not meet the required format. ')
        }
      }
    }


  }
  const onShowAlert = () => {
    setShowingAlert(true,
      setTimeout(() => {
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setShowingAlert(false)
      }, 2000)
    );
  }




  function viewPassword() {
    var x = document.getElementById("currentPasswordField");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);



  return (

    <div class="card" >
      <div class="card-header">
        <h3 class="card-title">Change Password</h3>
      </div>
      <div class="card-body">

        <Alert variant="success" show={showingAlert} >
          Password Has Been Succesfully Changed.
        </Alert>


        <div class="form-group mb-3 row">

          <label class="form-label col-3 col-form-label"> Current Password</label>
          <div class="col">
            <div class="input-group input-group-flat">
              <input type="password" id="currentPasswordField" value={currentPassword} class="form-control" name="current_password" onChange={currentPasswordChange} />
              <span class="input-group-text">
                <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewPassword}>
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                </a>
              </span>
            </div>
            <small class="text-danger">{currentPasswordError}</small>
          </div>

        </div>

        <hr />

        <small class="text-danger">{confirmPasswordError}</small>
        <small class="text-danger">{passwordError}</small>
        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label">New Password</label>
          <div class="col">
            <input type="password" class="form-control" value={newPassword} placeholder="New Password" name="new_password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$" onChange={newPasswordChange} />
            <small class="form-hint">
              Your password must be 6-32 characters long, and contain at least one special character, one UPPERCASE letter and one lowercase letter.
            </small>

          </div>
        </div>

        <div class="form-group mb-3 row">
          <label class="form-label col-3 col-form-label">Confirm New Password</label>
          <div class="col">
            <input type="password" class="form-control" value={confirmPassword} placeholder="Confirm Password" name="confirm_password" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$" onChange={confirmPasswordChange} />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="float-right">
            <button type="submit" className="btn btn-success">Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}