import React, { useEffect, useState } from "react";
import { useHistory, Link, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useAuth, logout, authFetch } from '../../auth';
import { Spinner, Button } from "react-bootstrap";


export default function SettingsAddUser(props) {
    const history = useHistory();

    const [state, setState] = useState([])
    const [role, setRole] = useState('agent')
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState(generatePassword(25))

    function generatePassword(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const changeRole = (e) => {
        setRole(e.target.value);
    }
    const onSubmitClick = (e) => {
        const uniqID = uuidv4()
        e.preventDefault()
        authFetch('https://fltbd.herokuapp.com/api/create-user', {
            method: 'PUT',
            body: JSON.stringify({
                email: state.email.toLowerCase(),
                user_id: uniqID,
                hashed_password: password,
                first_name: state.firstName,
                last_name: state.lastName,
                phone_number: state.phone_number,
                roles: role
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        
        props.updateList({email: state.email.toLowerCase(),
            hashed_password: password,
            first_name: state.firstName,
            last_name: state.lastName,
            phone_number: state.phone_number,
            user_id: uniqID,
            roles: role})
        
        history.push('/settings/manage-users')


    }
    const newPassword = (e) => {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setPassword(generatePassword(25))
        }, 500);

    };
    function viewPassword() {
        var x = document.getElementById("currentPassword");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Add User</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }


    return (
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Add User</h3>
            </div>

            <form onSubmit={onSubmitClick}>
                <div className="card-body">
                    <div class="form-row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" name="email" value={state.email} onChange={(e) => onChange(e)} />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Generated Password
                                {loading ?

                                    <div class="float-right">
                                        <Spinner animation="border" variant="primary" size="sm" />

                                    </div> :
                                    <div class="float-right" onClick={newPassword}>
                                        <Button variant="primary" size="sm">
                                            Generate New
                                        </Button>
                                    </div>
                                }
                            </label>
                            <div class="input-group input-group-flat">
                                <input type="password" id="currentPassword" class="form-control" name="password" value={password} disabled />
                                <span class="input-group-text" style={{ backgroundColor: "#f4f6fa" }}>
                                    <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewPassword}>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                                    </a>

                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label">First Name</label>
                            <input type="text" class="form-control" name="firstName" value={state.firstName} required onChange={(e) => onChange(e)} />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Last Name</label>
                            <input type="text" class="form-control" name="lastName" value={state.lastName} onChange={(e) => onChange(e)} />
                        </div>
                    </div>

                    <div class="form-row">

                        <div class="col-md-6 mb-3">
                            <label class="form-label">Role</label>
                            <div class="form-selectgroup" onChange={changeRole}>
                                <label class="form-selectgroup-item">
                                    <input type="radio" name="icons" value='admin' class="form-selectgroup-input" />
                                    <span class="form-selectgroup-label">
                                        Admin</span>
                                </label>
                                <label class="form-selectgroup-item">
                                    <input type="radio" name="icons" value='team leader' class="form-selectgroup-input" />
                                    <span class="form-selectgroup-label">
                                        Team Leader</span>
                                </label>
                                <label class="form-selectgroup-item">
                                    <input type="radio" name="icons" value='agent' class="form-selectgroup-input" defaultChecked />
                                    <span class="form-selectgroup-label">
                                        Agent</span>
                                </label>

                            </div>
                            <div class="mt-3">
                                {(() => {
                                    if (role === 'admin') {
                                        return (
                                            <div class="alert alert-danger" role="alert">
                                                <h4 class="alert-heading">Admin Role</h4>
                                                <p>This should be used to give the highest powers to the highest ranking members of your company. Give this role with extreme caution.</p>

                                            </div>
                                        )
                                    } else if (role === 'team leader') {
                                        return (
                                            <div class="alert alert-info" role="alert">
                                                <h4 class="alert-heading">Team Leader Role</h4>
                                                <p>Team leader role description.</p>

                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div class="alert alert-success" role="alert">
                                                <h4 class="alert-heading">Agent Role</h4>
                                                <p>This is the default role, it is also the lowest ranking role. You are safe to give this to new agents/brokers.</p>

                                            </div>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Phone Number</label>
                            <input type="text" class="form-control" name="phone_number" required onChange={(e) => onChange(e)} />
                        </div>
                    </div>

                    <button type="submit" className="btn btn-success float-right mb-3">Create User</button>

                </div>
            </form>
        </div>
    )
}