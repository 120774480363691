import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination
} from "react-table";
import { matchSorter } from "match-sorter";
import axios from "axios";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
    useHistory
} from "react-router-dom"
import { useAuth, authFetch } from "../../auth";

import PendingDelete from "./Modals/PendingDelete";


const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 0px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  input {
      width: 50%;
      
  }


`

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter }
}) {
    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}



function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            }
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0 },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        usePagination,
    );

    // We don't want to render all of the rows for this example, so cap
    // it for this use case

    return (
        <>
            <div className="card">
                <div className="table-responsive">
                    <table className="table-responsive card-table table-vcenter" style={{width: "100%", borderSpacing: "0"}} {...getTableProps()}>
                        <thead>

                            <tr>
                                <th
                                    className="reactTable-th"
                                    colSpan={visibleColumns.length}
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <h2>Pending Carriers </h2>
                                    <small>Carriers who have not completed the set up process.</small>
                                </th>
                            </tr>
                            {headerGroups.map((headerGroup) => (
                                <tr style={{ backgroundColor: "#f6e2b3" }} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th className="reactTable-th" {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                            {/* Render the columns filter UI */}

                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td className="reactTable-td" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <br />
            </div>
        </>
    );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

function PendingTable(props) {


    const handleDeleteSingle = (row) => {
        const dataCopy = [...row.data];
        // It should not matter what you name tableProps. It made the most sense to me.
        dataCopy.splice(row.row.index, 1);
        setData(dataCopy)
        authFetch(`https://fltbd.herokuapp.com/api/pending_packet/${row.value}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
        props.handleDeletePending(dataCopy)
        
    }

    const viewContract = (e) => {
        console.log(e)
        history.push({
            pathname: `/initiate_contract/${e.value}`,
        })

    }
    const changeStatus = (e) => {
        /// DELETE
        const dataCopy = [...data]
        const mappedData = dataCopy.map(g => (g.contract_id === e.Metadata.contract_id ? Object.assign({}, g, { status: e.RecordType }) : g))
        props.handlePostmarkUpdate(mappedData)
        
        //NOT WORKING...
        //props.updateSingle(row, e)
      }

    const columns = React.useMemo(
        () => [

            {
                Header: "Carrier Name",
                accessor: "carrier_name"
            },
            {
                Header: "Carrier Email",
                accessor: "carrier_email",
                // Use our custom `fuzzyText` filter on this column
                filter: "fuzzyText",
                Cell: (row) => (
                    <div>
                        {row.row.original.postmark_status === "Delivery" ?
                        <>
                        {row.value} <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="blue" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="3" y="5" width="18" height="14" rx="2" /><polyline points="3 7 12 13 21 7" /></svg>
                        </>
                        :
                        row.row.original.postmark_status === "Open" ?
                        <>
                        {row.value} <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="green" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline points="3 9 12 15 21 9 12 3 3 9" /><path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" /><line x1="3" y1="19" x2="9" y2="13" /><line x1="15" y1="13" x2="21" y2="19" /></svg>                 
                        </>
                        :
                        row.row.original.postmark_status === "Bounce" ?
                        <>
                        {row.value} <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /><line x1="12" y1="8" x2="12" y2="12" /><line x1="12" y1="16" x2="12.01" y2="16" /></svg>
                        </>
                        :
                        <>
                        {row.value}
                        </>
                        }
                    </div>
                )

            },

            {
                Header: "Status",
                accessor: "status",
                disableSortBy: true,

            },
            {
                Header: "Contract ID",
                accessor: "contract_id",

                Cell: (row) => (
                    <div>
                        <button class="btn btn-icon btn-primary" onClick={() => { viewContract(row) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>                        </button>
                        <PendingDelete
                            row={row}
                            parentDeleteSingle={handleDeleteSingle}
                        />
                    </div>
                )

            },
        ],
        []
    );

    const history = useHistory();
    const [data, setData] = useState([]);

    useEffect(() => {

        if (props && props.pendingCarriersData) {
            setData(props.pendingCarriersData)

        }
    }, [props.pendingCarriersData])

  


    return (
        <div>

            <Styles>
                <Table columns={columns} data={data} />
            </Styles>
        </div>
    );
}

export default PendingTable;
