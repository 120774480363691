import React, { Component, Suspense, useState, useEffect } from 'react';
import Leftside from './Leftside';
import Header from './Header'
import Footer from './Footer'
import DashboardContainer from './Dashboard/DashboardContainer'
import CustomersContainer from './Customers/CustomersContainer'
import CustomersAdd from './Customers/CustomersAdd'
import CustomersItem from './Customers/CustomersItem';
import CarriersAdd from './Carriers/CarriersAdd'
import CarriersItem from './Carriers/CarriersItem'
import LocationsContainer from './Locations/LocationsContainer'
import LocationsAdd from './Locations/LocationsAdd';
import LocationsItem from './Locations/LocationsItem'


import {
  Route, Switch, Redirect, Router,
} from 'react-router-dom';

import CarriersContainer from './Carriers/CarriersContainer';
import LoadsContainer from './Loads/LoadsContainer';
import styled from "styled-components";
import ReportsContainer from './Reports/ReportsContainer';

import { useAuth, login, authFetch, logout } from '../auth';
import Login from './Login/Login';
import ProfileContainer from './Profile/ProfileContainer';
import LoadsItem from './Loads/LoadsItem';
import RateConfirmationContainer from './Documents/RateConfirmationContainer';
import SignUp from './Login/SignUp';
import ForgotPassword from './Login/ForgotPassword';

import InitiateContract from './Documents/Contracts/InitiateContract';
import Contract from './Documents/Contracts/Contract';
import ContractRedirect from './Documents/Contracts/ContractRedirect';
import HomeContainer from './Home/HomeContainer';
import SettingsContainer from './Settings/SettingsContainer';

import EquipmentContainer from './Equipment/EquipmentContainer';
import EquipmentTruckItem from './Equipment/EquipmentTruckItem';
import EquipmentTrailerItem from './Equipment/EquipmentTrailerItem';
import DriversContainer from './Drivers/DriversContainer';
import DriversItem from './Drivers/DriversItem';
import LoadsDocuments from './Loads/LoadsDocuments';
import ConfirmEmail from './Login/ConfirmEmail';
import ResetPassword from './Login/ResetPassword';
import DoubleBrokerContainer from './Resources/DoubleBrokerContainer';
import NotFound from './Home/NotFound';
import SettingsUploadLocations from './Settings/SettingsUploadLocations';
import AccountingContainer from './Accounting/AccountingContainer';
import OnboardModal from './Modals/OnBoardModal';
import LegalContainer from './Legal/LegalContainer';
import RateConfirmationDemo from './Documents/RateConfirmationDemo';
import VerifyRate from './Accounting/VerifyRate';



const PrivateRoute = ({ component: Component, ...rest }) => {
  const [logged] = useAuth();
  const [state, setState] = useState()

  return <Route {...rest} render={(props) => (
    logged
      ? <Component {...props} {...state} />
      : <Redirect to='/login' />
  )} />
}

const LogInRoute = ({ component: Component, ...rest }) => {
  const [logged] = useAuth();

  return <Route {...rest} render={(props) => (
    !logged
      ? <Component {...props} />
      : <Redirect to='/dashboard' />
  )} />
}

export default function Layout() {
  const [logged] = useAuth();
  const [company, setCompany] = useState({ company_tc: '&nbsp;', company_contract: '&nbsp;', type: 'broker' })
  const [user, setUser] = useState()
  const [initials, setInitials] = useState([])
  const [contracts, setContracts] = useState()
  const [db, setDB] = useState()
  const [onBoard, setOnBoard] = useState(false)
  const [activeSub, setActiveSub] = useState(true)

  const handleCompanyTC = (companyTC) => {
    if (companyTC === "<p></p>") {
      setCompany({ ...company, company_tc: "" })

    }
    else {
      setCompany({ ...company, company_tc: companyTC })

    }
  }
  const handleCompanyContract = (CompanyContract) => {
    if (CompanyContract === "<p></p>") {
      setCompany({ ...company, company_contract: "" })
    }
    else {
      setCompany({ ...company, company_contract: CompanyContract })
    }
  }

  const updateEmployeeList = (updatedList) => {

    setCompany({ ...company, employees: updatedList })
  }

  const updateTruckList = (updatedList) => {

    setCompany({ ...company, trucks: updatedList })
  }
  const updateTrailerList = (updatedList) => {
    setCompany({ ...company, trailers: updatedList })
  }
  const updateCustomersList = (updatedList) => {
    setCompany({ ...company, customers: updatedList })
  }

  const updateLoadList = (updatedList) => {
    setCompany({ ...company, loads: updatedList })

  }

  const updateInvoiceList = (updatedList) => {
    setCompany({ ...company, invoices: updatedList })

  }
  const updateCarriersList = (updatedList) => {
    setCompany({ ...company, carriers: updatedList })

  }
  const createInvoice = (newInvoice, mappedData) => {
    setCompany({ ...company, invoices: newInvoice, loads: mappedData })

  }




  const updateCustomer = (updatedDetails) => {
    setCompany(prevState => {
      const newState = { ...prevState };
      newState.customers = newState.customers.map((item) => {
        if (item.customer_id === updatedDetails.customer_id) {
          return { ...item, ...updatedDetails};
        }
        return item;
      });
      return newState;
    });
  }

  const updateCompany = (Details) => {
    setCompany(prevState => {
      // Copy the current state
      let newState = {...prevState};

      // Iterate over Details to update each key/value pair
      for (let key in Details) {
        if (newState.hasOwnProperty(key)) {
          newState[key] = Details[key];
        }
      }

      return newState;
    });
    
  }

  const updateEmployee = (updatedEmployee) => {
    const updatedEmployees = company.employees.map(employee =>
        employee.user_id === updatedEmployee.user_id ? {...employee, ...updatedEmployee} : employee
    );

    setCompany(prevCompany => ({ ...prevCompany, employees: updatedEmployees })); 

    if (user.user_id === updatedEmployee.user_id) {
        setUser(prevUser => ({...prevUser, ...updatedEmployee}));
    }
}

  const updateLocation = (updatedSingle) => {
    const updateLocation = company.locations.map((item) => {
      if (item.location_id === updatedSingle.location_id) {
        const updatedItem = {
          ...item,
          location_name: updatedSingle.location_name,
          branch_number: updatedSingle.branch_number,
          contact_name: updatedSingle.contact_name,
          phone_number: updatedSingle.phone_number,
          street_address: updatedSingle.street_address,
          city: updatedSingle.city,
          state: updatedSingle.state,
          zipcode: updatedSingle.zipcode,
        };
        return updatedItem;
      }
      return item;
    });
    setCompany({ ...company, locations: updateLocation });
  }

  const updatedLocationsList = (updatedList) => {
    setCompany({ ...company, locations: updatedList })

  }

  const updateLocationInfo = (updatedList) => {
    const locationInfo = { location_url: updatedList.params.location_id, ...updatedList }
    const mapLoc = {
      "location_url": locationInfo.location_url,
      "hours": locationInfo.hours,
      "dock_height": locationInfo.dockHeight,
    }

    setCompany((prevState) => {
      const mappedData = company.locations.map(g => (g.location_id == updatedList.params.location_id ? Object.assign({}, g, { location_info: [mapLoc] }) : g))
      return ({ ...prevState, locations: mappedData })
    })
  }


  const updatePendingList = (updatedList) => {

    setCompany({ ...company, contracts: updatedList })
  }
  const deletePending = (G) => {
    //This is the only way I could get deleting pending carrier to work.

    setCompany((prevState) => ({ ...prevState, contracts: G }));
  }
  const pendingWebhook = (data) => {

    setCompany((prevState) => {
      const mappedData = prevState.contracts.map(g => (g.contract_id === data.Metadata.contract_id ? Object.assign({}, g, { postmark_status: data.RecordType }) : g))
      return ({ ...prevState, contracts: mappedData })

    })
  }

  //Testing for every item to be updated from one place depending on their type which needs to be included on callback
  //So this works, but might need to do it for only when things are submitted into database
  const updateSingle = (updatedSingle) => {
    const selectMap = {
      "customers": company.customers,
      "locations": company.locations,
      "carriers": company.carriers,
      "loads": company.loads
    }
    const data = selectMap[updatedSingle.type]
    const detailKey = Object.keys(updatedSingle)[0]
    const detailValue = Object.values(updatedSingle)[0]

    const updateItem = data.map((item) => {
      if (item.id === updatedSingle.id) {
        const updatedItem = {
          ...item,
          [detailKey]: detailValue
        };
        return updatedItem;
      }
      return item;
    });

    setCompany({ ...company, [updatedSingle.type]: updateItem });

  }

  const handleOnBoard = () => {
    if (company && company.carriers) {
      if (company.company_tc === "" ||
        company.company_contract === "" ||
        company.customers.length === 0 ||
        company.carriers.length === 0 ||
        company.locations.length < 2 ||
        company.stripe.stripe_subscription_id === null) {
        setOnBoard(true)
      }
      else {
        setOnBoard(false)
      }
    }

  }
  useEffect(() => {
    handleOnBoard()
  }, [company])

  const handleSubStatus = () => {
    if(company && company.stripe) {
      if(company.stripe[0].status === "Inactive" ||
        company.stripe[0].status === "Paused" ||
        company.stripe[0].status === "Canceled"
      ) {
        setActiveSub(false)
        
      }
      else {
        setActiveSub(true)
      }
    }
}

  useEffect(() => {
    handleSubStatus()
  }, [company])

  

  useEffect(() => {
    //Might not need this at all, sense private route always runs the fetch.
    // Turns out this is better than querying everytime
    if (logged) {
      authFetch("https://fltbd.herokuapp.com/user/").then(response => {
        if (response.status === 500) {
          logout()
        }
        return response.json()
      })
        .then(response => {
          if (response) {
            setUser(response)
            if (response && response.first_name) {
              setInitials(response.first_name.charAt(0) + '' + response.last_name.charAt(0))
            }
            authFetch(`https://fltbd.herokuapp.com/api/companys/${response.company_id}`)
              .then(data => {
                return data.json()
              })
              .then(data => {
                if (data) {
                  setCompany(data)


                }
              })
          }
          else (
            logout()
          )
        })

    }
    else {
      return
    }

  }, [])




  return (
    <>

      <div id="wrapper">

        <Header activeSub={activeSub} {...company} {...user} initials={initials} />
        <div id="content-wrapper" class="d-flex flex-column">


          <Switch>

          

            <PrivateRoute exact path="/dashboard">
              {logged ?
                <DashboardContainer onBoard={onBoard} activeSub={activeSub}  {...company} {...user} title="FreightBroker.ai -  Dashboard" />
                : <Redirect to='/login' />}
            </PrivateRoute>
           

            <PrivateRoute exact path="/customers">
              {logged ?
                <CustomersContainer  activeSub={activeSub} {...company} {...user} updateCustomersList={updateCustomersList} title="FreightBroker.ai -  Customers" />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/customers/add" component={CustomersAdd} />


            <PrivateRoute path="/customer/:customer_id">
              {logged ?
                <CustomersItem initials={initials} {...company} {...user} updateCustomersList={updateCustomersList} updateCustomer={updateCustomer} updateSingle={updateSingle} />
                : <Redirect to='/login' />}
            </PrivateRoute>


            <PrivateRoute exact path="/carriers">
              {logged ?
                <CarriersContainer
                  activeSub={activeSub}
                  deletePending={deletePending}
                  updatePendingList={updatePendingList}
                  pendingWebhook={pendingWebhook}
                  {...company} {...user}
                  title="FreightBroker.ai -  Carriers"
                />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/carriers/add">
              <CarriersAdd {...company} updateCarriersList={updateCarriersList} title="FreightBroker.ai -  Add Carrier" />
            </PrivateRoute>

            <PrivateRoute path="/carrier/:carrier_id">
              {logged ?
                <CarriersItem {...company} {...user} updateCarriersList={updateCarriersList} title="FreightBroker.ai -  Carrier Profile" />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/locations">
              {logged ?
                <LocationsContainer {...company} {...user}
                  updatedLocationsList={updatedLocationsList}
                  title="FreightBroker.ai -  Locations"
                />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/locations/add" component={LocationsAdd} />
            <PrivateRoute path="/location/:location_id">
              {logged ?
                <LocationsItem {...company} {...user} updatedLocationsList={updatedLocationsList} updateLocationInfo={updateLocationInfo} updateLocation={updateLocation} updateSingle={updateSingle} title="FreightBroker.ai -  Edit Location" />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute exact path="/loads">
              {logged ?
                <LoadsContainer onBoard={onBoard} activeSub={activeSub} {...company} {...user} updateLoadList={updateLoadList} title="FreightBroker.ai -  Loads" />
                : <Redirect to='/login' />}
            </PrivateRoute>


            <PrivateRoute exact path="/loads/add" component={LoadsItem} />



            <PrivateRoute path="/loads/:load_id">
              {logged ?
                <LoadsItem {...company} {...user} updateLoadList={updateLoadList} db={db} title="FreightBroker.ai -  Build/Edit Load" />
                : <Redirect to='/login' />}
            </PrivateRoute>

            <PrivateRoute path="/load_documents/:load_id">
              {logged ?
                <LoadsDocuments {...company} {...user} />
                : <Redirect to='/login' />}

            </PrivateRoute>

            <PrivateRoute exact path="/reports">
              <ReportsContainer {...user} {...company} />
            </PrivateRoute>


            <PrivateRoute exact path="/profile" component={ProfileContainer} />


            <PrivateRoute path="/settings" >
              {logged ?
                <SettingsContainer {...user} {...company}
                  updateCompany={updateCompany}
                  activeSub={activeSub}
                  updateEmployeeList={updateEmployeeList}
                  updateEmployee={updateEmployee}
                  parentCompanyTC={handleCompanyTC}
                  parentCompanyContract={handleCompanyContract} title="FreightBroker.ai -  Settings" />
                : <Redirect to='/login' />
              }
            </PrivateRoute>

            <PrivateRoute path="/accounting" >
              {logged ?
                <AccountingContainer {...user} {...company}
                  createInvoice={createInvoice}
                  updateLoadList={updateLoadList}
                  updateInvoiceList={updateInvoiceList} />
                : <Redirect to='/login' />
              }
            </PrivateRoute>


            <PrivateRoute exact path="/equipment" >
              {logged ?
                <EquipmentContainer {...user} {...company}
                  updateTruckList={updateTruckList}
                  updateTrailerList={updateTrailerList}
                  title="FreightBroker.ai -  Equipment"
                />
                : <Redirect to='/login' />
              }
            </PrivateRoute>
            <PrivateRoute path="/equipment/truck/:equipment_id" component={EquipmentTruckItem} />
            <PrivateRoute path="/equipment/trailer/:equipment_id" component={EquipmentTrailerItem} />

            <PrivateRoute exact path="/drivers">
              {logged ?
                <DriversContainer {...user} {...company} title="FreightBroker.ai -  Drivers" />
                : <Redirect to='/login' />
              }
            </PrivateRoute>
            <PrivateRoute path="/driver/:driver_id">
              {logged ?
                <DriversItem {...user} {...company} />
                : <Redirect to='/login' />
              }
            </PrivateRoute>

            <Route path="/documents/rateconfirmation/:load_id">
              <RateConfirmationContainer />
            </Route>


            <Route exact path="/rateconfirmation/demo">
              <RateConfirmationDemo />
            </Route>

            <Route exact path="/verify/:rate_id" 
            render={(props) => <VerifyRate {...props} />} />
             

            {/* HOME PAGE/LANDING ROUTE */}
            <LogInRoute exact path="/" component={HomeContainer} />

            {/* LOGIN ROUTE */}
            <Route exact path="/login">
              {!logged ?
                <Login title="FreightBroker.ai -  Login" />
                : <Redirect to='/dashboard' />}
            </Route>
              
            {/* CONFIRM EMAIL ROUTE */}
            <Route path="/confirm-email/:confirmation_id">
              {!logged ?
                <ConfirmEmail />
                : <Redirect to='/dashboard' />}
            </Route>

          {/* SIGN UP ROUTE */}
            <Route exact path="/signup">
              {!logged ?
                <SignUp title="FreightBroker.ai -  Sign Up" />
                : <Redirect to='/dashboard' />}
            </Route>


            {/* FORGOT PASSWORD ROUTE */}
            <Route exact path="/forgot-password">
              {!logged ?
                <ForgotPassword title="FreightBroker.ai -  Forgot Password" />
                : <Redirect to='/dashboard' />}
            </Route>

            {/* RESET PASSWORD ROUTE */}
            <Route exact path="/reset-password/:forgot_id">
              {!logged ?
                <ResetPassword />
                : <Redirect to='/dashboard' />}
            </Route>


            {/* DOUBLE BROKER INFO - MIGHT NOT KEEP */}
            <Route exact path="/double-broker-list">
              <Redirect to='/resources/double-broker-list' />
            </Route>

            <Route exact path="/resources/double-broker-list">
            {/* CHANGE/REMOVE EQUALS TO SHOW DOUBLE BROKER PAGE */}
            {3 === 2 ?
              <DoubleBrokerContainer {...user} title="FreightBroker.ai -  Double Broker List" />
                :
              <NotFound />
            }
            </Route>


            {/* CARRIER RECEIVES THIS ROUTE WHEN SENT CONTRACT */}
            <Route path="/initiate_contract/:contract_id"
              render={(props) => <InitiateContract {...props} />} />


            {/* CARRIER GETS SENT TO THIS ROUTE AFTER COMPLETING INITIATE ROUTE */}
            <Route path="/contract/:contract_id"
              render={(props) => <Contract {...props} />} />
            <Route exact path="/already_completed_contract" component={ContractRedirect} />




            {/* LEGAL STUFF */}
            <Route path="/privacy-policy"
              render={() => <LegalContainer title="FreightBroker.ai -  Privacy Policy" page="Privacy Policy" />} />

            <Route path="/terms-conditions"
              render={() => <LegalContainer title="FreightBroker.ai -  Terms & Conditions" page="Terms & Conditions" />} />


            <Route>
              <NotFound />
            </Route>
          </Switch>

        </div>

      </div>
      <Footer />
    </>


  )

}

