import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip,
    Modal,
    Button
} from "react-bootstrap";





const SubStatusModal = (props) => {

    const initialState = () => {
        return (props)
    }
    const history = useHistory();

    const [state, setState] = useState(initialState);
    const [showModal, setShow] = useState(true);
    const [checkList, setChecklist] = useState([])



    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setState(props);
    }




    return (
        <>


            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="sm"
                centered


            >


                <>
                    <div class="modal-status bg-danger"></div>
                    <Modal.Body class="modal-body text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9v2m0 4v.01"></path><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path></svg>
                        <h3>Subscription Error</h3>
                        <div class="text-muted">There is an issue with your subscription. Visit the subscription page to find more info.</div>
                    </Modal.Body>
                    <div class="modal-footer">
                        <div class="w-100">
                            <div class="row">
                                <div class="col">
                                    <button onClick={handleClose} class="btn w-100" data-bs-dismiss="modal">
                                        Cancel
                                    </button>
                                </div>

                                <div class="col">
                                    <Link to="/settings/subscription">
                                        <button class="btn btn-primary w-100" data-bs-dismiss="modal">
                                            Subscription Page
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

            </Modal>


        </>
    )
}

export default SubStatusModal