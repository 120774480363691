/* eslint-disable */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
  useSortBy
} from "react-table";
import { matchSorter } from "match-sorter";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter,
  useHistory
} from "react-router-dom"
import Select from 'react-select'
import { useAuth, authFetch, logout } from "../../auth";
import { Dropdown, Form, ButtonGroup } from "react-bootstrap";
import { set } from "date-fns";
import LoadsDelete from "./Modals/LoadsDelete";
import LoadsDeleteList from "./Modals/LoadsDeleteList";
import { v4 as uuidv4 } from "uuid";
import roundToNearestMinutesWithOptions from "date-fns/fp/roundToNearestMinutesWithOptions";


const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 0px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  input {
      width: 50%;
      
  }
  btn {
    padding: .5rem .5rem;
  }

`
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])


    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search By Carrier Name, MC Number, or DOT Number"
        style={{
          fontSize: "1.1rem",
          border: "0"
        }}
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}



function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ statusOptions, parentDeleteList, parentPatchList, columns, data, parentHideCompleted }) {

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );


  const [patchStatus, setPatchStatus] = useState('')
  const deleteSelected = () => {

    const selectedList = selectedFlatRows.map(d => d.original.load_id)
    authFetch(`https://fltbd.herokuapp.com/api/loads/multi-delete`, {
      method: 'POST',
      body: JSON.stringify({
        load_list: selectedList
      }),
      headers: { 'Content-Type': 'application/json' },
    })
    parentDeleteList(selectedList)

  }

  const patchSelected = (e) => {
    const selectedList = selectedFlatRows.map(d => d.original.load_id)
    authFetch(`https://fltbd.herokuapp.com/api/loads/multi-patch`, {
      method: 'PATCH',
      body: JSON.stringify({
        load_list: selectedList,
        status: e.value
      }),
      headers: { 'Content-Type': 'application/json' },
    })
    parentPatchList(selectedList, e)

  }
  const history = useHistory();
  const doubleClickRow = (e) => {
    const values = e.values.load_id
    history.push({
      pathname: `/loads/${values}`,
      //remove window.location.href if you want to load from state
      //pathname: window.location.href = `/loads/${values}` if you want to reload everytime it's dbl clicked
    })
  }
  const [hideCompletedLoads, setHideCompletedLoads] = useState(false)
  const hideCompleted = () => {
    const allLoads = [...data];
    const hideLoads = allLoads.filter(obj => (obj.status !== "Completed"));
    setHideCompletedLoads(!hideCompletedLoads)
    parentHideCompleted(hideLoads, hideCompletedLoads)

  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        hiddenColumns: ['Customer', 'Reference_Number'],
        selectedRowIds: [],
        pageIndex: 0,
        sortBy: [
          { id: 'loadnumber', desc: true }
        ]
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }

  );


  // We don't want to render all of the rows for this example, so cap
  // it for this use case

  return (
    <>
      <div className="card">
      <div className="table-responsive" style={{overflow: "visible"}}>
        <table className="table-responsive card-table table-vcenter" style={{width: "100%", borderSpacing: "0"}} {...getTableProps()}>
          <thead>


            <tr>
              <th
                className="reactTable-th"
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left"
                }}
              >

                <span>Search: </span>
                <input
                  type="text"
                  value={globalFilter || ""}
                  placeholder="Search By Carrier, Load #, Locations, etc"
                  onChange={e => setGlobalFilter(e.target.value)}
                  style={{
                    fontSize: "1.1rem",
                    border: "0",
                    width: "100%"
                  }}
                />
              </th>
            </tr>
            {headerGroups.map((headerGroup) => (
              <tr style={{ backgroundColor: "#f6e2b3"}} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="reactTable-th" {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>

            {page.map((row, i) => {
              prepareRow(row);

              return (
                <>
                  {row.isSelected == true ?
                    <tr style={{ cursor: "pointer", textShadow: "0px 0px 1px black" }}  {...row.getRowProps()} onDoubleClick={() => doubleClickRow(row)} >

                      {row.cells.map((cell) => {
                        return (
                          <td className="reactTable-td" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        );
                      })}
                    </tr>
                    :
                    <tr style={{ cursor: "pointer" }}  {...row.getRowProps()} onDoubleClick={() => doubleClickRow(row)}>

                      {row.cells.map((cell) => {
                        return (
                          <td className="reactTable-td" {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        );
                      })}
                    </tr>
                  }
                </>
              );

            })}
            <div style={{ paddingTop: "Can be changed to a value, will allow last row status to show" }}>
              {''}
            </div>
          </tbody>
        </table>
        </div>
        <div class="row m-3">
          <div class="col">
            <div className="pagination">
              <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'< Previous'}
              </button>
              <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={() => nextPage()} disabled={!canNextPage}>
                {'Next >'}
              </button>
            </div>
          </div>
          <div class="col d-none d-sm-block">
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
          </div>
          <div class="col d-none d-sm-block">
            <select
              class="form-select"

              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>


        </div>
        <br />
        <div class="row m-3">
          <div class="col">
            <LoadsDeleteList
              selectedFlatRows={selectedFlatRows}
              parentDeleteSelected={deleteSelected}
            />
          </div>
          <div class="col">
           
          </div>
          <div class="col-sm-4 col-12">
            <Select placeholder="Status of Selected" options={statusOptions} onChange={patchSelected} />
          </div>
        </div>

      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";


const Actions = ({ actions }) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <>
      {actions.map((action, idx) => {
        return (
          <span key={idx} className="badge">
            {action}
          </span>
        );
      })}
    </>
  );
};

function LoadsTable(props) {
  const [data, setData] = useState([]);
  const [selectedLoad, setSelectedLoad] = useState()

  const handleDeleteList = (removeLoads) => {
    const toDelete = new Set(removeLoads);
    const arrayOfObjects = [...data];
    const newArray = arrayOfObjects.filter(obj => !toDelete.has(obj.load_id));
    setData(newArray)
    props.deleteLoads(newArray)

    
  }

  const handlePatchList = (patchLoads, e) => {
    const toPatch = new Set(patchLoads);
    const dataCopy = [...data]
    const mappedData = dataCopy.map(g => (toPatch.has(g.load_id) ? Object.assign({}, g, { status: e.value }) : g))
    setData(mappedData)
    props.patchUpdate(patchLoads, e)
  }

  const handleDeleteSingle = (row) => {
    const dataCopy = [...data];
    // It should not matter what you name tableProps. It made the most sense to me.
    dataCopy.splice(row.row.index, 1);
    setData(dataCopy);
    authFetch(`https://fltbd.herokuapp.com/api/loads/${row.value}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    })
    props.deleteLoads(dataCopy)
  }


  const changeStatus = (row, e) => {
    const dataCopy = [...data]
    const mappedData = dataCopy.map(g => (g.load_id === row.row.values.load_id ? Object.assign({}, g, { status: e.value }) : g))
    //setData(mappedData)
    authFetch(`https://fltbd.herokuapp.com/api/loads/${row.row.values.load_id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        status: e.value
      }),
      headers: { 'Content-Type': 'application/json' },
    })
    props.updateSingle(row, e)
  }
  const hideCompleted = (e, hide) => {
    console.log(!hide)
    const dataCopy = [...data]
    console.log(dataCopy)
    if(hide) {
      setData(props.loads)
    }
    if(!hide) {
      console.log(e)
      setData(e)
    }
  }

  const statusOptions = [
    { value: 'Not Assigned', label: 'Not Assigned' },
    { value: 'Dispatched', label: 'Dispatched' },
    { value: 'In Transit', label: 'In Transit' },
    { value: 'Completed', label: 'Completed' }
  ]


  const columns = React.useMemo(
    () => [
      
      {
        Header: "Load #",
        id: "loadnumber",
        accessor: "load_number"
      },
      {
        Header: "Carrier",
        accessor: "carrier_name",
        // Use our custom `fuzzyText` filter on this column
        filter: "fuzzyText"
      },

      {
        Header: "Trailer",
        id: "trailer",
        accessor: "trailer"
      },
      {
        Header: "Customer",
        id: 'Customer',
        accessor: "customer_name"
      },
      {
        Header: "Reference Number",
        id: 'Reference_Number',
        accessor: "ref_number"
      },

      {
        Header: "Shippers",
        id: "shippers",
        accessor: row => {
          if (row && row.actions) {
            let sortActions = row.actions.sort((a, b) => a.order - b.order)
            return sortActions.map((a) => {
              if (a.action == 's') {
                if (a.city == null || a.state == null) {
                  return
                }
                const lineBreak = "\n"
                const cityState = a.city + " " + a.state + " " + lineBreak
                return (cityState)

              }
              else {
                return
              }
            })
          }
        },
        Cell: (row) => (

          <div style={{ whiteSpace: "pre-line" }}>
            {row.cell.value === null ? null : row.cell.value}
          </div>
        )
      },
      {
        Header: "Receivers",
        id: "receivers",
        accessor: row => {

          if (row && row.actions) {
            let sortActions = row.actions.sort((a, b) => a.order - b.order)
            return sortActions.map((a) => {
              if (a.action == 'r') {
                if (a.city == null || a.state == null) {
                  return
                }
                const lineBreak = "\n"
                const cityState = a.city + " " + a.state + " " + lineBreak
                return (cityState)

              }
              else {
                return
              }
            })
          }
          return
        },
        Cell: (row) => (

          <div style={{ whiteSpace: "pre-line" }}>
            {row.cell.value === null ? null : row.cell.value}
          </div>
        )

      },


      {
        Header: "Gross Pay",
        disableSortBy: true,
        accessor: "gross_pay",
        Cell: (row) => (

          <div>
            {row.cell.value === null || row.cell.value === undefined ? null : '$' + row.cell.value}
          </div>
        )
      },
      {
        Header: "Carrier Pay",
        disableSortBy: true,
        accessor: "carrier_pay",
        Cell: (row) => (

          <div>
            {row.cell.value === null || row.cell.value === undefined ? null : '$' + row.cell.value}
          </div>
        )
      },

      {
        // back up cell data in Notion
        Header: "Status",
        disableSortBy: true,
        accessor: "status",
        Cell: (row, e) => (
          <div style={{ fontSize: "small", paddingRight: "85px", width: "150%" }} >
            <Select placeholder={row.value} options={statusOptions} onChange={row, e => { changeStatus(row, e); }} />
          </div>
        )
      },


      {
        Header: "",
        id: "load_id",
        disableSortBy: true,
        accessor: "load_id",

        Cell: (row) => (
          <>
            <Dropdown align="end" drop="center">
      <Dropdown.Toggle variant="secondary">
        Actions
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {row.row.original.accepted === null ?
        null:      
        <Dropdown.Item className="dropdown-view" as={Link} to={`/documents/rateconfirmation/${row.cell.value}`}>        
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>&nbsp;View Rate Confirmation     
          </Dropdown.Item>    
        }
        <Dropdown.Item className="dropdown-edit" as={Link} to={`loads/${row.cell.value}`}>        
        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="white" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" /><path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" /><line x1="16" y1="5" x2="19" y2="8" /></svg>
        &nbsp;Edit Load
        </Dropdown.Item> 
        
        {row.row.original.accepted === null ?
              null :
              <Dropdown.Item className="dropdown-documents" as={Link} to={`load_documents/${row.cell.value}`}>        
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" /><line x1="8" y1="8" x2="12" y2="8" /><line x1="8" y1="12" x2="12" y2="12" /><line x1="8" y1="16" x2="12" y2="16" /></svg>
                &nbsp;Load Documents  
              </Dropdown.Item>    
        }
        <Dropdown.Item className="dropdown-copy" onClick={() => copyLoad({...row.row.original})}>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="8" y="8" width="12" height="12" rx="2" /><path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
        &nbsp;Copy Load
          </Dropdown.Item>

        <Dropdown.Item className="dropdown-delete" onClick={() => handleDeleteSingle(row)}>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" /><line x1="8" y1="8" x2="12" y2="8" /><line x1="8" y1="12" x2="12" y2="12" /><line x1="8" y1="16" x2="12" y2="16" /></svg>
        &nbsp;Delete Load
        </Dropdown.Item>

        


      
      </Dropdown.Menu>
    </Dropdown>

    
          </>
        )
      },


    ],
    [data]
  );

  const copyLoad = (row) => {
    const uniqID = uuidv4()
    const copiedLoad = row
    copiedLoad.load_id = uniqID
    
    const updatedArray = row.actions.map(item => {
      return {
        ...item,
        action_id: uuidv4()
      }
    })
    authFetch(`https://fltbd.herokuapp.com/api/loads/copy`, {
            method: 'PUT',
            body:  JSON.stringify({
              load_id: uniqID,
              status: 'Not Assigned',
              carrier_name: row.carrier_name,
              carrier_dot: row.carrier_dot,
              carrier_mc: row.carrier_mc,
              carrier_match: row.carrier_match,
              trailer: row.trailer,
              gross_pay: row.gross_pay,
              line_haul: row.line_haul,
              carrier_pay: row.carrier_pay,
              accessorial_fees: [row.accessorial_fees],
              customer_name: row.customer_name,
              equipment: [row.equipment],
              actions: [updatedArray],
              

          }),
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(g => {
              
              if(g.success === 'True') {
                console.log(g)
                copiedLoad.load_number = g.load_number
                copiedLoad.actions = updatedArray
                copiedLoad.status = 'Not Assigned'
                props.copyLoad(copiedLoad)
                
              }
              if(g.success === 'False') {
                console.log(g)
              }
              
            })
            

            

            
    setData([...data, copiedLoad]);
  }
  //const data = React.useMemo(() => makeData(500), []);
  const history = useHistory();

  useEffect(() => {
    if (props && props.data) {
      setData(props.data)
    }
  }, [props.data])

  return (


      <Table
        columns={columns}
        data={data}
        parentDeleteList={handleDeleteList}
        parentPatchList={handlePatchList}
        statusOptions={statusOptions}
        parentHideCompleted={hideCompleted}
      />

  );
}

export default LoadsTable;