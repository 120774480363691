import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from 'axios'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { v4 as uuidv4 } from "uuid";
import { authFetch } from '../../auth';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


const LoadsReceivers = props => {
    const history = useHistory();

    const [state, setState] = useState({ receivers: [] });
    const [locations, setLocations] = useState();
    const [focus, setFocus] = useState(false)


    const getOrder = () => {
        if (props && props.actions) {
            const actionsInfo = props.actions.filter(action => action.action == "r")
            if (actionsInfo === null || actionsInfo.length === 0) {
                return 1
            }
            else {
                let sortActions = actionsInfo.sort((a, b) => a.order - b.order)
                const getLast = sortActions.slice(-1)
                const orderNumber = parseInt(getLast[0].order) + 1
                return orderNumber
            }
        }
        else {
            return
        }
    }

    const handleAddReceiver = () => {
        const getUniqueID = uuidv4()
        const newDate = moment(new Date()).toDate()

        const newReceiver =
        {
            location_name: '',
            action: 'r',
            load_match: props.loadID,
            action_id: getUniqueID,
            date: newDate,
            order: getOrder()
        }
        setState({
            receivers: [...state.receivers, newReceiver]
        })
        authFetch(`https://fltbd.herokuapp.com/actions`, {
            method: 'PUT',
            body: JSON.stringify({
                location_name: '',
                action: 'r',
                load_match: props.loadID,
                action_id: getUniqueID,
                date: moment(newDate).format("L"),
                order: getOrder()
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentAddAction(newReceiver)

    }

    const handleRemoveReceiver = (idx) => () => {
        state.receivers.map((receiver, sidx) => {
            if (idx !== sidx)
                return receiver;
            authFetch(`https://fltbd.herokuapp.com/actions/${receiver.action_id}`, {
                method: 'DELETE',

            })
            props.parentRemoveAction(receiver)
        })
        const removeReceiver = state.receivers.filter((s, sidx) => idx !== sidx)
        setState({ receivers: removeReceiver })
        //props.parentReceivers(state.receivers.filter((s, sidx) => idx !== sidx));
    }

    const handleReceiverChange = (idx) => (e) => {
        const receieverChange = state.receivers.map((receiver, sidx) => {
            if (idx !== sidx)
                return receiver;
            authFetch(`https://fltbd.herokuapp.com/actions/${receiver.action_id}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    location_name: e.location_name,
                    load_match: props.load_id,
                    street_address: e.street_address,
                    city: e.city,
                    state: e.state,
                    contact_name: e.contact_name,
                    zipcode: e.zipcode,
                    phone_number: e.phone_number,
                    action: 'r',
                    action_id: receiver.action_id,
                    order: idx + 1
                })
            })
            props.parentActionChange(e, receiver.action_id)
            return {
                ...receiver,
                location_name: e.location_name,
                street_address: e.street_address,
                city: e.city,
                state: e.state,
                zipcode: e.zipcode,
                contact_name: e.contact_name,
                phone_number: e.phone_number,
            };
        })
        //setState({receivers: receieverChange})
        //props.parentReceivers(receieverChange)

    }


    useEffect(() => {
        if (props && props.actions) {
            const actionsInfo = props.actions.filter(action => action.action == "r")

            const options = actionsInfo.map(d => ({

                "location_name": d.location_name,
                "load_match": props.load_id,
                "street_address": d.street_address,
                "city": d.city,
                "state": d.state,
                "contact_name": d.contact_name,
                "zipcode": d.zipcode,
                "phone_number": d.phone_number,
                "action_id": d.action_id,
                "pickup_number": d.pickup_number,
                "extra_notes": d.extra_notes,
                "order": d.order,
                "date": d.date

            }))
            let sortActions = options.sort((a, b) => a.order - b.order)
            setState({ ...state, receivers: sortActions })
            //props.parentReceivers(options)
        }
    }, [props.actions])

    useEffect(() => {
        if (props && props.locations) {
            const options = props.locations.map(d => ({
                "location_name": d.location_name,
                "value": d.location_id,
                "label": d.location_name,
                "street_address": d.street_address,
                "city": d.city,
                "state": d.state,
                "zipcode": d.zipcode,
                "contact_name": d.contact_name,
                "phone_number": d.phone_number,
            }))
            setLocations(options)

        }
    }, [props], [props.locations])
    const handleChange = (idx) => (e) => {
        const updateReceiver = state.receivers.map((receiver, sidx) => {
            if (idx !== sidx)
                return receiver;
            
            props.parentActions(e, receiver.action_id)
            return { ...receiver, [e.target.name]: e.target.value }
        });
        setState({ ...state, receivers: updateReceiver })
        //props.parentReceivers({...state, receivers: updateReceiver})
    }

    const handleDateChange = (idx) => (date) => {
        const newDate = state.receivers.map((receiver, sidx) => {
            if (idx !== sidx)
                return receiver;
            authFetch(`https://fltbd.herokuapp.com/actions/${receiver.action_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    date: moment(date).format("L")
                }),
                headers: { 'Content-Type': 'application/json' },
            })
            const e = {
                target: {
                    name: "date",
                    value: moment(date).format("L")
                }
            }
            props.parentActions(e, receiver.action_id)
            return { ...receiver, date: date };
        });
        setState({ ...state, receivers: newDate })
        //props.parentReceivers({...state, receivers: newDate})        

    }
    const handleFocus = (idx) => (e) => {
        setFocus(false)
        const updateReceiver = state.receivers.map((receiver, sidx) => {
            if (idx !== sidx)
                return receiver;
            authFetch(`https://fltbd.herokuapp.com/actions/${receiver.action_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    [e.target.name]: e.target.value
                }),
                headers: { 'Content-Type': 'application/json' },
            })

            return { ...receiver, [e.target.name]: e.target.value }
        });

    }

    return (
        <>
            <div>
                <div class="card">
                    <div class="card-body">
                        <form>
                            <button type="button" onClick={handleAddReceiver} className="small" class="btn btn-red float-right">Add Drop Off</button>
                        </form>
                        <h3 class="card-title">Receivers </h3>
                    </div>
                </div>
                {state && state.receivers?.map((receiver, idx) => (
                    <div class="card">
                        <div class="card-body">
                            <div key={receiver.action_id} className="receiver alert alert-primary">
                                {receiver.location_name === ""
                                    ?
                                    <label><b>{`Drop #${idx + 1}`}</b> - Select Location Below</label>
                                    :
                                    <label><b>{`Drop #${idx + 1}`}</b></label>
                                }
                                <Select options={locations} placeholder={receiver.location_name} onChange={handleReceiverChange(idx)} />
                                <br />
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroup-sizing-sm">Street Address</span>
                                    </div>
                                    <input
                                        onBlur={handleFocus(idx)}
                                        onFocus={() => setFocus(true)}
                                        type="text"
                                        value={receiver.street_address}
                                        name="street_address"
                                        class="form-control"
                                        onChange={handleChange(idx)} />
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">City</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={receiver.city}
                                                name="city"
                                                class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">State</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={receiver.state}
                                                name="state"
                                                class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div class="input-group input-group-sm mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-sm">Zipcode</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                name="zipcode"
                                                value={receiver.zipcode}
                                                class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-default">Contact</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                name="contact_name"
                                                value={receiver.contact_name}
                                                class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-default">Phone Number</span>
                                            </div>
                                            <input
                                                onBlur={handleFocus(idx)}
                                                onFocus={() => setFocus(true)}
                                                type="text"
                                                value={receiver.phone_number}
                                                name="phone_number"
                                                class="form-control"
                                                onChange={handleChange(idx)} />
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">Extra Notes </span>
                                        </div>
                                        <textarea
                                            onBlur={handleFocus(idx)}
                                            onFocus={() => setFocus(true)}
                                            class="form-control"
                                            value={receiver.extra_notes}
                                            name="extra_notes"
                                            onChange={handleChange(idx)} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">

                                        <DatePicker
                                            selected={moment(receiver.date).toDate()}
                                            name="date"
                                            className="form-control"
                                            onChange={handleDateChange(idx)} />
                                    </div>
                                    <div className="col-4">
                                    </div>
                                    <div className="col-2">
                                        <input type="text" className="form-control" name="order" value={receiver.order} onChange={handleChange(idx)} />
                                    </div>

                                </div>

                                <br />

                                <button
                                    type="button"
                                    onClick={handleRemoveReceiver(idx)}
                                    className="small"
                                    class="btn btn-ghost-danger float-right">
                                    Remove
                                </button>

                            </div>
                        </div>
                    </div>

                ))}


            </div >

        </>
    )


}

export default LoadsReceivers