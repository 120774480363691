import React, { useEffect, useState } from "react";
import {
    Route, Switch, Redirect, Router, useHistory, Link
} from 'react-router-dom';

import { authFetch } from "../../auth";


export default function EquipmentHeader() {

    return (
        <div class="d-sm-flex align-items-center justify-content-between">

                <h1>Equipment</h1>
            
           
        </div>

    )
}