import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';


export default function LocationsItemLocationDetails(props) {
    const [state, setState] = useState('')
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }
    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        props.parentLocationDetails({ [e.target.name]: e.target.value })

    }
    useEffect(() => {
        setState(props)
    })
    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/locations/${props.location_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                location_name: state.location_name,
                branch_number: state.branch_number,
                contact_name: state.contact_name,
                phone_number: state.phone_number,
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit);
        props.updateLocation(state)
    }
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Location Details</h3>
                <div className="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div class="form-row">
                        <div class="col mb-3">
                            <label className="form-label">Location Name</label>
                            <input type="text" className="form-control" value={state.location_name} name="location_name" onChange={handleChange} required readOnly={edit} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label">Contact Name</label>
                            <input type="text" className="form-control" value={state.contact_name} name="contact_name" onChange={handleChange} readOnly={edit} />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="form-label">Phone Number</label>
                            <input type="tel" className="form-control" value={state.phone_number} name="phone_number" onChange={handleChange} readOnly={edit} />

                        </div>
                    </div>
                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>
        </div>

    )
}