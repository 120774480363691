
import React, { Component } from "react";
import CustomersHeader from "./CustomersHeader"
import { v4 as uuidv4 } from "uuid";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
} from "react-router-dom";
import { authFetch } from "../../auth";

class CustomersAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customer_id: uuidv4(),
            customer_name: "",
            contact_name: "",
            phone_number: "",
            email: "",
            billing_address: "",
            billing_city: "",
            billing_state: "",
            billing_zipcode: "",
        };
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }


    handleSubmit = e => {
        const getToken = localStorage.getItem('REACT_TOKEN_AUTH_KEY')
        const strippedToken = getToken.replace('access_token', '')
        const strippedToken2 = strippedToken.replace('""', '')
        const strippedToken3 = strippedToken2.replace('{:"', '')
        const strippedToken4 = strippedToken3.replace('"}', '')
        e.preventDefault();
        authFetch('https://fltbd.herokuapp.com/api/customers', {
            method: 'PUT',
            body: JSON.stringify({
                customer_id: this.state.customer_id,
                customer_name: this.state.customer_name,
                contact_name: this.state.contact_name,
                phone_number: this.state.phone_number,
                email: this.state.email,
                billing_address: this.state.billing_address,
                billing_city: this.state.billing_city,
                billing_state: this.state.billing_state,
                billing_zipcode: this.state.billing_zipcode,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + strippedToken4
            },
        })
            .then(res => res.json())
            .then(data => this.setState({ customer_name: data.customer_name }))
        this.props.history.push('/customers')


    };
    
    render() {
        const { customer_name } = this.state

        return (
            <div>
                <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div class="d-sm-flex align-items-center justify-content-between">
                        <div>

                            <h1>Add New Customer</h1>
                        </div>

                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div class="card">
                        <div class="card-header">
                        <h3 class="card-title">Customer Details</h3>
                        </div>
                            <div class="card-body">
                                <div class="form-group">
                                <label for="inputCustomerName">Customer Name</label>
                                <input type="text" class="form-control" placeholder="Enter Customer Name" value={this.state.customer_name} name="customer_name" onChange={this.onChange} />
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label>Contact Name</label>
                                        <input type="text" class="form-control" placeholder="Contact Name" value={this.state.contact_name} name="contact_name" onChange={this.onChange} required />
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="validationTooltip02">Email</label>
                                        <input type="text" class="form-control" id="validationTooltip02" placeholder="Enter Email" value={this.state.email} name="email" onChange={this.onChange} />

                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="validationTooltipUsername">Phone Number</label>
                                        <div class="input-group">

                                            <input type="text"
                                                class="form-control"
                                                id="validationTooltipUsername"
                                                placeholder="Enter Phone Number"
                                                value={this.state.phone_number}
                                                name="phone_number"
                                                onChange={this.onChange}
                                                required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                        <div class="card-header">

                        <h3 class="card-title">Billing Information</h3>
                        </div>
                            <div class="card-body">
                                
                                <div class="form-group">
                                    <label for="inputAddress">Address</label>
                                    <input type="text" class="form-control" id="inputAddress" placeholder="Enter Street Address, example: 1234 Main St" value={this.state.billing_address} name="billing_address" onChange={this.onChange} />

                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputCity">City</label>
                                        <input type="text" class="form-control" id="inputCity" placeholder="Enter City" value={this.state.billing_city} name="billing_city" onChange={this.onChange} />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="inputState">State</label>
                                        <select id="inputState" class="form-control" value={this.state.billing_state} name="billing_state" onChange={this.onChange}>
                                            <option selected >Choose...</option>
                                            <option>CA</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="inputZip">ZIP Code</label>
                                        <input type="text" class="form-control" id="inputZip" placeholder="Enter ZIP Code" value={this.state.billing_zipcode} name="billing_zipcode" onChange={this.onChange} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-success float-right" style={{marginTop: "10px"}}>Create Customer</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default withRouter(CustomersAdd)

