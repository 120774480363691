import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";

export default function ReportsHeader() {

    return (
        <div>

            <div class="d-sm-flex align-items-center justify-content-between">
                <div>

                    <h1>Reports</h1>
                </div>

            </div>


        </div>
    )

}



