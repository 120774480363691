import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from "../../auth";


export default function SettingsUploadCarriers(props) {
    const [file, setFile] = useState()
    const [error, setError] = useState()


    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);

        const Upload = async () => {
            await authFetch('https://fltbd.herokuapp.com/api/upload-carriers', {
                method: 'POST',
                body: formData
            }).then(resp => {
                if(resp.status === 500) {
                    setError('Something went wrong, either no file, wrong file type, or wrong format.')
                }
                
            })
        }
        Upload();
    }
    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Upload Carriers Data</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }

    return (
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Upload Carriers Data</h3>
            </div>
            <div className="card-body">
                <p>Must be a CSV File.</p>
                <p>Example</p>

                <pre style={{ whiteSpace: 'nowrap' }}>
                    <code>
                        email,carrier_name,carrier_dba,physical_address,billing_address,carrier_mc,carrier_dot,phone_number,factor,factoring_company,federal_id,accounting_name,accounting_phone,signed_by,ten_nine_nine,contract_id<br />
                        
                        

                    </code>
                </pre>
                <center>
                <span class="text-danger">
                    {error}
                </span>
            </center>
                <form onSubmit={handleSubmit} className="container" enctype="multipart/form-data">
                    <div className="form-inline justify-content-center mt-5">
                        <label htmlFor="image" className="ml-sm-4 font-weight-bold mr-md-4">CSV :  </label>
                        <div className="input-group">
                            <input type="file" id="image" name="file"
                                accept="csv/*" className="file-custom" />
                        </div>
                    </div>

                    <div className="input-group justify-content-center mt-4">
                        <button type="submit" className="btn btn-md btn-primary">Upload</button>
                    </div>
                </form>
            </div>
        </div>
    )

}

