import { useState, useEffect } from "react";
import { useAuth, authFetch } from "../../auth";
import DOMPurify from 'dompurify';
import styled from "styled-components";
import moment from 'moment';


const Styles = styled.div`
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Rock+Salt|Shadows+Into+Light|Cedarville+Cursive');
@page {
    size: Letter;
    margin: 4em 2em;
    
    margin-top: 2em;
   

    @bottom-right {
      content: "fltbd.com";
      font-size: 0.8em;
      font-style: italic;
      font-family: Arial, Helvetica, sans-serif;
    }
    /* 
    @top-left {
        content: "setupPack.com";
        font-size: 0.8em;
    }
    */
  }
  @page :first {

  }
  
  @page ppe_page {

  }



  html {
    color: #000;
    font-family: sans;
    font-size: 10pt;
    line-height: 1.4;
  }
  
  
  h1 {
      color: #000;
    font-weight: 400;
  }
  
  h2 {
    color: #27b;
    font-size: 1em;
    margin: 2em 0 0 0;
    text-transform: uppercase;
  }
  .h8 {
    color: #27b;
    font-size: 1.25em;
    margin: 2em 0 0 0;
    text-transform: uppercase;
  }
  
  p {
    margin: 0.5em 0;
    text-align: justify;
  }
  
  
  aside {
    background: #eee;
    float: right;
    margin-left: 1em;
    width: 30%;
  }
  
  aside embed {
    margin-bottom: 1em;
    width: 100%;
  }
  
  aside article {
    margin: 1em;
  }
  
  aside article:not(article:last-child) {
    border-bottom: 1px solid #aaa;
  }
  
  
  #secondary {
    
    text-align: right;
    width: 100%;
  }
  
  #secondary article {
    display: table-cell;
    padding-right: 1em;
    border-bottom:1px solid black;
    padding: 20px;
  }
  
  #secondary p {
    font-family: 'Cedarville Cursive', cursive;
    font-size: 1.8em;
    font-size: 3.5vw;
    padding: 1em;
    border-bottom: 1px solid black;
    
  }
  
    #secondary p {
 
    padding-bottom: 0em;
    
  }
  embed {
    width: 60%;
  }
  /* 
  li
{
    border-bottom: 2px solid black;
    width: 725px;
    list-style-type: none;
    padding-top: 20px
}
  */

label
{
    border-bottom: 2px solid white;
    padding-right: 5px;
}

* {
    box-sizing: border-box;
  }
  
  .box {
    float: left;
    width: 33.33%;
    padding: 0px;
  }
  .box2 {
    float: left;
    width: 50%;
    
  }
  
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .box p {
    margin: 0.5em 0;
    text-align: justify;
    font-size: 10pt;
    text-align:right;
  }
  table, th, td {
  
    border-collapse: collapse;
    
  }
  th, td {
    padding: 5px;
    text-align: left;
    font-size: 16px;
  }
  tr {
    padding-bottom:20px;
  }
  .ppe {
    page: ppe_page;
    page-break-before: always;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    margin: 0.65in;
    margin-top: .30in;
  }

  .ppe-title {
    margin-bottom: 30px;
    color: red;
    font-size: 25px;
    font-weight: bold;
  }

  .ppe-alert  {
    color: red;
    font-size: 18px;
  }
  
  .ppe li{
    border: none;
    list-style-type: disc;
  }

  .contract {
    page-break-before: always;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-right: 0.65in;
    margin-left: 0.65in;
  }

  .contract-bold {
    font-weight: bold;
  }
  
  .contract-underline {
    border-bottom: 1px solid black;
  }

  .contract li {
    list-style-type: none;
  }

  .contract tr {
    width:50%; 
    text-align: center;
  }

  .contract td {
    font-weight: bold;
    text-align: center;
  }

  .contract th {
    text-align: center;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }

  .ppe .footer{
    display: none;
  }

  .broker{

    width: 50%;
}
.carrier{

    width: 50%;
}

.container2 {
  display:flex;
}
.profile_signed {
    border-bottom: 1px solid black;    
    width: 70%;

}
.profile_date {
    border-bottom: 1px solid black;
    width: 30%;

}

.container2 span {
  font-size: 30px;
}

.profile_signed {
  border-bottom: 1px solid black;    
  width: 70%;

}
.profile_date {
  border-bottom: 1px solid black;
  width: 30%;

}

.container2 span {
font-size: 30px;
}


table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}
th {
  border-bottom: 1px solid black;
  
  font-size: 10pt;
  font-weight: 400;
  padding-bottom: .25cm;
  text-transform: uppercase;
}
td {
  padding-top: 0;
}
/* 
td:last-of-type {
  color: #1ee494;
  font-weight: bold;
  text-align: right;
}
*/
th, td {
  text-align: center;
}
th:first-of-type, td:first-of-type {
  text-align: left;
}
th:last-of-type, td:last-of-type {
  text-align: right;
}
table#total {
  background: #f6f6f6;
  border-color: #f6f6f6;
  border-style: solid;
  border-width: 2cm 3cm;
  bottom: 0;
  font-size: 20pt;
  margin: 0 -3cm;
  position: absolute;
  width: 18cm;
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.sec-column {
  float: left;
  width: 33.3%;
}

/* Clear floats after the columns */
.sec-row:after {
  content: "";
  display: table;
  clear: both;
}

.align-right {
  text-align: right;
}

.float-right {
  float:right;
}
.float-left {
  float: left;
}

.rate-confirmation-title {
  font-weight: bold;
  font-size: large
}

.bottom-border {
  border-bottom: 1px solid black;
}
.rate {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
}
hr {
  margin: 0px
}

`
function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}


export default function RateConfirmationItem(props) {
  const initialState = () => {
    return (props)
  }
  const [state, setState] = useState(props);
  const [carrier, setCarrier] = useState(props.carrier_match)
  const [driverName, setDriverName] = useState('')
  const [driverNumber, setDriverNumber] = useState('')
  const [signer, setSigner] = useState('')

  const driverNameChange = e => {
    setDriverName(e.target.value)
  }
  const driverNumberChange = e => {
    setDriverNumber(e.target.value)
  }
  const signerChange = e => {
    setSigner(e.target.value)
  }

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    fetch(`https://fltbd.herokuapp.com/document/confirmation/load/${props.load_id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        carrier_signedby: signer,
        driver_name: driverName,
        driver_number: driverNumber,
      }),
      headers: { 'Content-Type': 'application/json' },

    })
    setTimeout(() => {
      fetch(`https://fltbd.herokuapp.com/create-pdf/${props.load_id}`)
    }, 3000);
    props.parentCallbackGenerating(true)
  }

  const handleInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setDriverNumber(formattedPhoneNumber);
  };



  return (

    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
      <div class="card">
        <div class="card-body">
          {!props.hide_details ? "" :
            <center>Some details are hidden by the broker, all details will be revealed once accepted.</center>
          }
          <Styles>
            <div class="row">
              <div class="column">
                <p class="rate-confirmation-title">Rate Confirmation</p>
              </div>
              <div class="column float-right align-right">
                <p class="align-right">Load Number: {props.load_number}</p>
              </div>
            </div>

            <hr />
            <div class="sec-row mt-2">
              <div class="sec-column">
                <b>Broker:</b><br />
                <b>{props.company_name}</b><br />
                {props.company_address}<br />
                {props.company_city} {props.company_state} {props.company_zipcode}
                {props.company_phone}<br />
                MC: {props.company_mc}
              </div>
              <div class="sec-column">
                <b>Carrier:</b><br />
                <b>{props.carrier_name}</b><br />
                {props.carrier_address}<br />
                DOT: {props.carrier_dot}&nbsp;
                MC: {props.carrier_mc}<br />
                {props.carrier_phone}
              </div>
              <div class="sec-column float-right">
                <b>Load:</b><br />
                Trailer: {props.trailer}<br />
                Equipment: {props && props.equipment && props.equipment.map((item, index) => {

                  return (
                    <>
                      {item === null ? "" : <span key={index}>{(index ? ', ' : '') + item.value}</span>}

                    </>
                  )
                })}
                <br />
                {3 === 3 ? null :
                  <p>Distance: P Tags</p>
                }
              </div>
            </div>
            <hr />
            <br />
            <h3>Shippers:</h3>
            <table style={{ border: "none" }}>

              <tbody>
                {props.shippers && props.shippers.map((shipper, idx) =>

                  <tr key={shipper.id} class="border border-dark" >
                    {!props.hide_details ?
                      <td class="pl-3 pt-2">
                        <b>{shipper.location_name}</b>
                        <div>{shipper.street_address} {shipper.city} {shipper.state} {shipper.zipcode}</div>
                        <div>{shipper.contact_name} {shipper.phone_number}</div>
                        <div class=" p-2" >
                          {!shipper.pickup_number ? null : <div> Pick Up Number: <b>{shipper.pickup_number}</b></div>}
                          {!shipper.cargo_description ? null : <div> Cargo Description: <b>{shipper.cargo_description}</b></div>}
                          {!shipper.extra_notes ? null : <div> Extra Note: <b>{shipper.extra_notes}</b></div>}
                        </div>

                      </td>

                      :
                      <td>
                        <b>[HIDDEN]</b>
                        <div>{shipper.city} {shipper.state}</div>
                      </td>
                    }
                    <td class="pr-2">

                      {shipper.date}
                    </td>

                  </tr>
                )}




              </tbody>
            </table>
            <br />
            <h3>Receivers:</h3>
            <table style={{ border: "none" }}>

              <tbody>
                {props.receivers && props.receivers.map((receiver, idx) =>

                  <tr key={receiver.id} class="border border-dark">
                    {!props.hide_details ?
                      <td class="pl-3 pt-2">
                        <b>{receiver.location_name}</b>
                        <div>{receiver.street_address} {receiver.city} {receiver.state} {receiver.zipcode}</div>
                        <div>{receiver.contact_name} {receiver.phone_number}</div>
                        <div class=" p-2" >
                          {!receiver.extra_notes ? null : <div> Extra Note: <b>{receiver.extra_notes}</b></div>}
                        </div>
                      </td>
                      :
                      <td>
                        <b>[HIDDEN] </b>
                        <div>{receiver.city} {receiver.state}</div>
                      </td>
                    }
                    <td class="pr-2">

                      {receiver.date}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <br />
            
            {props.accessorial_fees && props.accessorial_fees !== null && props.accessorial_fees.length ?
              <>
              <h3>Breakdown:</h3>
              <table class="table table-transparent table-responsive">
                
                <thead >
                  <tr>

                    <th style={{ width: "1%", backgroundColor: "white", border: "1px solid black" }}>Fee</th>
                    <th class="text-center" style={{ width: "1%", backgroundColor: "white", border: "1px solid black" }}>Quantity</th>
                    <th class="text-center" style={{ width: "1%", backgroundColor: "white", border: "1px solid black" }}>Amount</th>
                    <th class="text-end" style={{ width: "1%", backgroundColor: "white", border: "1px solid black" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                <tr class="border border-dark">
                    <td style={{border:"1px solid black"}}>
                      Line-Haul
                    </td>
                    <td style={{border:"1px solid black"}}>
                      1
                    </td>
                    <td style={{border:"1px solid black"}}>
                      ${props.line_haul}
                    </td>
                    <td style={{border:"1px solid black"}}>
                      ${props.line_haul}
                    </td>
                  </tr>
                  {props.accessorial_fees && props.accessorial_fees.map((fee, idx) =>

                    <tr key={idx} class="border border-dark">
                        <td style={{border:"1px solid black"}}>
                          {fee.type}
                        </td>             
                      <td style={{border:"1px solid black"}}>
                        {fee.quantity}
                      </td>
                      <td style={{border:"1px solid black"}}>
                        ${fee.amount}
                      </td>
                      <td style={{border:"1px solid black"}}>
                        ${fee.quantity * fee.amount}
                      </td>
                    </tr>
                  )}

                 
              </tbody>
            </table>
            </>
          :
          null
            }
          <div class="rate align-right">
            <b>Rate: ${props.carrier_pay} </b>
          </div>
          <div class="company_tc markdown">
            <div className="text-center"><h3>Terms & Conditions</h3></div>
            <div className="company_tc markdown" dangerouslySetInnerHTML={createMarkup(props.company_tc)}></div>
          </div>
        </Styles>
        <form onSubmit={handleSubmit}>
          <div class="form-row" style={{ border: '1px solid black', padding: '10px' }}>
            <div class="form-group col-md-6">
              <label >Driver Name</label>
              <input type="text" class="form-control" placeholder="Driver Name" onChange={driverNameChange} required />
            </div>
            <div class="form-group col-md-6">
              <label>Driver Phone Number</label>
              <input type="text" class="form-control" placeholder="Driver Phone Number" value={driverNumber} onChange={handleInput} required />
            </div>
            <div class="form-group col-md-6">

            </div>
            <div class="form-group col-md-6">
              <label>Carrier Signature</label>
              <input type="text" class="form-control" placeholder="Carrier Signer Name" onChange={signerChange} required />
            </div>
          </div>
          <div class="text-center" style={{ marginTop: "30px" }}>
            <button class="btn btn-success btn-lg">Accept</button>
          </div>
        </form>
        <p class="text-muted text-center mt-5">binding ID#{props.binding_id}</p>




      </div>
    </div>
    </div >


  )
}

