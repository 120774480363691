import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory,
} from "react-router-dom";
import AccountingSidebar from "./AccountingSidebar";
import AccountingReadyTable from "./AccountingReadyTable";
import AccountingInvoice from "./AccountingInvoice";
import CustomersTable from "../Customers/CustomersTable";
import AccountingCreateInvoice from "./AccountingCreateInvoice";
import AccountingInvoicesTable from "./AccountingInvoicesTable";
import AccountingCustomersTable from "./AccountingCustomersTable";
import NotFound from "../Home/NotFound";
import AccountingCreateNewInvoice from "./AccountingCreateNewInvoice";
import AccountingInvoiceNotFound from "./AccountingInvoiceNotFound";
import AccountingDefault from "./AccountingDefault";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";
import DisabledPageModal from "../Modals/DisabledPageModal";

function AccountingHeader() {
    return (
        <div class="d-sm-flex">
            <h1>Accounting 
            <span class="badge">Beta</span>
            </h1>
        </div>
    )
}

export default function AccountingContainer(props) {

    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true);
    const [data, setData] = useState()
    const [allLoads, setAllLoads] = useState()
    const [billingReady, setBillingReady] = useState()
    const [invoices, setInvoices] = useState()
    const [customers, setCustomers] = useState([])


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (props && props.loads) {
            const loads = props.loads
            setAllLoads(loads)
            const readyForBilling = props.loads.filter(load => load.status == "Completed")
            setBillingReady(readyForBilling)
            setShow(false)
            setLoading(false)
        }
    }, [props.loads])

    useEffect(() => {
        if (props && props.customers) {
            setCustomers(props.customers)
        }
    }, [props.customers])

    useEffect(() => {
        if (props && props.invoices) {
            setInvoices(props.invoices)
        }
    }, [props.invoices])

    useEffect(() => {
        if (props) {
            setData(props)
        }
    }, [props])

    //handleNewInvoice+updateLoad don't work on their own.. see handleUpdate for both working.
    const handleNewInvoice = (NewInvoice) => {
        const invoiceCopy = [...invoices]
        setInvoices([...invoiceCopy, NewInvoice])
                           
        props.updateInvoiceList([...invoiceCopy, NewInvoice])
  
    }
    const updateLoad = (e) => {
        //Works just for create-invoice because status goes Completed->Invoice
        const dataCopy = [...allLoads]
        const mappedData = dataCopy.map(g => (g.load_id === e ? Object.assign({}, g, { status: "Invoiced" }) : g))

        props.updateLoadList(mappedData)
    }
    const handleUpdate = (NewInvoice, e) => {
        const invoiceCopy = [...invoices]
        const invoiceList = ([...invoiceCopy, NewInvoice])
        setInvoices([...invoiceCopy, NewInvoice])
        const dataCopy = [...props.loads]
        const mappedData = dataCopy.map(g => (g.load_id === e ? Object.assign({}, g, { status: "Invoiced" }) : g))
        props.createInvoice(invoiceList, mappedData)                
    }
    return (
        <>
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            {3 === 3 ? null :
            <DisabledPageModal />
            }
            <Router history={history}>
                <AccountingHeader />
                <div class="row row-cards" style={{ paddingTop: '0px' }}>
                    <AccountingSidebar {...props} />
                    <div class="col-lg-9">
                        <Switch>


                            <Route exact path="/accounting/ready"
                                render={(props) => <AccountingReadyTable {...props} 
                                data={billingReady} 
                                updateLoad={updateLoad} 
                                title="FreightBroker.ai - Loads Ready For Invoicing"
                                />}
                            />

                            <Route path="/accounting/create-invoice/:invoice_id"
                                render={(props) => <AccountingCreateInvoice
                                                    handleUpdate={handleUpdate}
                                                    updateLoad={updateLoad}
                                                    handleNewInvoice={handleNewInvoice}
                                                    {...props} 
                                                    {...data}
                                                    title="FreightBroker.ai - Create Invoice Using Existing Load"
                                                    />}

                            />
                            <Route exact path="/accounting/create-invoice"
                            render={(props) => <AccountingCreateNewInvoice
                                handleUpdate={handleUpdate}
                                updateLoad={updateLoad}
                                handleNewInvoice={handleNewInvoice}
                                {...props} 
                                {...data}
                                title="FreightBroker.ai - Create New Invoice"                                        
                             /> }
                             />
                            <Route exact path="/accounting/invoices"
                                render={(props) => <AccountingInvoicesTable 
                                                    {...props} data={invoices} 
                                                    title="FreightBroker.ai - Invoices"  />}
                            />
                            <Route path="/accounting/invoice/:invoice_id"
                                render={(props) => <AccountingInvoice {...props} {...data} />}
                            />

                            <Route exact path="/accounting/customers"
                                render={(props) => <AccountingCustomersTable {...props} customersData={customers} title="FreightBroker.ai - Customers" />}
                            />

                            <Route path="*">
                                <Redirect to='/accounting/invoices' />
                            </Route>

                        </Switch>
                    </div>
                </div>
            </Router>
        </div>
         <Modal show={show} size="sm" centered>

         <LoadingModal />

      </Modal>
  </>
    )

}