import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../auth";
import {
  Route, Switch, Redirect, Router, useHistory, Link
} from 'react-router-dom';
import axios from "axios";


import { convertToHTML } from 'draft-convert';
import { ContentState, convertToRaw, EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './../../App.css'
import DOMPurify from 'dompurify';
import { stateFromHTML } from 'draft-js-import-html';


function ProfileHeader() {
  return (
    <div class="d-sm-flex">
      <h1>Profile</h1>
    </div>
  )
}

export default function ProfileContainer() {
  const history = useHistory();
  const [state, setState] = useState()
  const [userRole, setUserRole] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [companyID, setCompanyID] = useState()
  const [companyTC, setCompanyTC] = useState(' ')
  const [companyName, setCompanyName] = useState()
  const [companyAddress, setCompanyAddress] = useState()
  const [inviteEmail, setInviteEmail] = useState()
  const [dot_number, setDOTNumber] = useState('')
  const [ip, setIP] = useState('');

  const [editorState, setEditorState] = useState(
    () =>
      EditorState.createWithContent(stateFromHTML(companyTC)),
  );
  const [convertedContent, setConvertedContent] = useState();

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();

  }

  const handleInviteCodeChange = (e) => {
    setInviteEmail(e.target.value)
  }

  const handleDOT = (e) => {
    setDOTNumber(e.target.value)
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }
  const getData = async () => {
    const res = await axios.get('https://ipinfo.io/json')
    setIP('test')
  }
  

  const handleSubmit = e => {
    e.preventDefault();
    console.log(convertedContent)
    authFetch(`https://fltbd.herokuapp.com/api/companys/${companyID}`, {
      method: 'PATCH',
      body: JSON.stringify({
        company_tc: convertedContent,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
  }
  const handleInviteCode = e => {
    e.preventDefault();
    console.log(inviteEmail)
    authFetch(`https://fltbd.herokuapp.com/api/companys/invitecode`, {
      method: 'PUT',
      body: JSON.stringify({
        email: inviteEmail,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
  }
  const sendPacket = e => {
    e.preventDefault();
    authFetch(`https://fltbd.herokuapp.com/bs4`, {
      method: 'PUT',
      body: JSON.stringify({
        dot_number: dot_number,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
  }


  useEffect(() => {
    getData()
    authFetch("https://fltbd.herokuapp.com/user/").then(response => {
      if (response.status === 500){
        //history.go(0)
        logout()
        console.log(response.status)
        //return null
      }
      return response.json()
    })       
      .then(response => {
        if (response) {
          setState(response)
          setFirstName(response.first_name)
          setLastName(response.last_name)
          setEmail(response.email)
          setCompanyID(response.company_id)
          setUserRole(response.roles)
          authFetch(`https://fltbd.herokuapp.com/company/${response.company_id}`)
            .then(data => {
              if (data.status === 401) {

                console.log(data.status)
                //return null
              }
              return data.json()
            })
            .then(data => {
              if (data) {
                setCompanyName(data.company_name)
                setCompanyTC(data.company_tc)
                setCompanyAddress(data.company_address)
              }
            })
        }
      })
  }, [])

  return (
    <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">

      <ProfileHeader />

      <div class="row">
        <div class='col-md-12'>
          <div class="row">
            <div class='col-md-6'>
              <div class="card" >
                <div class="card-body">
                  <div className="card-title">User Information</div>
                  {firstName} {lastName} <br />
                  {email}
                  <h2>Your IP Address is</h2>
      <h4>{ip}</h4>
                </div>
              </div>
            </div>
            <div class='col-md-6'>
              <div class="card" >
                <div class="card-body">
                  <div className="card-title">Company Information</div>
                  {companyName} <br />
                  {companyAddress} <br />
                  <hr />
                  <form onSubmit={handleInviteCode}>
                    Send Invite Code<br />
                    <input type="text" name="inviteEmail" onChange={handleInviteCodeChange} /><button type="submit">Submit</button>
                  </form>
                  <hr />
                  <form onSubmit={sendPacket}>
                    ENTER DOT<br />
                    <input type="text" name="dot_number" onChange={handleDOT} value={dot_number} /><button type="submit">Submit</button>
                  </form>
                  <hr />
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>

      

    </div>
  )
}


