import React, { useEffect, useState } from "react";
import {
    Route, Switch, Redirect, Router, useHistory
} from 'react-router-dom';
import { useAuth, authFetch, logout } from "../../auth";
import DriversField from "./DriversField";
import DriversHeader from "./DriversHeaders";
import DriversTable from "./DriversTable";


export default function DriversContainer(props) {
    const [data, setData] = useState([])

    const handleNewDriver = (NewDriver) => {
        const dataCopy = [...data]
        setData([...data, NewDriver])
    }
    
    const handleRemoveDriver = (removeDriver) => {
      const dataCopy = [...data];
      dataCopy.splice(removeDriver.row.index, 1)
      setData(dataCopy)
      authFetch(`https://fltbd.herokuapp.com//api/drivers/${removeDriver.value}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
      })
  }

  useEffect(() => {
    if(props  &&  props.drivers) {
        setData(props.drivers)
    }
}, [props.drivers])

useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);
    return(
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <DriversHeader />
            <DriversField parentNewDriver={handleNewDriver} />
            <DriversTable data={data} parentRemoveDriver={handleRemoveDriver}/>
        </div>

    )
}