import React, { useState, useEffect } from 'react';


const Subscription = (props) => (
  
    
  <section>
    <div className="product">

      <div className="description">
        <h3>Start Your No-Risk Free 15 day Trial</h3>
        <h5>After that, $20.00 / month per user</h5>
      </div>
    </div>
    <form action="https://fltbd.herokuapp.com/api/stripe-create-checkout" method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="lookup_key" value="sub" />
      <input type="hidden" name="stripe_customer_id" value={props.stripeCustomerID} />
      <input type="hidden" name="customer_email" value={props.email} />
      <button className="btn btn-primary btn-lg mt-2" id="checkout-and-portal-button" type="submit">
        Start Trial
      </button>
    </form>
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <div className="card">
          <div class="card-header">
                <h3 class="card-title">Thank you for using our product!</h3>
            </div>
            <div className="card-body">
            
           
 
            </div>
          </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);
const ManageSubScription = (props) => {
    return(
      <>
      <h4>Your subscription status: &nbsp;
        
        {props.stripeStatus === "Inactive" ||  props.stripeStatus === "Paused" || props.stripeStatus === "Canceled" ? 
          <span className="text-danger">Inactive</span>
          :
          props.stripeStatus === "Active" ? 
          <span className="text-success">Active</span>
          :
          props.stripeStatus === "Trial" ? 
          <span className="text-info">Trial</span>
          :
          null
        }
      
      </h4>
      {props.stripeStatus === "Canceled" ?
        <form action="https://fltbd.herokuapp.com/api/stripe-create-checkout" method="POST">
        <input
          type="hidden"
          id="customer-id"
          name="stripe_customer_id"
          value={props.stripeCustomerID}
        />
         <input
          type="hidden"
          id="customer-id"
          name="canceled"
          value={true}
        />
        <button className="btn btn-info btn-lg mt-2" id="checkout-and-portal-button" type="submit">
          Restart Subscription
        </button>
      </form>
        :
        <form action="https://fltbd.herokuapp.com/api/stripe-customer-portal" method="POST">
        <input
          type="hidden"
          id="customer-id"
          name="stripe_customer_id"
          value={props.stripeCustomerID}
        />
        <button className="btn btn-primary btn-lg mt-2" id="checkout-and-portal-button" type="submit">
          Manage Subscription
        </button>
      </form>
      }
      </>
    )
}
export default function SettingsSubscription(props) {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const [stripeSubID, setStripeSubID] = useState()
  const [stripeCustomerID, setStripeCustomerID] = useState()
  const [stripeStatus, setStripeStatus] = useState()
  const [email, setEmail] = useState()

  
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    if(props && props.stripe && props.email) {
      setStripeSubID(props.stripe[0].stripe_subscription_id)
      setStripeCustomerID(props.stripe[0].stripe_customer_id)
      setStripeStatus(props.stripe[0].status)
      setEmail(props.email)
    }
  }, [props.stripe],[props.email])


  if (!success && message === '') {
    return (
        <>
          <div className="card">
          <div class="card-header">
                <h3 class="card-title">Subscription</h3>
            </div>
            <div className="card-body">
            
            {stripeStatus === null ?  
            <Subscription email={email} stripeCustomerID={stripeCustomerID} /> :
            null
            }
            {stripeStatus !== null ? 
            <ManageSubScription stripeStatus={stripeStatus} stripeSubID={stripeSubID} stripeCustomerID={stripeCustomerID} />
            :
            null
            }
 
            </div>
          </div>
    </>
    )
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
  
}

