import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';


export default function EquipmentItemAlerts(props) {
    const [state, setState] = useState('')
    const [registrationAlert, setRegistrationAlert] = useState()
    const [maintenanceAlert, setMaintenanceAlert] = useState()
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }

    const registrationAlertChange = (e) => {
        setRegistrationAlert(!registrationAlert)
        props.parentRegistrationAlert(!registrationAlert)
    }
    const maintenanceAlertChange = (e) => {
        setMaintenanceAlert(!maintenanceAlert)
        props.parentMaintenanceAlert(!maintenanceAlert)
    }

    useEffect(() => {
        setState(props)
        setRegistrationAlert(props.registration_alert)
        setMaintenanceAlert(props.maintenance_alert)
    })

    const handleSubmit = e => {
        e.preventDefault();

        if (props.truck_notes) {
            authFetch(`https://fltbd.herokuapp.com/api/equipment/truck/${props.equipment_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    registration_alert: state.registration_alert,
                    maintenance_alert: state.maintenance_alert

                }),
                headers: { 'Content-Type': 'application/json' },
            })
        }
        if (props.trailer_records) {
            authFetch(`https://fltbd.herokuapp.com/api/equipment/trailer/${props.equipment_id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    registration_alert: state.registration_alert,
                    maintenance_alert: state.maintenance_alert

                }),
                headers: { 'Content-Type': 'application/json' },
            })

        }
        setEdit(!edit)
    }

    return (
        <div class="card">
            <div class="card-header" style={{ border: "none" }}>
                <h3 class="card-title">Alerts</h3>
                <div class="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>
                </div>
            </div>
            <div class="card-body">
                <form onSubmit={handleSubmit}>
                    <div>
                        <label class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" checked={registrationAlert} name="registration_alert" onChange={registrationAlertChange} disabled={edit} />
                            <span class="form-check-label">Registration Alert:

                                {!state.registration_alert ?
                                    <span class="text-danger"><b> OFF </b></span>
                                    :
                                    <span class="text-success"><b> ON </b> <br />
                                       <label class="form-label" style={{color: "black"}}> Alerts 1 Year From Registration Date</label>
                                    </span>

                                }</span>
                        </label>
                    </div>
                    <div>
                        <label class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" checked={maintenanceAlert} name="maintenance_alert" onChange={maintenanceAlertChange} disabled={edit} />
                            <span class="form-check-label">Maintenance Alert:

                                {!state.maintenance_alert ?
                                    <span class="text-danger"><b> OFF</b></span>
                                    :
                                    <span class="text-success"><b> ON</b> <br />
                                        <div class="row">
                                            <div class="col-md-8">
                                                <label class="form-label" style={{ color: "black" }}>Alert Days Since Last Maintenance


                                                </label>
                                            </div>
                                            <div class="col-md-4">
                                                <input type="text" class="form-control" />
                                            </div>
                                        </div>

                                    </span>
                                }</span>
                        </label>
                    </div>
                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>
        </div>
    )

}