import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
  } from "react-router-dom";


class LocationsHeader extends Component {
    render() {
        return (
            <div class="d-sm-flex align-items-center justify-content-between">
                    <div>
                        
                        <h1>Locations</h1>
                    </div>
                   
                </div>
        )
    }
}

export default LocationsHeader