import React, { useEffect, useState } from "react";
import { authFetch } from "../../auth";

import { Alert } from "react-bootstrap";


export default function SettingsLoadNumber(props) {


    const [loadNumber, setLoadNumber] = useState()
    const [showingSuccessAlert, setShowingSuccessAlert] = useState(false);

    const [loaded, setLoaded] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault()
        authFetch(`https://fltbd.herokuapp.com/api/companys/${props.id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                current_load_number: loadNumber,
            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(r => r.json())
        .then(data => {
            if (data.success) {
                onShowSuccessAlert()
                
                props.updateCompany({current_load_number:parseInt(loadNumber)})
            }
        })

    }
    const onShowSuccessAlert = () => {
        setShowingSuccessAlert(true,
            setTimeout(() => {
                setShowingSuccessAlert(false)
            }, 2000)
        );
    }


    const handleChange = e => {
        setLoadNumber(e.target.value)
        
    }

    useEffect(() => {
        if (props && props.current_load_number === null) {
            setLoadNumber(props.starting_load_number)

        }
        else {
            setLoadNumber(props.current_load_number)

        }
    }, [props.current_load_number])

    useEffect(() => {
        if (loadNumber !== undefined) {
           
        }
    }, [loadNumber])
    useEffect(() => {
        if (props && props.userRole) {
            setLoaded(true)
        }
    }, [props.userRole])


    if (loaded && !props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Load Number Settings</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }

    return (
        <>
            <div className="card">
                <div class="card-header">
                    <h3 class="card-title">Load Number Settings</h3>
                </div>
                <div className="card-body">
                <Alert variant="success" show={showingSuccessAlert} >
                    Load Number Successfully Updated.
                </Alert>
                    <p>Your current load number is:</p>

                    <div className="d-flex justify-content-center">
                        <input type="num" value={loadNumber} name="current_load_number" onChange={handleChange} />
                    </div>

                    <form onSubmit={handleSubmit} className="container">

                        <div className="mt-4">
                            <button type="submit" className="btn btn-success float-right">Save</button>
                        </div>
                    </form>
                </div>
            </div>
         
        </>
    )

}

