import React, { useEffect, useState } from "react";
import SettingsUsersTable from "./SettingsUsersTable";


export default function SettingsManageUsers(props) {
    const [employees, setEmployees] = useState()
    useEffect(() => {
        if(props  && props.employees) {
            setEmployees(props.employees)
        }
    }, [props.employees])
    useEffect(() => {
        document.title = props.title || "";
      }, [props.title]);
    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Manage Users</h3>
            </div>
                <div class="card-body">
                    
                    You don't have access to this. If you think this a mistake please contact one of your company's administators.
                </div>
            </div>
      
    }

    return(
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Manage Users</h3>
                {!props.activeSub ?
                null
                :
                <div class="edit-button">
                    <a href="/settings/manage-user/add"> [add user]</a>
                </div>
                }
            </div>
            <div className="card-body">
            <SettingsUsersTable {...props} employees={employees}/>
            </div>
        </div>
    )
}