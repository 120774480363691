import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../auth";
import {
    Route, Switch, Redirect, Router, useHistory
} from 'react-router-dom';
import { convertToHTML } from 'draft-convert';
import { ContentState, convertToRaw, EditorState, convertFromRaw, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './../../App.css'
import { stateFromHTML } from 'draft-js-import-html';
import { Alert } from "react-bootstrap";


export default function SettingsContract(props) {
    const history = useHistory();
    const [state, setState] = useState()
    const [companyContract, setCompanyContract] = useState(' ')
    const [userRole, setUserRole] = useState('')
    const [companyName, setCompanyName] = useState()
    const [companyID, setCompanyID] = useState()
    const [loading, setLoading] = useState(false)
    const [showingAlert, setShowingAlert] = useState(false);


    const [editorState, setEditorState] = useState(
        () =>
            EditorState.createWithContent(stateFromHTML(companyContract)),
    );
    const [convertedContent, setConvertedContent] = useState();
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();

    }
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }


    useEffect(() => {
        if (props && props.company_contract) {
            setCompanyContract(props.company_contract)
            setEditorState(EditorState.createWithContent(stateFromHTML(props.company_contract)))
        }
    }, [props.company_contract])
    const handleSubmit = e => {
        e.preventDefault();
        if (convertedContent !== undefined) {
        authFetch(`https://fltbd.herokuapp.com/api/companys/${props.id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                company_contract: convertedContent,
            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(r => r.json())
            .then(data => {
                if (data.success) {
                    onShowAlert()
                    setLoading(true);
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                    props.parentCompanyContract(convertedContent)
                }
            })
        }

    }

    const insertPlaceholder = placeholderText => {
        const newContentState = Modifier.insertText(editorState.getCurrentContent(),
            editorState.getSelection(),
            placeholderText);
        setEditorState(EditorState.createWithContent(newContentState));
        convertContentToHTML();



    }
    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    const onShowAlert = () => {
        setShowingAlert(true,
            setTimeout(() => {
                setShowingAlert(false)
            }, 2000)
        );
    }

    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Manage Company Contract</h3>
            </div>
            <div class="card-body">
                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }
    return (
        /*
        <button onClick={() => insertPlaceholder('{{company_name}}')}>
                            Add Company Name
                        </button>
                        <button onClick={() => insertPlaceholder('{{date}}')}>
                            Add Date
                        </button>
        */

        <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Manage Contract</h3>
            </div>
            <div class="card-body">
                <Alert variant="success" show={showingAlert} >
                    Contract Successfully Updated.
                </Alert>

                <div className="form-label">
                Insert your broker-carrier agreement below. When a carrier is sent a packet, this agreement will be apart of it.
                </div>

                <form onSubmit={handleSubmit}>
                    <Editor

                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="wrapper-class-none"
                        editorClassName="editor-class company-contract-height"
                        toolbarClassName="toolbar-class"

                    />
                    {loading ?
                        <div class="float-right">
                            <button className="btn btn-success float-right mt-3" disabled>Saving...</button>

                        </div>
                        :
                        <button type="submit" className="btn btn-success float-right mt-3">Make Changes</button>
                    }
                </form>
            </div>
        </div>

    )
}