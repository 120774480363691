import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { authFetch } from '../../../auth';



const UserDelete = (props) => {

    const initialState = () => {
        return (props)
    }
    const history = useHistory();
    const [state, setState] = useState(initialState);
    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setState(props);
    }
    
    const handleRemoveCustomer = () => {
        
        authFetch(`https://fltbd.herokuapp.com/api/manage-users/${props.userID}`, {
            method: 'DELETE',

        })
        props.deleteEmployee(props.userID)
        history.push('/settings/manage-users')
        
    }



    return (
        <>
            <div>
                <Link>
                            <button onClick={handleShow} className="btn btn-danger float-left">Delete User</button>

                </Link>

                <Modal
                    show={showModal}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body class="modal-body">
                            <div>Are you sure you want to delete this user? All data from this user will be lost.</div>
                            <br />
                            <div>If you aren't sure, you better ask somebody.</div>
                           


                        </Modal.Body>
                        <Modal.Footer>
                         <Button variant="primary" onClick={handleClose}>
                                Don't Delete
                            </Button>
                            <Button variant="danger" onClick={handleRemoveCustomer} >
                                Delete
                            </Button>
                            
                            
                        </Modal.Footer>
                    </form>
                </Modal>


            </div>
        </>
    )
}

export default UserDelete