import React, { useEffect, useState } from "react";
import { useAuth, login } from "../../auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect
} from "react-router-dom";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import SignUpWizard from "../SignUp/SignUpWizard";



const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    If your company already has an existing account, you will need to get this invite code from one of the company administators.
  </Tooltip>
);

export default function SignUp(props) {
  const [openRegistration, setOpenRegistration] = useState(false)

  const [existingCompany, setExistingCompany] = useState(false)

  const history = useHistory();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [inviteCode, setInviteCode] = useState('')

  const [state, setState] = useState('')

  const [dotNumber, setDOTNumber] = useState()
  const [companyDetails, setCompanyDetails] = useState({})

  const [errorEmail, setErrorEmail] = useState('')
  const [errorInviteCode, setErrorInviteCode] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('')
  const [logged] = useAuth();
  const onSubmitClick = (e) => {
    e.preventDefault()
    
    if (checkPassword(password)) {
      if (password !== confirmPassword) {
        setErrorConfirmPassword('Error dont match')
      }
      else {
      let opts = {
        "email": email.toLowerCase(),
        "hashed_password": password,
        "first_name": firstName,
        "last_name": lastName,
        "invite_code": inviteCode,
      }
      fetch('https://fltbd.herokuapp.com/registration/signup', {
      method: 'PUT',
      body: JSON.stringify(opts),
      headers: { 'Content-Type': 'application/json' },
    }).then(r => r.json())
      .then(data => {
        console.log(data)
        if (data.error_code === '5021') {
          setErrorEmail(data.error)
        }
        if (data.error_code === '5015') {
          setErrorInviteCode(data.error)
        }

      })
    }
  }
    else {
      setErrorPassword('The password format is not correct..')
    }

    
  }


  const handleExistingCompany = () => {
    setExistingCompany(!existingCompany)
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }
  const handleFirstName = (e) => {
    setFirstName(e.target.value)
  }
  const handleLastName = (e) => {
    setLastName(e.target.value)
  }
  const handleInviteCode = (e) => {
    setInviteCode(e.target.value)
  }
  const changegExistingCompany = () => {
    setExistingCompany(!existingCompany);
  }

  const changeDOTNumber = (e) => {
    setDOTNumber(e.target.value)
  }

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,32}$/;
    return re.test(str);
  }
  function viewPassword(e) {
    var x = document.getElementById("passwordField");

    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }
  function viewConfirmPassword(e) {
    var x = document.getElementById("confirmPasswordField");

    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  } useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (

    <div class="container-xl">

      <div class="container-tight py-4">
        <div class="text-center mb-4">

          <h1>Sign Up</h1>

        </div>
        {existingCompany ?
          <div>
            {3===3 ?
             <>
            <div class="card card-md better-box-shadow">
              <div class="card-body">
                <a href="#" onClick={changegExistingCompany}><b>My Company Already Has An Account</b></a>
              </div>
              
            </div>
           
            <SignUpWizard />
            </>
            : null}
           
          </div>
          :
        
          <div>
            {openRegistration ?
             <div class="card card-md better-box-shadow">
              <div class="card-body">
                <a href="#" onClick={changegExistingCompany}><b>My Company Does NOT Have an Account</b></a>
              </div>
              
            </div>
            :
            <>
            <div class="card card-md better-box-shadow">
              <div class="card-body">
                <b>Sorry, we aren't accepting new brokers at this time.</b>
              </div>
              
            </div>
            
            <form class="card card-md better-box-shadow" action="#" method="get" autocomplete="off">

              <div class="card-body">

                <div class="mb-3">

                  <label class="form-label">Email Address</label>
                  <small class="form-hint">You will be using this to login.</small>
                  <input type="text" name="email" class="form-control" placeholder="Enter email" onChange={handleEmailChange} value={email} />
                  <small class="text-danger">{errorEmail}</small>
                </div>
                <div class="mb-2">
                  <label class="form-label">Password </label>
                  <small class="form-hint">
                        Your password must be 6-32 characters long, and contain at least one special character, one UPPERCASE letter and one lowercase letter.
                    </small>
                  <div class="input-group input-group-flat">
                    <input type="password" id="passwordField" class="form-control" placeholder="Password" onChange={handlePasswordChange} value={password} autocomplete="off" />
                    <span class="input-group-text">
                      <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewPassword}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                      </a>
                    </span>                   
                  </div>
                  <small class="text-danger">{errorPassword}</small>
                </div>
                <div class="mb-2">
                    <label class="form-label">Confirm Password </label>
                    <div class="input-group input-group-flat">
                        <input type="password" id="confirmPasswordField" class="form-control" placeholder="Confirm Password" onChange={handleConfirmPasswordChange} autocomplete="off" />
                        <span class="input-group-text">
                            <a href="#" class="link-secondary" title="Show password" data-bs-toggle="tooltip" onClick={viewConfirmPassword}>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx="12" cy="12" r="2" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                            </a>
                        </span>
                    </div>
                    <small class="text-danger">{errorConfirmPassword}</small>
                </div>
                <div class="mb-3">
                  <label class="form-label">First Name</label>
                  <input type="text" name="first_name" class="form-control" placeholder="Enter first name" onChange={handleFirstName} value={firstName} />
                </div>
                <div class="mb-3">
                  <label class="form-label">Last Name</label>
                  <input type="text" name="last_name" class="form-control" placeholder="Enter last name" onChange={handleLastName} value={lastName} />
                </div>
                <div class="mb-3">

                  <label class="form-label">Company Invite Code
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <small> [what's this?]</small>
                    </OverlayTrigger>
                  </label>

                  <input type="text" name="invite_code" class="form-control" placeholder="Enter company's invite code" onChange={handleInviteCode} value={inviteCode} />
                  <small class="text-danger">{errorInviteCode}</small>
                </div>

                <div class="form-footer">
                  <button type="submit" class="btn btn-primary w-100" onClick={onSubmitClick}>Sign Up</button>

                </div>
                <hr />
                <p>Aleady have an account? <Link to="/login"><a href="#"><b>Login</b></a></Link></p>

              </div>
            </form>
            </>
          }
          </div>
}
      </div>
    </div>
  )
}