import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from "../../auth";


export default function SettingsUploadCustomers(props) {
    const [file, setFile] = useState()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(true)
 
    useEffect(() => {
    
        setTimeout(() => {
          setLoading(false)
        }, 1000);
        
    
      }, []);



    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        
        const Upload = async() => {
          await authFetch('https://fltbd.herokuapp.com/api/upload-customers', {
            method: 'POST',
            body: formData
          })
          .then(resp => {

                if(resp.status === 500) {
                    setError('Something went wrong, either no file, wrong file type, or wrong format.')
                }

                
          })
        }
        Upload();
      }
    
    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Upload Customers Data</h3>
            </div>
            <div class="card-body">
                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }

    return(
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Upload Customers Data</h3>
            </div>
            <div className="card-body">
            <p>Must be a CSV File.</p>
            <p>Example</p>
            
            <pre style={{whiteSpace:'nowrap'}}>
                <code>
                customer_name,contact_name,phone_number,email,billing_contact_name,billing_phone_number,billing_email,billing_address <br/>
                The Customer Company,Franklin Underwood,555-555-1234,funderwood@thecustomerco.abc,555-555-1234,Linda Lundy,llundy@thecustomerco.abc,123 Fake St. Wallace GS. 4123021 <br/>
                Second Customer,Paula Altos,555-321-9000,paula@secondcustomer.abc,Paula Altos,555-321-9000,paula@secondcustomer.abc,5000 Second St FakeTown FK 134444

                </code>
            </pre>
            <center>
                <span class="text-danger">
                    {error}
                </span>
            </center>
            <form onSubmit={handleSubmit} className="container" enctype="multipart/form-data">
    <div className="form-inline justify-content-center mt-2">
        <label htmlFor="image" className="ml-sm-4 font-weight-bold mr-md-4">CSV :  </label>
        <div className="input-group">
            <input type="file" id="image" name="file" 
            accept="csv/*" className="file-custom"/>
        </div>
    </div>

    <div className="input-group justify-content-center mt-4">
        <button type="submit" className="btn btn-md btn-primary">Upload</button>
    </div>
</form>
            </div>
        </div>
    )

}

