
import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useRouteMatch,
    useParams
} from "react-router-dom";

export default function AccountingSidebar(props) {

    return (
        <div class="col-lg-3">
            <div class="card">
                <div class="card-body">

                    <h4>Ready for Invoicing</h4>
                    <ul class="list-unstyled space-y-1">
                        <Link to="/accounting/ready">
                            <li>Ready for Invoicing</li>
                        </Link>
                    </ul>
                    <h4>Invoices</h4>
                    <ul class="list-unstyled space-y-1">
                        <Link to="/accounting/invoices">
                            <li>Invoices</li>
                        </Link>

                        <Link to="/accounting/customers">
                            <li>Customers</li>
                        </Link>

                    </ul>
                    
                </div>
            </div>
        </div>
    )
}