import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';

export default function LocationsItemLocationAddress(props) {
    const [state, setState] = useState('')
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }
    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        props.parentLocationDetails({ [e.target.name]: e.target.value })
        //props.updateLocation({[e.target.name]: e.target.value, location_id: props.location_id, type: "locations"})
        //props.updateSingle({[e.target.name]: e.target.value, location_id: props.location_id, type: "locations", id: props.id})

    }
    useEffect(() => {
        setState(props)
    })
    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/locations/${props.location_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                street_address: state.street_address,
                city: state.city,
                state: state.state,
                zipcode: state.zipcode,
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit);
        props.updateLocation(state)
    }
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Location Address</h3>
                <div className="edit-button">
                    <a href="#" onClick={changeEdit}> [edit]</a>
                </div>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="form-label" for="inputAddress">Address</label>
                        <input type="text" className="form-control" value={state.street_address} name="street_address" onChange={handleChange} readOnly={edit} />
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="form-label" for="inputCity">City</label>
                            <input type="text" className="form-control" id="inputCity" value={state.city} name="city" onChange={handleChange} readOnly={edit} />
                        </div>
                        <div className="form-group col-md-4">
                            <label className="form-label" for="inputState">State</label>
                            <select className="form-control" value={state.state} name="state" onChange={handleChange} disabled={edit}>
                                <option value="">Select One</option>
                                <optgroup label="U.S. States/Territories">
                                    <option value="AK">Alaska</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DC">District of Columbia</option>
                                    <option value="DE">Delaware</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="IA">Iowa</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MD">Maryland</option>
                                    <option value="ME">Maine</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MT">Montana</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NY">New York</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VA">Virginia</option>
                                    <option value="VT">Vermont</option>
                                    <option value="WA">Washington</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WY">Wyoming</option>
                                </optgroup>
                                <optgroup label="Canadian Provinces">
                                    <option value="AB">Alberta</option>
                                    <option value="BC">British Columbia</option>
                                    <option value="MB">Manitoba</option>
                                    <option value="NB">New Brunswick</option>
                                    <option value="NF">Newfoundland</option>
                                    <option value="NT">Northwest Territories</option>
                                    <option value="NS">Nova Scotia</option>
                                    <option value="NU">Nunavut</option>
                                    <option value="ON">Ontario</option>
                                    <option value="PE">Prince Edward Island</option>
                                    <option value="QC">Quebec</option>
                                    <option value="SK">Saskatchewan</option>
                                    <option value="YT">Yukon Territory</option>
                                </optgroup>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label className="form-label">ZIP Code</label>
                            <input type="text" className="form-control" value={state.zipcode} name="zipcode" onChange={handleChange} readOnly={edit} />
                        </div>
                    </div>
                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                </form>
            </div>
        </div>


    )
}