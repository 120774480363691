import React, { useEffect, useState, Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { authFetch } from "../../auth";

class EquipmentField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            year: '',
            make_model: '',
            vin: '',
            state: '',
            plate: '',
            type: 'Truck',
            collapsed: true
        };
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }

    handleSubmit = e => {
        const uniqueID = uuidv4()
        e.preventDefault()
        authFetch(`https://fltbd.herokuapp.com/api/equipment/${this.state.type.toLowerCase()}s`, {
            method: 'PUT',
            body: JSON.stringify({
                year: this.state.year,
                make_model: this.state.make_model,
                vin: this.state.vin,
                state: this.state.state,
                plate: this.state.plate,
                equipment_id: uniqueID


            }),
            headers: { 'Content-Type': 'application/json' },
        })
        if (this.state.type === "Truck") {
            this.props.parentNewTruck({
                year: this.state.year,
                make_model: this.state.make_model,
                vin: this.state.vin,
                state: this.state.state,
                plate: this.state.plate,
                equipment_id: uniqueID
            })
        }
        else {
            this.props.parentNewTrailer({
                year: this.state.year,
                make_model: this.state.make_model,
                vin: this.state.vin,
                state: this.state.state,
                plate: this.state.plate,
                equipment_id: uniqueID
            })
        }

    }


    render() {
        return (

            <div class="card">
                <div class="card-header" data-toggle="collapse" 
            data-target="#addEquipment" onClick={() => this.setState({collapsed: !this.state.collapsed})} style={{cursor: "pointer"}}>
                    <h3 class="card-title">Add New Equipment</h3>
                    <div class="edit-button">
                    {this.state.collapsed ?
                    <button class="btn btn-success" value="true" name="collapsed" type="button" data-toggle="collapse" data-target="#addEquipment" aria-expanded="false" aria-controls="collapseExample" onClick={() => this.setState({collapsed: false})}>
                        +
                    </button>
                    :
                    <button class="btn btn-danger" value="false" name="collapsed" type="button" data-toggle="collapse" data-target="#addEquipment" aria-expanded="false" aria-controls="collapseExample" onClick={() => this.setState({collapsed: true})}>
                    -
                    </button>
                    }
                </div>
                </div>
                <div class="card-body collapse" id="addEquipment">
                    <form onSubmit={this.handleSubmit}>
                        <div class="form-row">
                            <div class="col-md-2 mb-3">
                                <label style={{ "marginBottom": 0 }} class="form-label">Type</label>
                                <br />
                           

                                <select name="type" class="form-select" defaultValue={this.state.type} onChange={this.onChange}>
                                    <option value="Truck">Truck</option>
                                    <option value="Trailer">Trailer</option>

                                </select>
                            </div>
                            <div class="col-md-1 mb-3">
                                <label style={{ "marginBottom": 0 }} class="form-label">Year</label>
                                <br />
                   

                                <input type="text" class="form-control" placeholder="YYYY" value={this.state.year} name="year" onChange={this.onChange} required />
                            </div>
                            <div class="col-md-9 mb-3">
                                <label for="inputMakeModel" class="form-label" style={{ "marginBottom": 0 }}>Make & Model</label>
                                {this.state.type === "Truck" ? 
                                    <small>Example: Freightliner Cascadia, Dodge 3500</small>
                                    :
                                    <small>Example: Great Dane Flatbed, PJ Trailers Hotshot</small>
                                }
                                <input type="text" class="form-control" placeholder="Enter Make & Model" value={this.state.make_model} name="make_model" onChange={this.onChange} />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-1 mb-3">
                                <label class="form-label">State</label>
                                <input type="text" class="form-control" placeholder="State" value={this.state.state} name="state" onChange={this.onChange} required />
                            </div>
                            <div class="col-md-2 mb-3">
                                <label class="form-label">Plate</label>
                                <input type="text" class="form-control" placeholder="License Plate Number" value={this.state.plate} name="plate" onChange={this.onChange} />

                            </div>
                            <div class="col-md-9 mb-3">
                                <label for="inputVIN" class="form-label">VIN</label>
                                <div class="input-group">

                                    <input type="text"
                                        class="form-control"
                                        id="inputVIN"
                                        placeholder="Vehicle Identification Number"
                                        value={this.state.vin}
                                        name="vin"
                                        onChange={this.onChange}
                                        required />
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-success float-right" style={{ marginTop: "10px" }}>Create {this.state.type}</button>

                    </form>
                </div>

            </div>
        )
    }
}
export default withRouter(EquipmentField)
