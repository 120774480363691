import React, { useMemo, useState, useEffect } from "react";
import LocationsHeader from './LocationsHeader'
import LocationsTable from "./LocationsTable";
import LocationsField from "./LocationsField";
import Modal from 'react-bootstrap/Modal';
import LoadingModal from "../Modals/LoadingModal";

export default function LocationsContainer(props) {

    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(true)
    const [locations, setLocations] = useState([])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleNewLocation = (NewLocation) => {
        const dataCopy = [...locations]
        setLocations([...dataCopy, NewLocation])
        props.updatedLocationsList([...dataCopy, NewLocation])
    }
    const handleRemoveLocation = (RemoveLocation) => {
        const dataCopy = [RemoveLocation.data]
        
        dataCopy[0].splice(RemoveLocation.row.index, 1)
        setLocations(dataCopy[0])
        props.updatedLocationsList(dataCopy[0])
    }

    const handleRemoveTrailer = (removeTrailer) => {
        const dataCopy = [...locations];
        dataCopy.splice(removeTrailer.row.index, 1)
       
        
        console.log(dataCopy)

    }
    useEffect(() => {
        if(props && props.locations) {
            setLocations(props.locations)
            setShow(false)
            setLoading(false)
        }
    }, [props.locations])
    useEffect(() => {
        document.title = props.title || "";
      }, [props.title]);
    
    return (
        <>
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <LocationsHeader />
            <LocationsField parentNewLocation={handleNewLocation} />
            <LocationsTable locationsData={locations} parentRemoveLocation={handleRemoveLocation} />
        </div>
        <Modal show={show} size="sm" centered>

        <LoadingModal />

     </Modal>
 </>
    )
}

