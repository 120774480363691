import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { authFetch } from '../../auth';


export default function DashboardRecentCarriers(props) {
  const [loaded, setLoaded] = useState(false)
  const [recentCarriers, setRecentCarriers] = useState()

  useEffect(() => {
    if (props && props.recentCarriers) {
      const sortCarriers = props.recentCarriers.sort((a, b) => a.id < b.id ? 1 : -1)
      const recent = sortCarriers.slice(0, 5)
      setRecentCarriers(recent)
      setTimeout(() => {
        setLoaded(true)
      }, 500)

    }
  }, [props.recentCarriers])
  return (



    <div>

      <div class="card-header">
        <h3 class="card-title">Recent Carriers</h3>
      </div>
      <div class="card-table table-responsive">
        {!loaded ?
          <table class="table table-vcenter color-white">
            <thead>
              <tr style={{borderTop: "1px solid black"}}>
              <th style={{borderBottom: "1px solid black", color:"black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0"}} class="placeholder-glow"><span class="placeholder col-12"></span></th>
              </tr>
            </thead>
            <tr class="placeholder-glow">
              <td><span class="placeholder col-12"></span></td>
         
            </tr>
            <tr class="placeholder-glow">
              <td><span class="placeholder col-12"></span></td>
              
            </tr>
            <tr class="placeholder-glow">
              <td><span class="placeholder col-12"></span></td>
             
            </tr>
            <tr class="placeholder-glow">
              <td><span class="placeholder col-12"></span></td>
              
            </tr>
            <tr class="placeholder-glow">
              <td><span class="placeholder col-12"></span></td>
              
            </tr>
          </table>

          :
          <table class="table table-vcenter color-white">
            <thead>
              <tr style={{borderTop: "1px solid black"}}>
              <th style={{borderBottom: "1px solid black", color:"black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0"}}>Carrier Name</th>
                <th style={{borderBottom: "1px solid black", color:"black", fontWeight: "bold", textTransform: "none", fontSize: ".75rem", letterSpacing: "0"}}></th>
              </tr>
            </thead>
            {recentCarriers && recentCarriers.map(carrier =>

              <tr key={carrier.id}>

                <td class="align-middle">{carrier.carrier_name}</td>
                <td>
                  <Link to={`carrier/${carrier.carrier_id}`}>
                <button class="btn btn-icon btn-primary"><svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="2"></circle><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>                        </button>
                </Link>
                </td>
              </tr>

            )}
          </table>
        }
      </div>

    </div>


  )


}
