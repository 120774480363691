import React, { useEffect, useState } from "react";
import Select from 'react-select'
import DatePicker from "react-datepicker"

const CustomerInfo = (props) => {
    //below is required to so you don't get properties undefined for props.customers 
    const initialState = () => {
        return (props)
    }
    const [state, setState] = useState(initialState);

    function customerChange(e) {

        setState(prevState => {
            return { ...prevState, ...e };
        });


        props.handleCustomerChange(e)
    }
    function handleTerms(e) {
        setState({ ...state, terms: e.value })

        props.updateCustomer({ ...state, terms: e.value })
    }
    function handleDateChange(date, name) {

        props.handleDateChange(date, name)
    }

    const statusOptions = [
        { value: 'Net 60', label: 'Net 60' },
        { value: 'Net 45', label: 'Net 45' },
        { value: 'Net 30', label: 'Net 30' },
        { value: 'Net 15', label: 'Net 15' },
        { value: 'Net 10', label: 'Net 10' },
        { value: 'Due On Receipt', label: 'Due On Receipt' },
    ]

    useEffect(() => {
        if (props && props.customers) {
            const options = props.customers.map(d => ({
                "id": d.id,
                "customer_name": d.customer_name,
                "customer_id": d.customer_id,
                "billing_address": d.billing_address,
                "billing_email": d.billing_email,
                "billing_phone_number": d.billing_phone_number,
                "terms": d.terms,
            }))
            setState({ customers: options })
        }

    }, [props.customers])

    useEffect(() => {
        if (props && props.customer_name) {
            const customer = props.customers.filter(customer => customer.customer_name == props.customer_name)

            setState(prevState => {
                return { ...prevState, ...customer[0] };
            });
           
        }

    }, [props.customer_name])


    return (
        <>
            <div className="row">
                <div class="col-6">
                    <div class="mb-3">
                        <label class="form-label">Customer Name</label>
                        <Select
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.customer_name}

                            options={state.customers}
                            placeholder={props.customer_name}
                            name="customer_name" onChange={(e) => customerChange(e)} />
                    </div>
                   
                </div>
                <div class="col-6">
                    <label className="form-label">Billing Address</label>
                    <textarea className="form-control" type="text" value={state.billing_address} />
                    <label className="form-label mt-3">Billing Email</label>
                    <input className="form-control" type="text" value={state.billing_email} />
                </div>
            </div>
            <div className="row mt-3">
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">Terms</label>
                        <Select options={statusOptions}
                            placeholder={state.terms}
                            value={state.terms}
                            name="customer_name"
                            onChange={(e) => handleTerms(e)}
                        />
                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">Invoice Date</label>

                        <DatePicker
                            selected={props.date}
                            name="date"
                            className="form-control"
                            dateFormat="MM/dd/yyyy"
                            onChange={(date) => handleDateChange(date, "date")} />

                    </div>
                </div>
                <div class="col-4">
                    <div class="mb-3">
                        <label class="form-label">Due Date</label>

                        <DatePicker
                            selected={props.due_date}
                            name="due_date"
                            className="form-control"
                            dateFormat="MM/dd/yyyy "
                            onChange={(date) => handleDateChange(date, "due_date")}
                        />
                    </div>
                </div>

            </div>
        </>
    )
}

export default CustomerInfo