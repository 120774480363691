import React, { Component, setState } from 'react';


export default class ContractBillingDetails extends Component {
    constructor(props) {
        super(props);
        this.state={
            showComponent: false
        }
    }
    factorChange = () => {
        this.setState({ showComponent: !this.state.showComponent });
        this.props.ParentCarrierFactor(!this.state.showComponent)
        }
    
    carrierMailAddressChange = e => {
        this.props.ParentCarrierMailAddress(e.target.value)
    }
    carrierFederalIDChange = e => {
        this.props.ParentCarrierFederalID(e.target.value)
    }
    carrierFactoringCompanyChange = e => {
      this.props.ParentCarrierFactoringCompany(e.target.value)
    }

    handleChange = e => {
      this.props.handleChange({[e.target.name]: e.target.value})
    }

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;
    
        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => { 
          return validateNewInput[k] === true })) {
            if (this.props.carrier_federal_id !== userInput.carrier_federal_id || this.props.billing_address != userInput.billing_address) { // only update store of something changed
              return 'success'  // Update store here (this is just an example, in reality you will do it via redux or flux)
            }
    
            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }
    
        return isDataValid;
      }
      validationCheck() {
        if (!this._validateOnDemand)
          return;
    
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
    
        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
      }
      _validateData(data) {
        return  {
          carrier_federal_idVal: (data.carrier_federal_id != 0), // required: anything besides N/A
          billing_addressVal: (data.billing_address != 0), // required: anything besides N/A
        }
      }
    
      _validationErrors(val) {
        const errMsgs = {
          carrier_federal_idValMsg: val.carrier_federal_idVal ? '' : 'Federal ID is Required',
          billing_addressValMsg: val.billing_addressVal ? '' : 'Mailing Address Is Required',
          
        }
        return errMsgs;
      }
    
      _grabUserInput() {
        return {
          carrier_federal_id: this.refs.carrier_federal_id.value,
          billing_address: this.refs.billing_address.value,
        
          
        };
      }
    render() {
    let notValidClasses = {};

    if (typeof this.props.billing_addressVal == 'undefined' || this.props.billing_address) {
      notValidClasses.billing_addressCls = 'no-error col-md-8 error-msg-red';
    }
    else {
       notValidClasses.billing_addressCls = 'has-error col-md-8 error-msg-red';
       notValidClasses.billing_addressValGrpCls = 'val-err-tooltip error-msg-red';
    }
    if (typeof this.props.carrier_federal_idVal == 'undefined' || this.props.carrier_federal_id) {
        notValidClasses.carrier_federal_idCls = 'no-error col-md-8';
      }
      else {
         notValidClasses.carrier_federal_idCls = 'has-error col-md-8';
         notValidClasses.carrier_federal_idValGrpCls = 'val-err-tooltip';
      }
      return (
        <div class="contract-margin">
            <div className="card-header">
                <h3 class="card-title">Carrier Billing Details</h3>
                <div className="edit-button">
                    {this.props.details.company_name}
                </div>
              </div>
            <div class="card-body">
               
                <label class="form-label" form="inputCarrierName">Carrier Mailing Address Address </label>
                <textarea ref="billing_address" type="text" class="form-control mt-3" name="billing_address" value={this.props.details.billing_address} onChange={this.carrierMailAddressChange} />
                <div className="error-msg-red">{this.state.billing_addressValMsg}</div>
    
                <label class="form-label mt-3" form="inputCarrierName">Federal ID</label>
                <input ref="carrier_federal_id" type="text" value={this.props.details.federal_id} class="form-control" onChange={this.carrierFederalIDChange} />
                <div className="error-msg-red">{this.state.carrier_federal_idValMsg}</div>

                <label class="form-label mt-3">Does the Carrier Factor Their Receivables?</label>
            <div class="form-selectgroup-boxes row mb-3">
              <div class="col-lg-6">
                <label class="form-selectgroup-item">
                  <input type="radio" name="report-type" checked={!this.state.showComponent} class="form-selectgroup-input" onChange={this.factorChange} />
                  <span class="form-selectgroup-label d-flex align-items-center p-3">
                    <span class="me-3">
                      <span class="form-selectgroup-check"></span>
                    </span>
                    <span class="form-selectgroup-label-content">
                      <span class="form-selectgroup-title strong mb-1">No</span>

                    </span>
                  </span>
                </label>
              </div>
              <div class="col-lg-6">
                <label class="form-selectgroup-item">
                  <input type="radio" name="report-type" checked={this.state.showComponent} class="form-selectgroup-input" onChange={this.factorChange} />
                  <span class="form-selectgroup-label d-flex align-items-center p-3">
                    <span class="me-3">
                      <span class="form-selectgroup-check"></span>
                    </span>
                    <span class="form-selectgroup-label-content">
                      <span class="form-selectgroup-title strong mb-1">Yes</span>

                    </span>
                  </span>
                </label>

              </div>
              <div class="mb-3">

                {this.state.showComponent ? <div>
                  <label class="form-label mt-3">Factoring Company</label>
                  <input type="text" class="form-control" name="factoring_company" value={this.props.details.factoring_name} onChange={this.carrierFactoringCompanyChange} />
                </div> : null}
              </div>
              <div>

              </div>
             </div>


            </div>
        </div>
    )
    }
}