import React, { Component, setState } from 'react';
import DOMPurify from 'dompurify';


export default class ContractBrokerContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carrier_agreed: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => {
            return validateNewInput[k] === true
        })) {
            if (this.state.carrier_agreed != userInput.carrier_agreed) { // only update store of something changed
                return 'success'  // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }

        return isDataValid;
    }
    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }
    _validateData(data) {
        return {
            carrier_agreedVal: (data.carrier_agreed == "true"), // required: anything besides N/A

        }
    }

    _validationErrors(val) {
        const errMsgs = {
            carrier_agreedValMsg: val.carrier_agreedVal ? '' : 'Must Agree To Terms and Conditions',

        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            carrier_agreed: this.refs.carrier_agreed.value,



        };
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }
    

    render() {
        const contract = this.props.details.company_contract
        
        /* const contract_filled = contract.replace("{{company_name}}", this.props.details.carrier_name) */
        const contractStyle = {
            color: "black",
            width: "100%",
            backgroundColor: "white",
            maxHeight: "300px",
            overflowY: 'auto',
            border: '1px solid black'
        }
        let notValidClasses = {};

        if (typeof this.state.carrier_agreedVal == false || this.state.carrier_agreedVal) {
            notValidClasses.carrier_agreedCls = 'no-error col-md-8';
        }
        else {
            notValidClasses.carrier_agreedCls = 'has-error col-md-8';
            notValidClasses.carrier_agreedValGrpCls = 'val-err-tooltip';
        }

        return (
            <div class="contract-margin">
                <div className="card-header">
                <h3 class="card-title">View Broker's Contract Below</h3>
                <div className="edit-button">
                    {this.props.details.company_name}
                </div>
              </div>
                <div class="card-body">

                    <div style={contractStyle} >
                        <div className="preview" dangerouslySetInnerHTML={this.createMarkup(contract)}></div>

                    </div>
                   
                    
                    <div class="form-check">
                        
                        <input ref="carrier_agreed" name="carrier_agreed" id="carrierAgreement" class="form-check-input" type="checkbox" value={this.state.carrier_agreed} onClick={this.handleInputChange} />
                        <label class="form-check-label mt-3" for="carrierAgreement" >
                            
                            <h3><b><u>I Accept</u></b></h3>
                            
                        </label>
                        <br />
                        <p style={{fontWeight: "bold"}}>
                        I, {this.props.details.signed_by}, agree to sign this Agreement Electornically. You agree your electronic signature is the legal equivalent of your manual signature on this Agreement. 
                        <br />By selecting "I Accept" you consent to be legally bound by this Agreements terms and conditions. 
                        </p>

                    </div>
                        
                   
                    

         
  
                    <div className="d-flex justify-content-center error-msg-red">{this.state.carrier_agreedValMsg}</div>


                </div>
            </div>
        )
    }
}