import React, { useMemo, useState, useEffect, Component } from "react";
import { v4 as uuidv4 } from "uuid";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useHistory} from 'react-router-dom'

class LocationsAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            location_id: uuidv4(),
            location_name: "",
            branch_number: "",
            street_address: "",
            city: "",
            state: "",
            zipcode: "",
            phone_number: "",
            contact_name: "",
    
        };
    }
    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value

        })
    }
    handleSubmit = e => {
        const getToken = localStorage.getItem('REACT_TOKEN_AUTH_KEY')
        const strippedToken = getToken.replace('access_token', '')
        const strippedToken2 = strippedToken.replace('""', '')
        const strippedToken3 = strippedToken2.replace('{:"','')
        const strippedToken4 = strippedToken3.replace('"}', '')
        e.preventDefault();
        fetch('https://fltbd.herokuapp.com/api/locations', {
            method: 'PUT',
            body: JSON.stringify({
                location_id: this.state.location_id,
                location_name: this.state.location_name,
                branch_number: this.state.branch_number,
                contact_name: this.state.contact_name,
                phone_number: this.state.phone_number,
                street_address: this.state.street_address,
                city: this.state.city,
                state: this.state.state,
                zipcode: this.state.zipcode,
            }),
            headers: { 'Content-Type': 'application/json',
                        'Authorization':  'Bearer ' + strippedToken4
                    }
        })
            .then(res => res.json())
            .then(data => this.setState({ location_id: data.location_id }))
            this.props.history.push('/locations')

    };
    
    render() {
        

        return (
            <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                
                <div class="d-sm-flex align-items-center justify-content-between">
                    <div>
                        <h1>Add New Location</h1>
                    </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Location Details</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                            <label for="inputCustomerName">Location Name</label>
                            <input type="text" class="form-control" placeholder="Enter Company Name" value={this.state.location_name} name="location_name" onChange={this.onChange} required />
                            </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputContactName">Contact Name</label>
                                <input type="text" class="form-control" id="inputContactName" placeholder="Contact Name" value={this.state.contact_name} name="contact_name" onChange={this.onChange} />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPhoneNumber">Phone Number</label>
                                <input type="tel" class="form-control" id="inputPhoneNumber" placeholder="555-555-5555" value={this.state.phone_number} name="phone_number" onChange={this.onChange} />
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Location Details</h3>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="inputAddress">Address</label>
                                <input type="text" class="form-control" id="inputAddress" placeholder="Enter Street Address, example: 1234 Main St" value={this.state.street_address} name="street_address" onChange={this.onChange} />
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputCity">City</label>
                                    <input type="text" class="form-control" id="inputCity" placeholder="Enter City" value={this.state.city} name="city" onChange={this.onChange} />
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState">State</label>
                                    <select id="inputState" class="form-control" value={this.state.state} name="state" onChange={this.onChange}>
                                        <option selected >Choose...</option>
                                        <option>CA</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputZip">ZIP Code</label>
                                    <input type="text" class="form-control" id="inputZip" placeholder="Enter ZIP Code" value={this.state.zipcode} name="zipcode" onChange={this.onChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-success float-right" style={{marginTop: "10px"}}>Create Location</button>
                </form>
            </div>
        )
    }
}


export default LocationsAdd

