import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";

export class CarriersHeader extends Component {
    render() {
        return (
            <div>

                <div class="d-sm-flex align-items-center justify-content-between">
                    <div>
                        
                        <h1>Carriers</h1>
                    </div>
                
                </div>


            </div>
        )
    }
}

export default CarriersHeader


