import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { authFetch } from '../../../auth';
import LoadsDelete from './LoadsDelete';

const LoadsDeleteList = (props) => {

    const initialState = () => {
        return (props)
    }
    const history = useHistory();

    const [state, setState] = useState(initialState);
    const [showModal, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setState(props);
    }
    
    const handleRemoveLoads = () => {
        
        props.parentDeleteSelected()
        setShow(false)
        
    }


    return (
        <>
                <Link>
                    <Button className="btn btn-danger" onClick={handleShow}>
                        Delete Selected
                    </Button>
                </Link>

                <Modal
                    show={showModal}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body class="modal-body">
                            <div>Are you sure you want to delete these load(s)?</div>
                            <br />
                            <div>
                                {props.selectedFlatRows.map(d => 
                                    <li>{d.original.load_number}</li>
                                    )}
                            </div>
                           


                        </Modal.Body>
                        <Modal.Footer>
                         <Button variant="primary" onClick={handleClose}>
                                Don't Delete
                            </Button>
                            <Button variant="danger" onClick={handleRemoveLoads} >
                                Delete
                            </Button>
                            
                            
                        </Modal.Footer>
                    </form>
                </Modal>


        </>
    )
}

export default LoadsDeleteList