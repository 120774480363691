import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from '../../auth';


export default function DriversAlerts(props) {
    const [state, setState] = useState('')
    const [licenseAlert, setLicenseAlert] = useState()
    const [medicalAlert, setMedicalAlert] = useState()
    const [twicAlert, setTwicAlert] = useState()
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }
    const alertChange = (e) => {
        props.parentAlertDetails(e.target.name)
    }
    const licenseAlertChange = (e) => {
        setLicenseAlert(!licenseAlert)
        props.parentLicenseAlert(!licenseAlert)
    }
    const twicAlertChange = (e) => {
        setTwicAlert(!twicAlert)
        props.parentTwicAlert(!twicAlert)
    }
    const medicalAlertChange = (e) => {
        setMedicalAlert(!medicalAlert)
        props.parentMedicalAlert(!medicalAlert)
    }
    useEffect(() => {
        setState(props)
        setLicenseAlert(props.license_alert)
        setTwicAlert(props.twic_card_alert)
        setMedicalAlert(props.medical_alert)
    })
    const handleSubmit = e => {
        e.preventDefault();
        console.log(state.license_alert)
        authFetch(`https://fltbd.herokuapp.com/api/drivers/${props.driver_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                license_alert: state.license_alert,
                twic_card_alert: state.twic_card_alert,
                medical_alert: state.medical_alert,

            }),
            headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit)
    }
        return (
            <div class="card">
                <div class="card-header" style={{ border: "none" }}>
                    <h3 class="card-title">Alerts</h3>
                    <div class="edit-button">
                        <a href="#" onClick={changeEdit}> [edit]</a>
                    </div>
                </div>
                <div class="card-body">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" checked={licenseAlert} name="license_alert" onChange={licenseAlertChange} disabled={edit} />
                                <span class="form-check-label">License Alert:

                                    {!state.license_alert ?
                                        <span class="text-danger"><b> OFF</b></span>
                                        :
                                        <span class="text-success"><b> ON</b></span>
                                    }</span>
                            </label>

                        </div>
                        <div>
                            <label class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" checked={medicalAlert} name="medical_alert" onChange={medicalAlertChange} disabled={edit} />
                                <span class="form-check-label">Medical Alert:

                                    {!state.medical_alert ?
                                        <span class="text-danger"><b> OFF</b></span>
                                        :
                                        <span class="text-success"><b> ON</b></span>
                                    }</span>
                            </label>
                        </div>
                        <div>
                            <label class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" checked={twicAlert} name="twic_card_alert" onChange={twicAlertChange} disabled={edit} />
                                <span class="form-check-label">TWIC Alert:

                                    {!state.twic_card_alert ?
                                        <span class="text-danger"><b> OFF</b></span>
                                        :
                                        <span class="text-success"><b> ON</b></span>
                                    }</span>
                            </label>
                        </div>
                        {!edit ?
                            <div>
                                <br />
                                <button type="submit" className="btn btn-success float-right">Make Changes</button>
                            </div>
                            : null}
                    </form>
                </div>
            </div>
        )

    }