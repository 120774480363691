import React, { useEffect, useState, Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { authFetch } from "../../auth";

export default function DriversField(props) {
    const [state, setState] = useState('')
    const [newDriver, setNewDriver] = useState('')
    const [collapse, setCollapse] = useState(true)
    const handleChange = e => {
        setNewDriver({
            ...newDriver,
            [e.target.name]: e.target.value
        })
    }
    
    const handleSubmit = e => {
        e.preventDefault();
        const uniqueID = uuidv4()
        authFetch(`https://fltbd.herokuapp.com/api/drivers`, {
            method: 'PUT',
            body: JSON.stringify({
                driver_id: uniqueID,
                first_name: newDriver.first_name,
                last_name: newDriver.last_name,
                dob: newDriver.dob,
                license_number: newDriver.license_number,
                license_state: newDriver.license_state,
                license_exp: newDriver.license_exp,
                
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentNewDriver({
            driver_id: uniqueID,
            first_name: newDriver.first_name,
            last_name: newDriver.last_name,
            dob: newDriver.dob,
            license_number: newDriver.license_number,
            license_state: newDriver.license_state,
            license_exp: newDriver.license_exp,
        })
        setNewDriver({
            first_name: '',
            last_name: '',
            dob: '',
            license_number: '',
            license_state: '',
            license_exp: '',
        })


    }

    return (
        <div class="card" >
            <div class="card-header" data-toggle="collapse" 
        data-target="#addDriver" onClick={() => setCollapse(!collapse)} style={{cursor: "pointer"}}>
                <h3 class="card-title">Add New Driver</h3>
                <div class="edit-button">
                    {collapse ?
                    <button class="btn btn-success" type="button" data-toggle="collapse" data-target="#addDriver" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapse(false)}>
                        +
                    </button>
                    :
                    <button class="btn btn-danger" value="false" name="collapsed" type="button" data-toggle="collapse" data-target="#addDriver" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapse(true)}>
                    -
                    </button>
                    }
                </div>
            </div>
            <div class="card-body collapse" id="addDriver">
                <form onSubmit={handleSubmit}>
                    <div class="form-row">
                        <div class="col-md-2 mb-3">
                            <label for="inputFirstName" class="form-label">First Name</label>
                            <input type="text" class="form-control" placeholder="First Name" value={newDriver.first_name} name="first_name" onChange={handleChange}  />
                        </div>
                        <div class="col-md-1 mb-3">
                            <label for="inputMiddleInitial" class="form-label">Middle Initial</label>
                            <input type="text" class="form-control" placeholder="" value={newDriver.middle_initial} name="middle_initial" onChange={handleChange}  />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="inputLastName" class="form-label">Last Name</label>
                            <input type="text" class="form-control" placeholder="Last Name" value={newDriver.last_name} name="last_name" onChange={handleChange} />
                        </div>
                        <div class="col-md-3 mb-3" >
                            <label for="imputDOB" class="form-label">Date of Birth</label>
                            <input type="text" class="form-control" placeholder="mm/dd/yyyy" value={newDriver.dob} name="dob" onChange={handleChange} />
                        </div>
                    </div>
                    <div class="form-row">

                        <div class="col-md-3 mb-3">
                            <label for="inputLicenseNumber" class="form-label">License Number</label>
                            <input type="text" class="form-control" placeholder="License Number" value={newDriver.license_number} name="license_number"  onChange={handleChange} />
                        </div>
                        <div class="col-md-1 mb-3">
                            <label for="inputLicenseNumber" class="form-label">License State</label>
                            <input type="text" class="form-control" placeholder="License State" name="license_state" value={newDriver.license_state} onChange={handleChange} />
                        </div>
                        <div class="col-md-1 mb-3">
                            <label for="inputLicenseNumber" class="form-label">License Exp.</label>
                            <input type="text" class="form-control" placeholder="mm/dd/yyyy" name="license_exp" value={newDriver.license_exp} onChange={handleChange} />
                        </div>
                    </div>
                    <button class="btn btn-success float-right">Create Driver</button>
                </form>
            </div>
        </div>
    )

}

