import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { useAuth, logout, authFetch } from '../../auth';
import { Modal, Button } from "react-bootstrap";
import UserDelete from './Modals/UserDelete';

export default function SettingsUserItem(props) {
    const history = useHistory();
    const [state, setState] = useState([])
    const userID = props.match.params.user_id
    const [edit, setEdit] = useState("False")
    const [role, setRole] = useState()
    const [loading, setLoading] = useState(false)

    const deleteEmployee = (Employee) => {
        props.deleteEmployee(Employee)
    }


    const changeEdit = () => {
        setEdit(!edit);
    }
    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/manage-users/${userID}`, {
            method: 'PATCH',
            body: JSON.stringify({
                first_name: state.first_name,
                last_name: state.last_name,
                phone_number: state.phone_number,
                roles: state.roles,
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        setLoading(true);
        setTimeout(() => {
            props.updateEmployee({user_id: state.user_id,
                first_name: state.first_name,
                last_name: state.last_name,
                phone_number: state.phone_number,
                roles: state.roles})

            setLoading(false);
            setEdit(!edit);
        }, 500);
        
    }
    const handleDelete = e => {
        e.preventDefault();
        
        setEdit(!edit);


    }

    useEffect(() => {
        authFetch(`https://fltbd.herokuapp.com/api/manage-users/${userID}`).then(response => {
            if (response.status === 500) {
                //history.go(0)
                logout()
                console.log(response.status)
                //return null
            }
            return response.json()
        })
            .then(response => {
                if (response) {
                    setState(response)
                    setRole(response.roles)
                }
            })
    }, [])
    const changeRole = (e) => {
        setState({ ...state, roles: e.target.value })
        setRole(e.target.value)
    }
    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Add User</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }
    return (
        <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Manage User</h3>

                <div class="edit-button">

                    <a href="#" onClick={changeEdit}> [edit]</a>

                </div>
            </div>
            <div class="card-body">


                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label">First Name</label>
                        <input type="text" class="form-control" value={state.first_name} name="first_name" required readOnly={edit} onChange={(e) => onChange(e)} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Last Name</label>
                        <input type="text" class="form-control" value={state.last_name} name="last_name" required readOnly={edit} onChange={(e) => onChange(e)} />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Email</label>
                        <input type="text" class="form-control" value={state.email} name="email" required readOnly="True" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label">Phone Number</label>
                        <input type="text" class="form-control" value={state.phone_number} name="phone_number" required readOnly={edit} onChange={(e) => onChange(e)} />
                    </div>
                </div>
                <label class="form-label">Role</label>
                            <div class="form-selectgroup" onChange={changeRole}>
                                <label class="form-selectgroup-item">
                                    <input type="radio" name="icons" value='admin' class="form-selectgroup-input" checked={state.roles === 'admin'}  disabled={edit} />
                                    <span class="form-selectgroup-label">
                                        Admin</span>
                                </label>
                                <label class="form-selectgroup-item">
                                    <input type="radio" name="icons" value='team leader' class="form-selectgroup-input" checked={state.roles === 'team leader'} disabled={edit} />
                                    <span class="form-selectgroup-label">
                                        Team Leader</span>
                                </label>
                                <label class="form-selectgroup-item">
                                    <input type="radio" name="icons" value='agent' class="form-selectgroup-input" checked={state.roles === 'agent'} disabled={edit} />
                                    <span class="form-selectgroup-label">
                                        Agent</span>
                                </label>

                            </div>
                            <div class="mt-3">
                                {(() => {
                                    if (role === 'admin') {
                                        return (
                                            <div class="alert alert-danger" role="alert">
                                                <h4 class="alert-heading">Admin Role</h4>
                                                <p>This should be used to give the highest powers to the highest ranking members of your company. Give this role with extreme caution.</p>

                                            </div>
                                        )
                                    } else if (role === 'team leader') {
                                        return (
                                            <div class="alert alert-info" role="alert">
                                                <h4 class="alert-heading">Team Leader Role</h4>
                                                <p>Team leader role description.</p>

                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div class="alert alert-success" role="alert">
                                                <h4 class="alert-heading">Agent Role</h4>
                                                <p>This is the default role, it is also the lowest ranking role. You are safe to give this to new agents/brokers.</p>

                                            </div>
                                        )
                                    }
                                })()}
                            </div>

                {!edit ?
                    <div>
                        <UserDelete deleteEmployee={deleteEmployee} userID={userID} />
                        <form onSubmit={handleSubmit}>
                            {loading ?
                            <button type="submit" className="btn btn-success float-right" disabled>Saving...</button>
                            :
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                            }
                        </form>
                    </div>
                    : null}
            </div>

        </div>
    )
}