import React, { useEffect, useState, Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    withRouter,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { authFetch } from "../../auth";
import { Alert } from "react-bootstrap";


function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}


export default function CustomersField(props) {
    const [state, setState] = useState('')
    const [newCustomer, setNewCustomer] = useState('')
    const [collapse, setCollapse] = useState(true)
    const [showingAlert, setShowingAlert] = useState(false);


    const onChange = e => {
        setNewCustomer({
            ...newCustomer,
            [e.target.name]: e.target.value

        })
    }
    const handleInput = e => {

        setNewCustomer({
            ...newCustomer,
            [e.target.name]: formatPhoneNumber(e.target.value)

        })
    };
    const handleSubmit = e => {

        e.preventDefault();
        const uniqueID = uuidv4()
        authFetch(`https://fltbd.herokuapp.com/api/customers`, {
            method: 'PUT',
            body: JSON.stringify({
                customer_id: uniqueID,
                customer_name: newCustomer.customer_name,
                contact_name: newCustomer.contact_name,
                phone_number: newCustomer.phone_number,
                email: newCustomer.email,
                billing_address: newCustomer.billing_address,


            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(r => r.json())
            .then(data => {
                if (data.success) {
                    onShowAlert()
                    props.parentNewCustomer({
                        customer_id: uniqueID,
                        customer_name: newCustomer.customer_name,
                        contact_name: newCustomer.contact_name,
                        phone_number: newCustomer.phone_number,
                        email: newCustomer.email,
                        billing_address: newCustomer.billing_address,
                        billing_city: newCustomer.billing_city,
                        billing_state: newCustomer.billing_state,
                        billing_zipcode: newCustomer.billing_zipcode,
                    })
                    setNewCustomer({
                        customer_name: '',
                        contact_name: '',
                        phone_number: '',
                        email: '',
                        billing_address: '',
                        billing_city: '',
                        billing_state: '',
                        billing_zipcode: '',
                    })
                    
                }
            })
    }
    const onShowAlert = () => {
        setShowingAlert(true,
            setTimeout(() => {               
                setShowingAlert(false)
            }, 2000)
        );
    }


    return (
        <div class="card" >
            <div class="card-header" data-toggle="collapse"
                data-target="#addCustomer" onClick={() => setCollapse(!collapse)} style={{ cursor: "pointer" }}>
                <h3 class="card-title">Add New Customer</h3>
                <div class="edit-button">
                    {collapse ?
                        <button class="btn btn-success" type="button" data-toggle="collapse" data-target="#addCustomer" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapse(false)}>
                            +
                        </button>
                        :
                        <button class="btn btn-danger" type="button" data-toggle="collapse" data-target="#addCustomer" aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapse(true)}>
                            -
                        </button>
                    }
                </div>
            </div>
            <div class="card-body collapse" id="addCustomer">
                <Alert variant="success" show={showingAlert} >
                    Customer Successfully Added.
                </Alert>
                <form onSubmit={handleSubmit}>
                    <div class="form-group">
                        <label for="inputCustomerName" class="form-label">Customer Name</label>
                        <input type="text" class="form-control" placeholder="Enter Customer Name" value={newCustomer.customer_name} name="customer_name" onChange={onChange} required/>
                    </div>

                    <div class="form-row">
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Contact Name</label>
                            <input type="text" class="form-control" placeholder="Contact Name" value={newCustomer.contact_name} name="contact_name" onChange={onChange} required />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="validationTooltip02" class="form-label">Email</label>
                            <input type="text" class="form-control" id="validationTooltip02" placeholder="Enter Email" value={newCustomer.email} name="email" onChange={onChange} />

                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="validationTooltipUsername" class="form-label">Phone Number</label>
                            <div class="input-group">

                                <input type="text"
                                    class="form-control"
                                    id="validationTooltipUsername"
                                    placeholder="Enter Phone Number"
                                    value={newCustomer.phone_number}
                                    name="phone_number"
                                    onChange={handleInput}
                                    required />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputAddress" class="form-label">Billing Address</label>
                        <textarea class="form-control" id="inputAddress" placeholder="Enter Street Address, example: 1234 Main St" value={newCustomer.billing_address} name="billing_address" onChange={onChange} />
                    </div>


                    <button class="btn btn-success float-right" style={{ marginTop: "10px" }}>Create Customer</button>
                </form>
            </div>



        </div>

    )

}