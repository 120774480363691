import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, Link, withRouter } from "react-router-dom";
import { authFetch } from "../../auth";


export default function SettingsUploadLocations(props) {
    const [file, setFile] = useState()
    const [error, setError] = useState()


    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);

        const Upload = async () => {
            await authFetch('https://fltbd.herokuapp.com/api/upload-locations', {
                method: 'POST',
                body: formData
            }).then(resp => {
                if(resp.status === 500) {
                    setError('Something went wrong, either no file, wrong file type, or wrong format.')
                }
                
            })
        }
        Upload();
    }
    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Upload Locations Data</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.
            </div>
        </div>

    }

    return (
        <div className="card">
            <div class="card-header">
                <h3 class="card-title">Upload Locations Data</h3>
            </div>
            <div className="card-body">
                <p>Must be a CSV File.</p>
                <p>Example</p>

                <pre style={{ whiteSpace: 'nowrap' }}>
                    <code>
                        location_name,street_address,city,state,zipcode,contact_name,phone_number <br />
                        Moore Equipment Co,521 Martin Knolls Apt. 165,Kyletown,TN,85643,Kevin,(282)731-8410<br />
                        Garner-Myers Pipe,220 Edward Crest Suite 914,New Beverlyborough,WA,40101,Megan,(287)452-6422<br />
                        Fitzgerald and Hopkins Wreckage,8647 Donald Extensions,Perezbury,DC,58169,Ryan,(315)233-9857<br />
                        Odom Interiors,924 Vincent Station,West Scottville,NH,69990,Toni,+1-998-665-9535<br />
                        Super Piping,720 Anna Brook,Port Monica,MD,41373,Elizabeth,001-103-256-8956<br />
                        

                    </code>
                </pre>
                <center>
                <span class="text-danger">
                    {error}
                </span>
            </center>
                <form onSubmit={handleSubmit} className="container" enctype="multipart/form-data">
                    <div className="form-inline justify-content-center mt-5">
                        <label htmlFor="image" className="ml-sm-4 font-weight-bold mr-md-4">CSV :  </label>
                        <div className="input-group">
                            <input type="file" id="image" name="file"
                                accept="csv/*" className="file-custom" />
                        </div>
                    </div>

                    <div className="input-group justify-content-center mt-4">
                        <button type="submit" className="btn btn-md btn-primary">Upload</button>
                    </div>
                </form>
            </div>
        </div>
    )

}

