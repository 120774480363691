import React, { useState, useEffect, Component } from 'react'
import { authFetch } from '../../auth';


export default function CarriersItemCarrierDetails(props) {
    const [state, setState] = useState('')
    const [edit, setEdit] = useState("false")
    const changeEdit = () => {
        setEdit(!edit);
    }
    
    const carrierPhoneChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value

        })
        props.parentCallbackCarrierPhone(e.target.value)
    }

    const handleDetails = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value

        })
        props.parentHandleChangeDetails(e)
    }


    useEffect(() => {
        setState(props)
    })

    const handleSubmit = e => {
        e.preventDefault();
        authFetch(`https://fltbd.herokuapp.com/api/carriers/${props.carrier_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                carrier_name: state.carrier_name,
                carrier_dba: state.carrier_dba || ' ',
                physical_address: state.physical_address,
                phone_number: state.phone_number,
                email: state.email,
                
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        setEdit(!edit);
        props.updateCarrier(state)
    }
    return (
        <div class="card">

            <div class="card-header">
                <h3 class="card-title">Carrier Details</h3>

                <div class="edit-button">
                    <a href="#edit" id="edit" onClick={changeEdit}> [edit]</a>

                </div>
            </div>
            
                <div class="card-body">
                <form onSubmit={handleSubmit}>
                <div class="form-row">
                        <div class="col mb-3">
                    <label class="form-label" >Carrier Name</label>
                    <input type="text" class="form-control" value={state.carrier_name} name="carrier_name" onChange={handleDetails} readOnly={edit} />
                </div>
                </div>
                <div class="form-row">
                        <div class="col mb-3">
                    <label class="form-label" >Carrier DBA</label>
                    <input type="text" class="form-control" value={state.carrier_dba} name="carrier_dba" onChange={handleDetails} readOnly={edit} />
                </div>
                </div>
                <div class="form-row">
                        <div class="col mb-3">
                    <label class="form-label" >Carrier Physical Address </label>
                    <textarea class="form-control" value={state.physical_address} name="physical_address" onChange={handleDetails} readOnly={edit} />
                </div>
                </div>
                    <div class="form-row">
                       
                        <div class="col-md-6 mb-3">
                            <label class="form-label">MC Number</label>
                            <input type="text" class="form-control" value={state.carrier_mc} name="carrier_mc" readOnly />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">DOT Number</label>
                            <input type="text" class="form-control" value={state.carrier_dot} name="carrier_dot" readOnly />
                        </div>
                    </div>
                    <div class="form-row">
                    <div class="col-md-6 mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control" value={state.phone_number} name="phone_number" onChange={carrierPhoneChange} required readOnly={edit} />
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" value={state.email} name="email" onChange={handleDetails} required readOnly={edit} />
                        </div>
                    
                    </div>
                    {!edit ?
                        <div>
                            <br />
                            <button type="submit" className="btn btn-success float-right">Make Changes</button>
                        </div>
                        : null}
                         </form>
                </div>
           
        </div>
    )

}