import React from 'react';


import Layout from './Layout/Layout';
import Login from './Layout/Login/Login';

import { useAuth } from './auth';
import { BrowserRouter as Router, Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'



//Might be able to make some landing pages below. Have different layouts based on logged like

// {!logged ? <notLogged page/> : <Layout/> }



function App() {
  const [logged] = useAuth();
  return (
    
    <div>
      
        <Layout />
       
      </div>


  );
}

export default App;