import React, { useEffect, useState } from 'react';


export default function NotFound() {

    return (
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <div class="card card-md">
                <div class="card-body">

                    <div class="row align-items-center">
                        <div class="col-10">
                        <center>
                            <h1 style={{color:"black"}}>Error - 404</h1>
                            <div class="markdown text-muted">
                                Page Not Found
                            </div>
                        </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}