import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip,
    Modal,
    Button
} from "react-bootstrap";
import { authFetch } from '../../../auth';
import { v4 as uuidv4 } from "uuid"


const autoSendToolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        If this option is selected, we will send the rate confirmation to the carrier's email on file via our emailing system.
    </Tooltip>
);

const hideToolTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        If this option is selected, the shippers & receivers details will be hidden until the carrier signs the rate confirmation.
    </Tooltip>
);

const LoadsAccessorialFees = (props) => {

    const initialState = () => {
        return (props)
    }
    const history = useHistory();
    const [fees, setFees] = useState([
        { type: "default", quantity: '', amount: '' },
    ]);

    const [state, setState] = useState(initialState);
    const [showModal, setShow] = useState(false);


    const handleClose = () => 
        {
            setShow(false)
            if(props.accessorial_fees) {
                setFees(props.accessorial_fees)
            }
        };
    const handleShow = () => {
        setShow(true);
        setState(props);
    }

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newFees = [...fees];
        newFees[index][name] = value;
        setFees(newFees);
    };

    const handleAddInput = () => {
        setFees([...fees, { type: "default", quantity: '1', amount: '' }]);
        
    };

    const handleRemoveInput = (index) => {
        const newFees = [...fees];
        newFees.splice(index, 1);
        setFees(newFees);
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
       
        props.parentLoadDetails({ carrier_pay: ((totalFees || 0) + (parseFloat(props.line_haul) || 0)) })
        const body = fees.length ? { accessorial_fees: [fees], carrier_pay: ((totalFees || 0) + (parseFloat(props.line_haul) || 0))  } : { accessorial_fees: {"null": null}, carrier_pay: ((totalFees || 0) + (parseFloat(props.line_haul) || 0))  };
        
        props.parentAccessorialFees(fees)
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        });
        
        handleClose();
    };
    const accessorialList = [
        { value: 'Prepull', label: 'Prepull' },
        { value: 'Per Diem', label: 'Per Diem' },
        { value: 'Demurrage', label: 'Demurrage' },
        { value: 'Detention', label: 'Detention' },
        { value: 'Yard Storage', label: 'Yard Storage' },
        { value: 'Chassis use', label: 'Chassis use' },
        { value: 'Reefer Fee', label: 'Reefer Fee' },
        { value: 'Drop and Hook', label: 'Drop and Hook' },
        { value: 'Layover', label: 'Layover' },
        { value: 'Congestion Fee', label: 'Congestion Fee' },
        { value: 'Fuel Surcharge', label: 'Fuel Surcharge' },
        { value: 'Overweight', label: 'Overweight' },
        { value: 'Lumper Fee', label: 'Lumper Fee' },
        { value: 'Port Fees', label: 'Port Fees' },
        { value: 'Chassis Split', label: 'Chassis Split' },
    ];

    useEffect(() => {
        if (props && props.accessorial_fees) {
            if(props.accessorial_fees[0] !== null) {
                setFees(props.accessorial_fees)
            }
            
        }
    }, [props.accessorial_fees])



    const totalFees =  fees.reduce((acc, fee) => {
        const amount = fee && parseFloat(fee.amount); // add a null check here
        const quantity = fee && parseInt(fee.quantity); // add a null check here
        if (isNaN(amount) || isNaN(quantity)) {
          return acc;
        }
        return acc + amount * quantity;
      }, 0);


    return (
        <>
            <Link>
                <Button className="btn btn-sm btn-primary float-right" onClick={handleShow}>
                    Accessorial Fees
                </Button>
            </Link>

            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                
            >
                <Modal.Header closeButton>
                    <Modal.Title>Accessorial Fees</Modal.Title>
                </Modal.Header>

                <form onSubmit={handleSubmit}>
                    <Modal.Body class="modal-body">

                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="status">Fee:</label>
                            </div>
                            <div className="col-3">
                                <label htmlFor="quantity">Quantity:</label>
                            </div>
                            <div className="col-3">
                                <label htmlFor="amount">Amount:</label>
                            </div>
                        </div>
                        {fees && fees.map((fee, index) => (
                            
                            <div className="row mb-3" key={index}>
                                
                                <div className="col-4">
                                    
                                {fee && fee.type && (
                                    <select
                                        id={`type-${index}`}
                                        name="type"
                                        value={fee.type}
                                        className="form-select"
                                        onChange={(event) => handleInputChange(index, event)}
                                    >
                                        <option value="default">Fee Type</option>
                                        {accessorialList.map((type) => (
                                            <option key={type.value} value={type.value}>
                                                {type.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                                </div>
                                <div className="col-3">
                                    <input
                                        id={`quantity-${index}`}
                                        type="text"
                                        name="quantity"
                                        value={fee ? fee.quantity : ''}
                                        className="form-control"
                                        onChange={(event) => handleInputChange(index, event)}
                                    />
                                </div>
                                <div className="col-3">
                                    <input
                                        id={`number-${index}`}
                                        type="number"
                                        name="amount"
                                        value={fee ? fee.amount : ''}
                                        className="form-control"
                                        onChange={(event) => handleInputChange(index, event)}
                                    />
                                </div>
                                <div className="col-2">
                                    <button className="btn btn-danger" type="button" onClick={() => handleRemoveInput(index)}>
                                        -
                                    </button>
                                </div>
                            </div>
                        ))}
                    
                        <button className="btn btn-primary" type="button" onClick={handleAddInput}>
                            Add Fee
                        </button>

                        

                    </Modal.Body>
                    <Modal.Footer>
                        <h3 className="">Total Fees: {totalFees.toFixed(2)}</h3>
                        <button className="btn btn-success float-right" type="submit">Submit</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default LoadsAccessorialFees


