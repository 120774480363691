import React, { Component, setState, prevState } from 'react';
import axios from 'axios';

export default class ContractCompleteContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carrier_edit: true,
            billing_edit: true,
            carrier_name: this.props.details.carrier_name,
            carrier_dba: this.props.details.carrier_dba,
            carrier_address: this.props.details.physical_address,
            carrier_mailaddress: this.props.details.billing_address,
            carrier_phone: this.props.details.carrier_phone,
            factor: this.props.details.factor,
            factoring_name: this.props.details.factoring_name,
            federal_id: this.props.details.federal_id,
        }
        this.carrierEdit = this.carrierEdit.bind(this);
        this.billingEdit = this.billingEdit.bind(this);

    }

    carrierEdit() {
        this.setState(prevState => ({
            carrier_edit: !prevState.carrier_edit
          }));
    }
    billingEdit() {
        this.setState(prevState => ({
            billing_edit: !prevState.billing_edit
          }));
    }

    onChange = e => {
        this.setState({...this.state, 
                        [e.target.name]: e.target.value})
    }

    testSubmit = e => {
        e.preventDefault()
        
    }
   
      
    handleSubmit = e => {

        e.preventDefault();
        fetch(`https://fltbd.herokuapp.com/finish-contract-api/${this.props.match.params.contract_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                carrier_name: this.state.carrier_name,
                carrier_dba: this.state.carrier_dba,
                carrier_address: this.state.carrier_address,
                carrier_mailaddress: this.state.carrier_mailaddress,
                carrier_phone: this.state.carrier_phone,
                factor: this.props.details.factor,
                factoring_name: this.props.details.factoring_name,
                federal_id: this.state.federal_id,
                ip_address: this.props.ip,
                trailers: this.props.details.trailers,
                areas: this.props.details.areas
            }),
            headers: { 'Content-Type': 'application/json', }

        })
        setTimeout(() => {
            fetch(`https://fltbd.herokuapp.com/api/create-contract/${this.props.match.params.contract_id}`)
          }, 2500);
        this.props.parentIsGenerating(true)
    };

    render() {
        return (
            <div class="contract-margin">
                <div className="card-header">
                <h3 class="card-title">Please confirm the details below and submit contract</h3>
                <div className="edit-button">
                    {this.props.details.company_name}
                </div>
              </div>
                <div class="card-body">
                    <div>
                        <div class="row justify-content-between">
                            <div class="col-4">
                                <h3>Carrier Details</h3>
                            </div>
                            <div class="col-4">
                                <a href="#" onClick={this.carrierEdit} class="float-right">[edit]</a>
                            </div>
                        </div>

                        <h4 className="mt-2">Carrier Name</h4>
                        <input type="text" class="form-control" value={this.state.carrier_name} name="carrier_name" onChange={this.onChange} readOnly={this.state.carrier_edit} />
                        <h4 className="mt-2">Carrier DBA</h4>
                        <input type="text" class="form-control" value={this.state.carrier_dba} name="carrier_dba" onChange={this.onChange} readOnly={this.state.carrier_edit} />
                        <h4 className="mt-2">Physical Address</h4>
                        <input type="text" class="form-control" value={this.state.carrier_address} name="carrier_address" onChange={this.onChange} readOnly={this.state.carrier_edit} />
                        <h4 className="mt-2">Phone Number</h4>
                        <input type="text" class="form-control" value={this.state.carrier_phone} name="carrier_phone" onChange={this.onChange} readOnly={this.state.carrier_edit} />

                    </div>
                    <hr />
                    <div>
                        <div class="row justify-content-between">
                            <div class="col-4">
                                <h3>Billing Details</h3>
                            </div>
                            <div class="col-4">
                                <a href="#" onClick={this.billingEdit} class="float-right">[edit]</a>
                            </div>
                        </div>

                        <h4 className="mt-2">Billing Address</h4>
                        <input type="text" class="form-control" value={this.state.carrier_mailaddress} name="carrier_mailaddress" onChange={this.onChange} readOnly={this.state.billing_edit} />
                        <h4 className="mt-2">Federal ID</h4>
                        <input type="text" class="form-control" value={this.state.federal_id} name="federal_id" onChange={this.onChange} readOnly={this.state.billing_edit} />

                    </div>

                    <div class="d-flex justify-content-center mt-5">


                        <button class="btn btn-lg btn-success" onClick={this.handleSubmit}>Submit Contract</button>

                    </div>
                </div>
            </div>
        )
    }
}