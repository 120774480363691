import React, { useState, useEffect, Component } from 'react'
import { useHistory, Link, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { authFetch } from '../../auth';
import Select from 'react-select'
import { gd } from 'date-fns/locale';
import CreatableSelect from 'react-select/creatable';



const LoadsDetails = (props) => {
    const initialState = () => {
        return (props)
    }
    const [checked, setChecked] = useState([]);

    const [equipment, setEquipment] = useState({
        tarp: false,
        team: false,
        hazmat: false,
    })
    const checkList = ["Chains & Binders", "Straps", "Tarp", "Team"];
    //https://contactmentor.com/checkbox-list-react-js-example/
    //https://www.cluemediator.com/checkbox-list-example-in-react
    const handleCheck = (event) => {

        var updatedList = [...checked]
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
        console.log(updatedList)
        props.parentEquipmentDetails(updatedList)
        //updateEquipment()
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                equipment: [updatedList]
            }),
            headers: { 'Content-Type': 'application/json' },
        })
    };

    // Return classes based on whether item is checked
    const isChecked = (item) => {
        const updatedList = [...checked]
        return (updatedList.includes(item) ?
            true :
            false)
    }

    //Not needed
    const loadChecked = (equipment) => {
        if (props && props.equipment) {
            props.equipment.map((g) => {
                console.log(isChecked(g))

            })
        }
    }

    useEffect(() => {
        //Not Working...
        if (props && props.equipment) {
            setChecked(props.equipment)


        }
    }, [props.equipment])




    const [state, setState] = useState(initialState);


    const statusList = [
        { value: 'Not Assigned', label: 'Not Assigned' },
        { value: 'Dispatched', label: 'Dispatched' },
        { value: 'In Transit', label: 'In Transit' },
        { value: 'Completed', label: 'Completed' },

    ]
    const trailerList = [
        { value: 'F - Flatbed', label: 'F - Flatbed' },
        { value: 'SD - Stepdeck', label: 'SD - Stepdeck' },
        { value: 'HS - Hotshot', label: 'HS - Hotshot' },
        { value: 'FSD - Flatbed, Stepdeck', label: 'FSD - Flatbed, Stepdeck' },
        { value: 'FSDHS - Flatbed, Stepdeck, Hotshot', label: 'FSDHS - Flatbed, Stepdeck, Hotshot' },
        { value: 'SDHS - Stepdeck, Hotshot', label: 'SDHS - Stepdeck, Hotshot' },
        { value: 'RGN - Removeable Gooseneck', label: 'RGN - Removeable Gooseneck' },
        { value: 'DD - Double Drop', label: 'DD - Double Drop' },
        { value: 'DBL - Doubles', label: 'DBL - Doubles' },
        { value: 'R - Reefer', label: 'R - Reefer' },
        { value: "40' - Container", label: "40' - Container" },
        { value: "20' - Container", label: "20' - Container" },
        { value: "40' - Container", label: "40' - Container" },
        
        { value: 'Other', label: 'Other' }

    ]

    const [multiple, setMultiple] = useState()
    const equipmentList = [
        { value: 'Chains & Binders', label: 'Chains & Binders' },
        { value: 'Straps', label: 'Straps' },
        { value: 'Tarp', label: 'Tarp' },
        { value: 'Team', label: 'Team' },
    ];


    function trailerChange(e) {

        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                trailer: e.value
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentLoadDetails({ trailer: e.value })
    }
    function statusChange(e) {
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                status: e.value
            }),
            headers: { 'Content-Type': 'application/json' },
        })
        props.parentLoadDetails({ status: e.value })
    }
    

    const handleEquipmentChange = (e) => {
        setMultiple(e)
        console.log(e)
        props.parentEquipmentDetails(e)
        authFetch(`https://fltbd.herokuapp.com/api/loads/${props.load_id}`, {
            method: 'PATCH',
            body: JSON.stringify({
                equipment: [e]
            }),
            headers: { 'Content-Type': 'application/json' },
        })

    }
    useEffect(() => {
        if (props && props.equipment) {
            setMultiple(props.equipment)
        }
      
    }, [props.equipment])


    const selectStyles = {
      multiValue: (base) => ({
          ...base,
          backgroundColor: '#7E6651',
          color: 'white',
      }),
      multiValueLabel: (base) => ({
        ...base,
        backgroundColor: '#7E6651',
        color: 'white',
    }),
  }

    return (


        <div class="card">
            <div class="card-body">

                <h3 class="card-title">Load Details</h3>
                <div class="mb-3">
                    <label class="form-label">Status</label>
                    <Select options={statusList} placeholder={props.status} name="status" onChange={(e) => statusChange(e)} />


                </div>
                <div class="mb-3">
                    <label class="form-label">Trailer</label>
                    <CreatableSelect options={trailerList} placeholder={props.trailer} name="trailer" onChange={(e) => trailerChange(e)} />

                </div>

                <div className="mb-3">

                    <label class="form-label">Equipment</label>
                    <CreatableSelect 
                        isMulti={true}
                        name="equipment"
                        value={multiple}
                        options={equipmentList}
                        onChange={handleEquipmentChange}
                        styles={selectStyles}
                    />
                </div>

                {3 === 2
                    ?
                    <div class="mb-3">
                        <div className="mb-3">
                            <label class="form-label">Equipment</label>
                            <div className="form-check form-check-inline">
                                {checkList.map((item, index) => (
                                    <div key={index}>

                                        <input className="form-check-input" value={item} type="checkbox" name={item} checked={isChecked(item)} onChange={handleCheck} />
                                        <label class="form-check-label">{item}</label>
                                    </div>
                                ))}
                            </div>
                        </div>


                    </div>
                    : null}
            </div>
        </div>



    )


}

export default LoadsDetails