import React, { Component, useEffect, useState } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import { authFetch, logout } from '../../auth'
import { v4 as uuidv4 } from "uuid";

import EquipmentTruckItemDetails from './EquipmentTruckItemDetails';
import EquipmentItemTruckRecords from './EquipmentItemTruckRecords';
import EquipmentItemTruckRecordsTable from './EquipmentItemTruckRecordsTable';
import EquipmentItemAlerts from './EquipmentItemAlerts';
import NotFound from '../Home/NotFound';

export default function EquipmentTruckItem(props) {
    const [records, setRecords] = useState([])
    const [data, setData] = useState()
    const [notFound, setNotFound] = useState(false)

    const handleNewRecord = (NewRecord) => {
        const dataCopy = [...records];
        setRecords([...dataCopy, NewRecord])
    }
    const handleRemoveRecord = (RemoveRecord) => {
        const equipment_id = props.match.params.equipment_id
        const dataCopy = [...records];
        dataCopy.splice(RemoveRecord.row.index, 1)
        setRecords(dataCopy)
        authFetch(`https://fltbd.herokuapp.com/api/truck_notes/${equipment_id}/${RemoveRecord.value}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        })
  
    }
    const handleEquipmentDetails = (Details) =>{
        const detailKey = Object.keys(Details)[0]
        const detailValue = Object.values(Details)[0]
        setData({...data, [detailKey]: detailValue})
    }
    const handleRegistrationAlert = (Alert) => {
        setData({...data, registration_alert: Alert})
    }
    const handleMaintenanceAlert = (Alert) => {
        setData({...data, maintenance_alert: Alert})
    }
    useEffect(() => {
        const equipment_id = props.match.params.equipment_id
        authFetch(`https://fltbd.herokuapp.com/api/equipment/truck/${equipment_id}`).then(response => {
            if (response.status === 500) {
                //history.go(0)
                logout()
                //return null
            }
            return response.json()
        })

            //.then(response => response.json()) remove this for e.json is not a function
            .then(data => {
                if(data.equipment_id === undefined) {
                    setNotFound(true)
                } 
                setData(data)
            }
            );


        authFetch(`https://fltbd.herokuapp.com/api/truck_notes/${equipment_id}`)

            .then(response => response.json())
            .then(response => {
                return response
            })
            .then(response =>
                setRecords(response)

            );
    }, [])
    if(notFound) {
        return (
            <NotFound />
        )
    }
    return (
        <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">

            <div class="d-sm-flex align-items-center justify-content-between">
                <h1>Manage Equipment</h1>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <EquipmentTruckItemDetails
                        parentEquipmentDetails={handleEquipmentDetails}
                        {...data} />
                </div>
                <div class="col-md-4">
                    <EquipmentItemAlerts 
                    parentRegistrationAlert={handleRegistrationAlert}
                    parentMaintenanceAlert={handleMaintenanceAlert}
                    {...data} />
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <EquipmentItemTruckRecords
                        parentNewRecord={handleNewRecord}
                        {...data} />
                </div>
                <div class="col-md-12">
                    <EquipmentItemTruckRecordsTable
                        recordsData={records}
                        parentRemoveRecord={handleRemoveRecord}
                    />
                </div>
            </div>

        </div>
    )

}
