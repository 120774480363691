import React, { useEffect, useState } from "react";
import { useAuth, authFetch, logout } from "../../auth";
import {
    Route, Switch, Redirect, Router, useHistory
} from 'react-router-dom';
import { convertToHTML } from 'draft-convert';
import { ContentState, convertToRaw, EditorState, convertFromRaw, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './../../App.css'
import DOMPurify from 'dompurify';
import { stateFromHTML } from 'draft-js-import-html';
import { Alert } from "react-bootstrap";


export default function SettingsConfirmation(props) {
    const history = useHistory();

    const [companyTC, setCompanyTC] = useState(' ')
    const [showingAlert, setShowingAlert] = useState(false);


    const [loading, setLoading] = useState(false)

    const [editorState, setEditorState] = useState(
        () =>
            EditorState.createWithContent(stateFromHTML(companyTC)),
    );
    const [convertedContent, setConvertedContent] = useState();

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();

    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (convertedContent !== undefined) {


            authFetch(`https://fltbd.herokuapp.com/api/companys/${props.id}`, {
                method: 'PATCH',
                body: JSON.stringify({
                    company_tc: convertedContent,
                }),
                headers: { 'Content-Type': 'application/json' },
            }).then(r => r.json())
                .then(data => {

                    if (data.success) {
                        onShowAlert()
                        setLoading(true);
                        setTimeout(() => {
                            setLoading(false);
                        }, 500);
                        props.parentCompanyTC(convertedContent)
                    }
                })
        }

    }



    useEffect(() => {
        if (props && props.company_tc) {
            setCompanyTC(props.company_tc)
            setEditorState(EditorState.createWithContent(stateFromHTML(props.company_tc)))
        }
    }, [props.company_tc])
    useEffect(() => {
        document.title = props.title || "";
    }, [props.title]);

    const onShowAlert = () => {
        setShowingAlert(true,
            setTimeout(() => {
                setShowingAlert(false)
            }, 2000)
        );
    }
    if (!props.userRole.includes("admin")) {
        return <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Manage Rate Confirmation</h3>
            </div>
            <div class="card-body">

                You don't have access to this. If you think this a mistake please contact one of your company's administators.

            </div>
        </div>

    }
    return (


        <div class="card" >
            <div class="card-header">
                <h3 class="card-title">Manage Rate Confirmation</h3>
            </div>
            <div class="card-body">
                <Alert variant="success" show={showingAlert} >
                    Contract Successfully Updated.
                </Alert>
                <div className="form-label">
                    This will be visible at the bottom of every rate confirmation.

                </div>

                <form onSubmit={handleSubmit}>
                    <Editor

                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="wrapper-class-none"
                        editorClassName="editor-class company-contract-height"
                        toolbarClassName="toolbar-class"

                    />
                    {loading ?
                        <div class="float-right">
                            <button className="btn btn-success float-right mt-3" disabled>Saving...</button>

                        </div>
                        :
                        <button type="submit" className="btn btn-success float-right mt-3">Make Changes</button>
                    }
                </form>
            </div>
        </div>

    )
}