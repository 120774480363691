import React, { useState, useEffect, Component } from 'react'
import { Modal, Button } from "react-bootstrap";
import { useHistory, Link, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";




const RequestRemove = () => {
    const [showModal, setShow] = useState(false);
    const [error, setError] = useState()
    const [mcError, setMCError] = useState()
    const [remove, setRemove] = useState('')
    const [loading, setLoading] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    const handleChange = e => {
        setRemove({
            ...remove,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = e => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let mc_re =  /^[0-9]{1,8}$/
        

        if (re.test(remove.email) && mc_re.test(remove.mc_number)) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
            const uniqueID = uuidv4()

            fetch(`https://fltbd.herokuapp.com/api/double-broker-list/request-remove`, {
                method: 'PUT',
                body: JSON.stringify({
                    request_id: uniqueID,
                    mc_number: remove.mc_number,
                    email: remove.email,
                }),
                headers: { 'Content-Type': 'application/json' },
            })
            setLoading(true);
            setTimeout(() => {
                setRemove({
                    request_id: '',
                    mc_number: '',
                    email: '',
                })
                setShow(false)
                setLoading(false)
            }, 500);
            setError("")

        }
        if(!mc_re.test(remove.mc_number)) {
            setMCError('Not a valid MC Number')
        }
        if(!re.test(remove.email)) {
            setError('Not A Valid Email.')
            // invalid email, maybe show an error to the user.
        }
        e.preventDefault();

    }
    return (
        <div>
            <Link>
                <button onClick={handleShow} className="btn btn-danger btn-uppercase mt-3 float-right">Request Removal</button>

            </Link>
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Request Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body class="modal-body">
                    <form onSubmit={handleSubmit}>
                        <div>I would like our company to be removed from the list</div>
                        <br />
                        <div>
                            <label class="form-label">MC Number</label>
                            <input type="text" class="form-control" value={remove.mc_number} name="mc_number" onChange={handleChange} />
                            <div className="d-flex error-msg-red m-2">{mcError}</div>
                            <label class="form-label mt-2">Email</label>
                            <input type="text" class="form-control" value={remove.email} name="email" onChange={handleChange} required />
                            <div className="d-flex error-msg-red m-2">{error}</div>
                        </div>
                        
                        <div class="modal-footer" style={{padding: "0"}}>
                            <button type="button" class="btn me-auto" style={{margin: "0"}} data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                            {loading ?
                            <button class="btn btn-success" style={{margin: "0"}} disabled>Sending...</button>
                            :
                            <button class="btn btn-success" style={{margin: "0"}}>Submit</button>
                        }
                        </div>
                    </form>

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default RequestRemove